// const moduleComponent = () =>
//   import(/* webpackChunkName: "payment" */ "./index");
const paymentResponseComponent = () =>
  import(/* webpackChunkName: "paymentResponse" */ "./pages/response");

export const payment = [
  // {
  //   path: "/payment",
  //   name: "payment",
  //   component: moduleComponent,
  //   meta: {
  //     title: "Ödeme",
  //     notRequiresAuth: true,
  //     bgColorGray: true,
  //     acceptedRoles: "ALL",
  //   },
  // },
  {
    path: "/payment/response",
    name: "paymentResponse",
    component: paymentResponseComponent,
    meta: {
      title: "Ödeme",
      notRequiresAuth: true,
      bgColorGray: true,
    },
  },
];
