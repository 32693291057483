const dashboardPreviewStudentComp = () =>
  import(/* webpackChunkName: "dashboardPreviewStudent" */ "./index");

export const dashboardPreviewStudent = [
  {
    path: "/dashboard-preview-student",
    name: "dashboardPreviewStudent",
    component: dashboardPreviewStudentComp,
    meta: {
      title: "Çalışmalar Önizleme",
      bgColorGray: true,
      acceptedRoles: "STUDENT_PARENT",
    },
  },
];
