import axios from "@/services/axios/authAxios";

const basketAddPacket = async (params) => {
  return await axios.post("basket/packet/add", null, {
    params,
  });
};

const getBasket = async (params) => {
  return await axios.get("basket", {
    params: { ...params },
  });
};

const basketRemovePacket = async (params) => {
  return await axios.delete("basket/packet/remove", {
    params: { ...params },
  });
};

const basketPayTransaction = async (params) => {
  // kaydi olan kullanicilar icin
  return await axios.post("basket/payTransaction", {
    ...params,
  });
};

const basketRegisterAndPay = async (params) => {
  // kaydi olmayan kullanicilar icin
  return await axios.post("unAuth/payTransactionAndRegister", {
    ...params,
  });
};

const getInstallmentInfo = async (params) => {
  return await axios.get("unAuth/pay/installment", {
    params: { ...params },
  });
};

const checkCouponCode = async (params) => {
  return await axios.get("unAuth/checkCouponCode", {
    params: { ...params },
  });
};

const controlCouponCodePacket = async (params) => {
  return await axios.get("unAuth/controlCouponCodePacket", {
    params: { ...params },
  });
};

const controlEmailAndPhone = async (params) => {
  // Gecerli eposta ve telefon kontrolunun yapildigi
  return await axios.post("unAuth/controlEmailPhone", {
    ...params,
  });
};

export {
  basketAddPacket,
  getBasket,
  basketRemovePacket,
  basketPayTransaction,
  basketRegisterAndPay,
  getInstallmentInfo,
  checkCouponCode,
  controlCouponCodePacket,
  controlEmailAndPhone
};
