<template>
  <div v-tooltip="{ content: title, placement: 'top-end' }">
    <router-link
      :to="link"
      v-slot="{ isActive, route, isExactActive, href, navigate }"
      custom
    >
      <a
        @click="navigate"
        :href="href"
        class="flex items-center py-2 px-5 hover:text-gray-100 hover:bg-gray-700 rounded"
        :class="
          isExactActive || (isActive && route.name != 'home')
            ? 'text-gray-100 bg-gray-700'
            : ''
        "
      >
        <div class="grid place-items-center min-w-10 h-10">
          <svg-icon :name="icon" class="w-6 h-6" />
        </div>
        <div class="pl-2 text-sm whitespace-nowrap overflow-hidden overflow-ellipsis">{{ title }}</div>
      </a>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    link: {
      type: [String, Object],
      default: "",
    },
  },
};
</script>

<style>
</style>