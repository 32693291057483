import { isCookieEnabled, getCookie } from "@/common/helpers/user"

const auth = {
  state: {
    accesToken: isCookieEnabled() ? getCookie("accessTokenB") || "" : "",
    refreshToken: isCookieEnabled() ? getCookie("refreshTokenB") || "" : ""
  },
  getters: {
    getAccessToken: state => state.accesToken,
    getRefreshToken: state => state.refreshToken,
    isLoggedIn: state => state.accesToken != "" && state.refreshToken != ""
  },
  mutations: {},
  actions: {},
  namespaced: true
}
export default auth
