import store from "@/store"
export const appSettings = {
  adminMiniSideBar: val => {
    if (typeof window !== 'undefined') {
      val
        ? document.documentElement.classList.add("adminMiniSideBar")
        : document.documentElement.classList.remove("adminMiniSideBar")
      store.dispatch("appSettings/adminMiniSideBar", val)
    }
  },
  subtopicMiniSideBar: val => {
    if (typeof window !== 'undefined') {
    val
      ? document.documentElement.classList.add("subtopicMiniSideBar")
      : document.documentElement.classList.remove("subtopicMiniSideBar")
    store.dispatch("appSettings/subtopicMiniSideBar", val)
    }
  },
  bgColorGray: val => {
    store.dispatch("appSettings/bgColorGray", val)
  },
  bgColorBlue: val => {
    store.dispatch("appSettings/bgColorBlue", val)
  },
}
