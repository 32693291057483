import { userService } from "@/services/user/index";
import { basketAddPacket } from "@/common/helpers/basket"
import router from '@/router/index';
import { isCookieEnabled } from "@/common/helpers/user"

export default {
  install: (app) => {
    app.mixin({
      methods: {
        addToBasket(packetId) {
          if (!isCookieEnabled()) { // cookie izni yoksa
            router.push({ name: "cookieBlocked" })
            return
          }
          this.$gtag.event('add_to_cart', {
            'event_category': 'ecommerce',
            'event_label': 'Satın Al'
          })
          let couponCode = ""
          let localStorageCouponCode = isCookieEnabled() ? this.getCookie("couponCode") : false
          let accessTokenB = isCookieEnabled() ? this.getCookie("accessTokenB") : false
          let localStorageBasket = isCookieEnabled() ? this.getCookie("basket") : false
          if(localStorageCouponCode) { // kupon kodu varsa her sepete urun eklendiginde o kupon kodu da sorguda gidilecek
            couponCode = isCookieEnabled() ? this.getCookie("couponCode") : null
          }
          if (!accessTokenB) { // misafir kullaniciysa
            var localBasket = []
            if (localStorageBasket) { // sepette daha onceden ekli paket varsa..
              localBasket = JSON.parse(localStorageBasket) //.. paket id-lerin ekli oldugu dizi isleme alinir
            }
            if(localBasket.includes(packetId)) { // localdeki ayni paket eklenmek istenirse
              if (router.history.current.name != 'basket') {
                setTimeout(() => {
                  router.push({ name: 'basket' });
                }, 300);
              }
              return
            }
            localBasket.push(packetId) // eklenmek istenen paket id diziye eklenir
            if (isCookieEnabled()) {
              this.setCookie("basket", JSON.stringify(localBasket)) // bu dizi tekrardan locale yazilir
            }
            userService.getBasketList(couponCode) // sepet guncellensin diye
            if (router.history.current.name != 'basket') {
              setTimeout(() => {
                router.push({ name: 'basket' });
              }, 300);
            }
            return
          }
          basketAddPacket({ packetIds: packetId }).then(() => {
            userService.getBasketList(couponCode) // sepet guncellensin diye
            if (router.history.current.name != 'basket') {
              setTimeout(() => {
                router.push({ name: 'basket' });
              }, 300);
            }
          })
          .catch(error => {
            if(error.response.data.shrtCode == "ALREADY_PACKET_IN_BASKET") {
              if (router.history.current.name != 'basket') {
                setTimeout(() => {
                  router.push({ name: 'basket' });
                }, 300);
              }
            } else if(error.response.data.shrtCode == "ALREADY_PACKET_IN_USER") {
              this.$toastAlert({
                type: "warning",
                title: this.$t("general.warning"),
                message: "Paket zaten alındı",
              });
            } else if(error.response.data.shrtCode == "PACKET_NOT_ADD") {
              this.$toastAlert({
                type: "warning",
                title: this.$t("general.warning"),
                message: "Paket sepete eklenmedi.",
              });
            }
            else{
              this.$toastAlert({
                type: "error",
                title: this.$t("general.error"),
                message: "Sepete eklenirken hata oluştu",
              });
            }
          })
        },
        // Yeni cookie ekleme ve var olan cookie guncelleme
        setCookie(cookieName, cookieValue, expDays = 365) {
          let date = new Date();
          date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
          const expires = "expires=" + date.toUTCString();
          document.cookie = cookieName + "=" + cookieValue + "; " + expires + "; path=/";
        },
        // Var olan cookie getirme
        getCookie(cookieName) {
          const name = cookieName + "=";
          const cArr = document.cookie.split('; ');
          let res;
          cArr.forEach(val => {
              if (val.indexOf(name) === 0) res = val.substring(name.length);
          })
          return res;
        },
        // Var olan cookie silme
        deleteCookie(cookieName) {
          document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
        },
      },
    });
  },
};
