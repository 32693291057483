import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/styles/index.css";
import VueI18n from "vue-i18n";
import i18n from "./i18n";
import svgIcon from "./common/components/icons/svgIcon";
import mainModal from "./common/components/modals/mainModal";
import mainModal2 from "./common/components/modals/mainModal2";
import mainModal3 from "./common/components/modals/mainModal3";
import popUpDialog from "./plugins/popUpDialog";
import popUpModal from "./plugins/popUpModal";
import toastAlert from "./plugins/toastAlert";
import basket from "./plugins/global";
import { VTooltip, VPopover, VClosePopover } from "v-tooltip";
import VueSocialSharing from "vue-social-sharing";
import VueGtag from "vue-gtag";
import './registerServiceWorker'
import VueMeta from 'vue-meta'
import VueParticles from 'vue-particles'

Vue.config.productionTip = false;

//plugins
Vue.use(VueI18n);
Vue.use(popUpDialog);
Vue.use(popUpModal);
Vue.use(toastAlert);
Vue.use(basket);
Vue.use(VueSocialSharing);
Vue.use(
  VueGtag,
  {
    appName: "Edunext",
    pageTrackerScreenviewEnabled: true,
    config: { id: "G-WNPP5453NL" },
  },
  router
);
Vue.use(VueMeta)
Vue.use(VueParticles)

//directives
Vue.directive("tooltip", VTooltip);
Vue.component("v-popover", VPopover);
Vue.directive("close-popover", VClosePopover);

//global components
Vue.component("svgIcon", svgIcon);
Vue.component("mainModal", mainModal);
Vue.component("mainModal2", mainModal2);
Vue.component("mainModal3", mainModal3);

export const app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
