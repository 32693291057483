const dashboardPreviewComp = () =>
  import(/* webpackChunkName: "dashboardPreview" */ "./index");

export const dashboardPreview = [
  {
    path: "/dashboard-preview/:userId",
    name: "dashboardPreview",
    component: dashboardPreviewComp,
    meta: {
      title: "Çalışmalar Önizleme",
      bgColorGray: true,
      acceptedRoles: "SCL_COORDINATOR",
    },
  },
];
