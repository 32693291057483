<template>
  <transition>
    <div
      class="fixed left-0 top-0 bg-black-800 bg-opacity-25 w-full h-full flex items-center justify-center z-40"
      @click.self="close"
    >
      <div
        :class="[
          'bg-white rounded-3xl flex flex-col overflow-hidden z-40',
          boxSize
        ]"
      >
        <div class="relative bg-gray-100 px-6 pt-6 pb-3">
          {{ title }}
        </div>
        <div class="py-3 px-6">
          {{ message }}
          <div v-html="template"></div>
        </div>
        <div class="pt-3 pb-6 px-6 bg-gray-100">
          <button
            class="px-4 py-2 text-white rounded-2xl mr-4"
            :class="confirmButtonClassString"
            @click="accept()"
            :aria-label="buttonText"
          >
            {{ buttonText }}
          </button>
          <button
            v-if="type == 'dialog'"
            class="px-4 py-2 bg-gray-200 rounded-2xl"
            @click="close()"
            aria-label="Vazgeç"
          >
            Vazgeç
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "info"
    },
    title: {
      type: String,
      default: ""
    },
    message: {
      type: String,
      default: ""
    },
    template: {
      type: String,
      default: ""
    },
    size: {
      type: Number,
      default: 1
    },
    buttonText: {
      type: String,
      default: "Tamam"
    }
  },
  methods: {
    accept() {
      this.$emit("confirm", true)
    },
    close() {
      this.$emit("confirm", false)
    }
  },
  computed: {
    confirmButtonClassString() {
      let classString = ""
      if (this.type == "error") classString = "bg-error-400"
      else if (this.type == "success") classString = "bg-success-400"
      else if (this.type == "info" || this.type == "dialog")
        classString = "bg-primary-400"
      return classString
    },
    boxSize() {
      let size = "w-64"
      if (this.size == 2) size = "w-96"
      if (this.size > 2) size = "w-full max-w-container-sm"
      return size
    }
  }
}
</script>

<style></style>
