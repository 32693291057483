const modulLayout = () => import(/* webpackChunkName: "adminPage" */ "./index");
const packet = () =>
  import(/* webpackChunkName: "adminPage" */ "./pages/packet");

const basePacket = () =>
  import(/* webpackChunkName: "adminPage" */ "./pages/basePacket");

const packetList = () =>
  import(/* webpackChunkName: "adminPage" */ "./pages/packetList");

const basePacketList = () =>
  import(/* webpackChunkName: "adminPage" */ "./pages/basePacketList");

const basePacketTests = () =>
  import(/* webpackChunkName: "adminPage" */ "./pages/basePacketTests");

const approveBasePacketTests = () =>
  import(/* webpackChunkName: "adminPage" */ "./pages/approveBasePacketTests");

const updateBasePacketTest = () =>
  import(/* webpackChunkName: "adminPage" */ "./pages/updateBasePacketTest");
const updateApproveBasePacketTest = () =>
  import(
    /* webpackChunkName: "adminPage" */ "./pages/updateApproveBasePacketTest"
  );

const coupon = () =>
  import(/* webpackChunkName: "adminPage" */ "./pages/coupon");
const couponList = () =>
  import(/* webpackChunkName: "adminPage" */ "./pages/couponList");

const configurations = () =>
  import(/* webpackChunkName: "adminPage" */ "./pages/configurations");

const excelImport = () =>
  import(/* webpackChunkName: "adminPage" */ "./pages/excelImport");

const usersList = () =>
  import(/* webpackChunkName: "adminPage" */ "./pages/usersList");

const meta = {
  acceptedRoles:
    "SUPERADMIN,MATH_ADMIN,SCIENCE_ADMIN,ADMIN,SOCIAL_ADMIN,TURKISH_ADMIN",
  bgColorGray: true,
};

export const admin = [
  {
    path: "/admin",
    component: modulLayout,
    children: [
      {
        path: "packet",
        name: "adminPacket",
        component: packet,
        meta: {
          title: "Admin Paket",
          acceptedRoles: "ADMIN",
          bgColorGray: true,
        },
      },
      {
        path: "packet-list",
        name: "adminPacketList",
        component: packetList,
        meta: {
          title: "Admin Paket Listesi",
          acceptedRoles: "ADMIN",
          bgColorGray: true,
        },
      },
      {
        path: "base-packet",
        name: "adminBasePacket",
        component: basePacket,
        meta: {
          title: "Admin Base Paket",
          acceptedRoles: "SUPERADMIN",
          bgColorGray: true,
        },
      },
      {
        path: "base-packet-list",
        name: "adminBasePacketList",
        component: basePacketList,
        meta: {
          title: "Admin Base Paket Listesi",
          acceptedRoles: "SUPERADMIN",
          bgColorGray: true,
        },
      },
      {
        path: "base-packet-tests",
        name: "basePacketTests",
        component: basePacketTests,
        meta,
      },
      {
        path: "approve-base-packet-tests",
        name: "approveBasePacketTests",
        component: approveBasePacketTests,
        meta,
      },
      {
        path: "base-packet-test",
        name: "updateBasePacketTest",
        component: updateBasePacketTest,
        meta,
      },
      {
        path: "approve-base-packet-test",
        name: "updateApproveBasePacketTest",
        component: updateApproveBasePacketTest,
        meta,
      },
      {
        path: "coupon",
        name: "coupon",
        component: coupon,
        meta: {
          title: "Admin Kupon",
          acceptedRoles: "ADMIN",
          bgColorGray: true,
        },
      },
      {
        path: "coupon-list",
        name: "couponList",
        component: couponList,
        meta: {
          title: "Admin Kupon Listesi",
          acceptedRoles: "ADMIN",
          bgColorGray: true,
        },
      },
      {
        path: "configurations",
        name: "configurations",
        component: configurations,
        meta: {
          title: "Admin Konfigürasyonları",
          acceptedRoles: "ADMIN",
          bgColorGray: true,
        },
      },
      {
        path: "excel-import",
        name: "excelImport",
        component: excelImport,
        meta: {
          title: "Admin Excel Yükleme",
          acceptedRoles: "ADMIN",
          bgColorGray: true,
        },
      },
      {
        path: "users-list",
        name: "usersList",
        component: usersList,
        meta: {
          title: "Admin Kullanıcı Listesi",
          acceptedRoles: "ADMIN",
          bgColorGray: true,
        },
      },
    ],
  },
];
