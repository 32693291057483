import axios from "@/services/axios/authAxios";

const getStudentPacket = async () => {
  return await axios.get("user/packet")
}
const getPacketCategoryList = async () => {
  return await axios.get("/unAuth/packet/category");
};
const getPacketByCategoryId = async (categoryId) => {
  // categoryId null oldugunda tum paketleri getirir
  return await axios.get("unAuth/packet", {
    params: { categoryId },
  });
};
const getPacketByPacketId = async (packetId) => {
  return await axios.get("unAuth/packetById", {
    params: { packetId },
  });
};
const getPacketByPacketCode = async (packetCode) => {
  return await axios.get("unAuth/packetByPacketCode", {
    params: { packetCode },
  });
};

export { getStudentPacket, getPacketCategoryList, getPacketByCategoryId, getPacketByPacketId, getPacketByPacketCode };
