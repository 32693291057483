const forgetPasswordComp = () =>
  import(/* webpackChunkName: "forgetPassword" */ "./index");

export const forgetPassword = [
  {
    path: "/parolami-unuttum",
    name: "forgetPassword",
    component: forgetPasswordComp,
    meta: {
      title: "Şifremi unuttum",
      bgColorGray: true,
      notRequiresAuth: true
    }
  },
];
