const moduleComponent = () =>
  import(/* webpackChunkName: "profile" */ "./index");

const profileInformation = () =>
  import(
    /* webpackChunkName: "profileInformation" */ "./pages/profileInformation"
  );

const schoolInformation = () =>
  import(
    /* webpackChunkName: "schoolInformation" */ "./pages/schoolInformation"
  );

const changePassword = () =>
  import(/* webpackChunkName: "changePassword" */ "./pages/changePassword");

const myPackets = () =>
  import(/* webpackChunkName: "myPackets" */ "./pages/myPackets");

const parentInformation = () =>
  import(
    /* webpackChunkName: "parentInformation" */ "./pages/parentInformation"
  );

export const profile = [
  {
    path: "/profile",
    name: "profile",
    component: moduleComponent,
    meta: {
      title: "Profilim",
      acceptedRoles: "ALL",
      bgColorGray: true,
    },
    children: [
      {
        path: "/profile/information",
        name: "profileInformation",
        component: profileInformation,
        meta: {
          title: "Profil Bilgilerim",
          acceptedRoles: "STUDENT,SCL_COORDINATOR,STUDENT_PARENT",
          bgColorGray: true,
        },
      },
      {
        path: "/profile/school-information",
        name: "schoolInformation",
        component: schoolInformation,
        meta: {
          title: "Okul Bilgilerim",
          acceptedRoles: "STUDENT,SCL_COORDINATOR,STUDENT_PARENT",
          bgColorGray: true,
        },
      },
      {
        path: "/profile/change-password",
        name: "changePassword",
        component: changePassword,
        meta: {
          title: "Şifre Değiştirme",
          acceptedRoles: "STUDENT,SCL_COORDINATOR,STUDENT_PARENT",
          bgColorGray: true,
        },
      },
      {
        path: "/profile/my-packets",
        name: "myPackets",
        component: myPackets,
        meta: {
          title: "Eğitim Paketlerim",
          acceptedRoles: "STUDENT,SCL_COORDINATOR,STUDENT_PARENT",
          bgColorGray: true,
        },
      },
      {
        path: "/profile/parent-information",
        name: "parentInformation",
        component: parentInformation,
        meta: {
          title: "Veli Bilgilerim",
          acceptedRoles: "STUDENT",
          bgColorGray: true,
        },
      },
    ],
  },
];
