import axios from "../../axios/authAxios";
import { getStudentPacket, getPacketByPacketId } from "@/modules/packets/helpers";
import { authenticationService } from "@/services/auth/index";
import { getBasket } from "@/common/helpers/basket"
import router from '@/router/index';
// import  app  from "@/main.js"
import { isCookieEnabled, getCookie, setCookie, deleteCookie } from "@/common/helpers/user"
// import { getUserImage } from "@/common/helpers/user"
const user = {
  state: {
    menuList: [],
    baskeIsLoaded: false, // tekrar sayfa reload olana kadar basket cagrilmasin diye kontrol data-si
    basketList: [],
    totalBasketInfo: {}, // sepette kac urun varsa hepsinin toplam bilgileri (api-den geliyor)
    packetList: [],
    userRole: null,
    userType: null,
    userRolePermissions: [],
    userId: "",
    email: "",
    name: "",
    surname: "",
    phone: "",
    schoolLogoUrl: null,
    schoolId: null,
    photo: "",
    tckn: "",
    gender: "",
    level: null,
    courseList: [],
    courseListWithPackage: [],
    activeCourse: null,
    forceChangePassword: null,
    activePackage: null,
    acceptKvkkDate: null,
    kvkkDateAccepted: false, // src/router/index.js icinde bu degere gore kontrol var. Eger bu deger true ise sifre yenileme ekranina yonlendiriliyor. Bu deger de sifre yenileme ekranindayken true oluyor. Sso-dan gelmisse kvkk metin onaylandiktan sonra false-a tekrarda cekiliyor ki, gerekli islemler yapilabilsin
    city: "",
    levelBranchNameList: null,
    levelOrder: null,
    readGuide: null,
    schoolBranch: null,
    schoolName: null,
    stageShrtCode: null,
    state: null,
    tempEmail: "",
    tempPhoneNumber: "",
    username: "",
    couponCode: "",
    forceFreeTrialPage: null,
    forceFreeTrialGtbTtbPage: null,
    forcePacket: null,
    isLoggedIn_Store: false,
    child: "",
    parent: "",
  },
  getters: {
    getMenuList: (state) => state.menuList,
    fullName: (state) => `${state.name} ${state.surname}`,
  },
  mutations: {
    setMenuList: (state, menuList) => (state.menuList = menuList),
    setBaskeIsLoaded: (state, val) => (state.baskeIsLoaded = val),
    setBasketList: (state, val) => (state.basketList = val),
    setTotalBasketInfo: (state, val) => (state.totalBasketInfo = val),
    setPacketList: (state, val) => (state.packetList = val),
    setUserRole: (state, role) => (state.userRole = role),
    setUserType: (state, type) => (state.userType = type),
    setUserRolePermissions: (state, permissions) =>
      (state.userRolePermissions = permissions),
    setUserId: (state, id) => (state.userId = id),
    email: (state, val) => (state.email = val),
    name: (state, val) => (state.name = val),
    surname: (state, val) => (state.surname = val),
    phone: (state, val) => (state.phone = val),
    photo: (state, val) => (state.photo = val),
    tckn: (state, val) => (state.tckn = val),
    gender: (state, val) => (state.gender = val),
    courseList: (state, val) => (state.courseList = val),
    courseListWithPackage: (state, val) => (state.courseListWithPackage = val),
    schoolLogoUrl: (state, val) => (state.schoolLogoUrl = val),
    schoolId: (state, val) => (state.schoolId = val),
    setForceChangePass: (state, val) => (state.forceChangePassword = val),
    activeCourse: (state, val) => {
      state.activeCourse = val;
      setCookie("activeCourse", val);
    },
    level: (state, val) => {
      state.level = val;
    },
    setActivePackage: (state, val) => (state.activePackage = val),
    setAcceptKvkkDate: (state, val) => (state.acceptKvkkDate = val),
    kvkkDateAccepted: (state, val) => (state.kvkkDateAccepted = val),
    city: (state, val) => (state.city = val),
    levelBranchNameList: (state, val) => (state.levelBranchNameList = val),
    levelOrder: (state, val) => (state.levelOrder = val),
    readGuide: (state, val) => (state.readGuide = val),
    schoolBranch: (state, val) => (state.schoolBranch = val),
    schoolName: (state, val) => (state.schoolName = val),
    stageShrtCode: (state, val) => (state.stageShrtCode = val),
    state: (state, val) => (state.state = val),
    tempEmail: (state, val) => (state.tempEmail = val),
    tempPhoneNumber: (state, val) => (state.tempPhoneNumber = val),
    username: (state, val) => (state.username = val),
    setCouponCode: (state, val) => (state.couponCode = val),
    setForceFreeTrialPage: (state, val) => (state.forceFreeTrialPage = val),
    setForceFreeTrialGtbTtbPage: (state, val) => (state.forceFreeTrialGtbTtbPage = val),
    setForcePacket: (state, val) => (state.forcePacket = val),
    isLoggedIn_Store: (state, val) => (state.isLoggedIn_Store = val),
    child: (state, val) => (state.child = val),
    parent: (state, val) => (state.parent = val),
  },
  actions: {
    async userProfile({ commit }) {
      await axios.get("user/profile").then((response) => {
        commit("email", response.data.email);
        commit("name", response.data.name);
        commit("surname", response.data.surname);
        commit("phone", response.data.phoneNumber);
        // commit(
        //   "photo",
        //   getUserImage(response.data.photo, response.data.gender?.toLowerCase())
        // )
        commit("tckn", response.data.tckn);
        commit("gender", response.data.gender);
        // let courseList = response.data.courseList.map(
        //   course => course.courseShrtCode
        // )
        // commit("courseList", courseList)
        commit("courseListWithPackage", response.data.courseList);
        commit("schoolLogoUrl", response.data.schoolLogoUrl);
        commit("schoolId", response.data.schoolId);
        commit("level", response.data.levelBranchNameList?.[0].split("/")[0]);
        commit("city", response.data.city);
        commit("levelOrder", response.data.levelOrder);
        commit("readGuide", response.data.readGuide);
        commit("schoolBranch", response.data.schoolBranch);
        commit("schoolName", response.data.schoolName);
        commit("stageShrtCode", response.data.stageShrtCode);
        commit("state", response.data.state);
        commit("tempEmail", response.data.tempEmail);
        commit("tempPhoneNumber", response.data.tempPhoneNumber);
        commit("username", response.data.username);
        commit("setUserId", response.data.userId);
        // let activeCourse
        // if (
        //   response.data.courseList?.filter(
        //     p => p.courseShrtCode == "COURSE_MATH"
        //   ).length > 0
        // ) {
        //   activeCourse = "COURSE_MATH"
        // } else {
        //   activeCourse = response.data.courseList[0]?.courseShrtCode
        // }
        // commit("activeCourse", localStorage.activeCourse || activeCourse)
        // let activePackage = response.data.courseList.filter(
        //   p => p.courseShrtCode == state.activeCourse
        // )[0]?.coursePackage
        // commit("setActivePackage", activePackage)
        commit("isLoggedIn_Store", true);
        commit("child", response.data.child);
        commit("parent", response.data.parent);
      }).catch(() => {
        // token olmadigi durumda beyaz ekran gelmesin diye localStorage temizlenip, login sayfaina yonlenir
        isCookieEnabled() ? deleteCookie("accessTokenB") : null;
        authenticationService.logOut(); // cikis yapilir
        router.push('/'); // token olmadigi icin de login-e yonlenir
      });
    },
    async menuPermissions({ commit, state }) {
      return await axios
        .get("menu/menuPermissions")
        .then((response) => {
          let menuList = response.data.menuList.sort((a, b) =>
            a.menuOrder < b.menuOrder ? -1 : 1
          );
          // admin panelde "state.activePackage" undefined dondugunde 3. ve 5. menuler gorunmuyordu. bunun icin ek olarak undefined kontrolu eklendi
          if (
            state.activePackage != "PRO" &&
            state.activePackage != undefined
          ) {
            // gelisim asistani ve acik uclu sorular modulunun gizlenmesi icin
            menuList = menuList.filter(
              (ml) =>
                ml.label != "openEndedQuestions" &&
                ml.label != "developmentAssistant"
            );
          }
          commit("setMenuList", menuList);
          commit("setUserRole", response.data.userRole);
          commit("setUserType", response.data.userType);
          commit("setUserId", response.data.userId);
          commit("setForceChangePass", response.data.forceChangePassword);
          commit("setAcceptKvkkDate", response.data.acceptKvkkDate);
          commit("setForceFreeTrialPage", response.data.freeTrialPage);
          commit("setForceFreeTrialGtbTtbPage", response.data.freeTrialGtbTtbPage);
          commit("setForcePacket", response.data.forcePacket);
          commit("setCouponCode", response.data.couponCode);
          commit("isLoggedIn_Store", true);
          if (response.data.couponCode != null) {
            isCookieEnabled() ? setCookie("freeCouponCode", response.data.couponCode) : null;
          }
        })
        .catch((error) => {
          throw error;
        });
    },
    async roleMenuPermissions({ commit }) {
      return await axios
        .get("menu/roleMenuPermissions")
        .then((response) => {
          commit("setUserRolePermissions", response.data);
        })
        .catch((error) => {
          throw error;
        });
    },
    updateBasketList: ({ commit }, val) => {
      commit("setBasketList", val);
    },
    updateBaskeIsLoaded: ({ commit }, val) => {
      commit("setBaskeIsLoaded", val);
    },
    updateCouponCode: ({ commit }, val) => {
      commit("setCouponCode", val);
    },
    updateForcePacket: ({ commit }, val) => {
      commit("setForcePacket", val);
    },
    basketList({ commit }, couponCode) {
      // kupon kod girildiginde getBasket api-sine gonderilmesi lazim. Bu yuzden bazi kontroller var
      // NOT: localdeki kupon kod kontrolu neden burada yapilmadi cunku kullanici localden kupon kodunu degisirse sepeti getiren api de patlar..
      // ..kupon basariliysa bu api tetiklendigi icin sorun yok.
      if (isCookieEnabled() ? getCookie("accessTokenB") : false) { // login olmus kullaniciysa
        getBasket({
          ...(couponCode && {
            couponCode: couponCode,
          })
        }).then((response) => {
          commit("setBasketList", response.data.basketList);
          commit("isLoggedIn_Store", true);
          if (couponCode) { // kupon kodu girildiyse
            commit("setCouponCode", couponCode); // kupon kod varsa kaydedilir
            commit("setTotalBasketInfo", {
              couponDiscountType: response.data.couponDiscountType,
              discountPercent: response.data.discountPercent,
              lastDiscountedPrice: response.data.lastDiscountedPrice,
              totalCouponDiscountedPrice: response.data.totalCouponDiscountedPrice, // yuzdeli kupon indiriminde gelecek deger
              couponBasketDiscountPrice: response.data.couponBasketDiscountPrice, // rakamli kupon indiriminde gelecek deger
              totalLastBasePrice: response.data.totalLastBasePrice
            });
          }
          else {
            commit("setTotalBasketInfo", {
              lastDiscountedPrice: response.data.lastDiscountedPrice,
              totalLastBasePrice: response.data.totalLastBasePrice
            });
          }
        }).catch(error => {
          // token olmadigi durumda beyaz ekran gelmesin diye localStorage temizlenip, login sayfaina yonlenir
          if (error.response.data.message == "Unauthorized") {
            isCookieEnabled() ? deleteCookie("accessTokenB") : null;
            authenticationService.logOut(); // cikis yapilir
            router.push('/'); // token olmadigi icin de login-e yonlenir
          }
        })
      }
      else { // misafir kullaniciysa
        if(isCookieEnabled() ? !getCookie("basket") : true) return
        var localBasket = JSON.parse(getCookie("basket"))
        var basketObj = {basketList: [], totalLastBasePrice: null, lastDiscountedPrice: null}
        localBasket.forEach(packetId => {
          getPacketByPacketId(packetId)
            .then((response) => {
              let r = response.data
              basketObj.totalLastBasePrice += r.lastBasePrice
              basketObj.lastDiscountedPrice += r.lastDiscountedPrice
              basketObj.basketList.push({
                id: r.id,
                packetId: r.id,
                packetName: r.packetName,
                lastBasePrice: r.lastBasePrice,
                lastDiscountedPrice: r.lastDiscountedPrice,
              })
              commit("setBasketList", basketObj.basketList);
              commit("setTotalBasketInfo", response.data.totalLastBasePrice);
            })
            .catch(() => {
              // app.$toastAlert({
              //   type: "error",
              //   title: this.$t("general.error"),
              //   message: "Paket bilgisi getirilirken hata oluştu",
              // });
            });
        })
      }
    },
    async packetList({ commit }) {
      await getStudentPacket().then(response => {
        commit("setPacketList", response.data);
      })
    },
    activeCourse({ commit }, course) {
      commit("activeCourse", course);
    },
    resetUser({ commit }) {
      commit("setMenuList", []);
      commit("setUserRole", null);
      commit("setUserType", null);
      commit("setUserRolePermissions", []);
      commit("setUserId", null);
      isCookieEnabled() ? deleteCookie("activeCourse") : null;
      commit("setActivePackage", null);
      commit("setBasketList", []);
      // on tarafta gosterilecek/gerekli kullanici bilgilerin temizlenmesi
      commit("name", null);
      commit("surname", null);
      commit("email", null);
      commit("city", null);
    },
    setKvkkDateAccepted: ({ commit }, val) => {
      commit("kvkkDateAccepted", val);
    },
    set_name: ({ commit }, val) => {
      commit("name", val);
    },
    set_surname: ({ commit }, val) => {
      commit("surname", val);
    },
    set_city: ({ commit }, val) => {
      commit("city", val);
    },
    setIsLoggedIn_Store: ({ commit }, val) => {
      commit("isLoggedIn_Store", val);
    },
  },
  photo({ commit }, photo) {
    commit("photo", photo);
  },
  namespaced: true,
};
export default user;
