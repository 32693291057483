const moduleComponent = () =>
  import(/* webpackChunkName: "registerPage" */ "./index");
const registerGeciciComponent = () =>
  import(/* webpackChunkName: "registerGecici" */ "./index-gecici");

export const registerPage = [
  {
    path: "/uye-ol",
    name: "register",
    component: moduleComponent,
    meta: {
      title: "Üye ol",
      acceptedRoles: "ALL"
    },
  },

  //sorulacak
  {
    path: "/register-gecici",
    name: "register-gecici",
    component: registerGeciciComponent,
    meta: {
      acceptedRoles: "ALL"
    }
  },
];
