const appSettings = {
  state: {
    adminMiniSideBar: false, // admin paneldeki sidebar
    bgColorGray: true,
    bgColorBlue: true,
    answerListBar: true, // test ekranindaki sidebar
    subtopicMiniSideBar: false, // gelisim testi ekranindaki sidebar
  },
  getters: {},
  mutations: {
    adminMiniSideBar: (state, val) => (state.adminMiniSideBar = val),
    bgColorGray: (state, val) => (state.bgColorGray = val),
    bgColorBlue: (state, val) => (state.bgColorBlue = val),
    answerListBar: (state, val) => (state.answerListBar = val),
    subtopicMiniSideBar: (state, val) => (state.subtopicMiniSideBar = val),
  },
  actions: {
    adminMiniSideBar: ({ commit }, val) => commit("adminMiniSideBar", val),
    bgColorGray: ({ commit }, val) => commit("bgColorGray", val),
    bgColorBlue: ({ commit }, val) => commit("bgColorBlue", val),
    answerListBar: ({ commit }, val) => commit("answerListBar", val),
    subtopicMiniSideBar: ({ commit }, val) => commit("subtopicMiniSideBar", val),
  },
  namespaced: true
}
export default appSettings
