<template>
  <transition v-if="value" name="modal">
    <div class="z-50 fixed left-0 top-0 w-screen h-screen">
      <div
        class="w-full h-full flex items-center justify-center bg-black-800 bg-opacity-60"
        @click.self="close"
      >
        <div
          class="modal-content overflow-hidden relative bg-white rounded-lg"
          :class="[
            hintClass, sizeClass
          ]"
        >
          <!-- <button
            @click="close"
            aria-label="Kapat"
            class="text-white absolute top-6 right-6 w-4 h-4 text-text-gray-300 ml-4"
          >
            <svgIcon name="close" />
          </button> -->
          <div class="text-white bg-dolgerBlue p-4 text-left">
            <slot name="header"></slot>
          </div>
          <div class="p-6 overflow-y-auto max-h-96">
            <slot name="body"></slot>
          </div>
          <div class="border-t-2 p-2 border-gray-100">
            <slot name="footer">
              <div class="flex justify-end">
                <button
                  aria-label="Kapat"
                  class="px-6 py-2 bg-gray-100 hover:bg-gray-200 rounded-md capitalize"
                  @click="close"
                >
                  Kapat
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    hintClass:{
      type: String,
      default: "w-11/12"
    },
    size: {
      type: Number,
      default: 1
    }
  },
  computed: {
    sizeClass() {
      let sizeClass = []
      if (this.size == 0.3) sizeClass.push("max-w-sm")
      else if (this.size == 1) sizeClass.push("max-w-xl")
      else if (this.size == 2) sizeClass.push("max-w-2xl")
      else if (this.size == 3) sizeClass.push("max-w-3xl")
      else if (this.size == 4) sizeClass.push("max-w-4xl")
      return sizeClass
    }
  },
  methods: {
    close() {
      this.$emit("input", false)
      this.$emit("closed")
    }
  }
}
</script>

<style lang="scss">
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.4s ease;
  .modal-content {
    transition: transform 0.2s ease;
  }
}
.modal-enter,
.modal-leave-to {
  opacity: 0;
  .modal-content {
    transform: scale(0.7);
  }
}
</style>
