var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('QuestionGalleryView',_vm._b({on:{"favorite":_vm.setFavoriteQuestion,"solution":_vm.questionSolution,"drawMode":_vm.drawMode,"hintMode":_vm.openHintMode},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},'QuestionGalleryView',{
    question: _vm.question,
    buttonPending: _vm.buttonPending,
    pending: _vm.pending,
    showTrueOption: _vm.showTrueOption,
    showDifficulty: _vm.showDifficulty
  },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }