import alertManagerComp from "./components/alertManager"
export default {
  install(Vue) {
    const toastAlert = new Vue({
      data: {
        alertList: [],
        alertLastId: 1
      }
    })
    const alertManager = new Vue({
      render(h) {
        return h(alertManagerComp, {
          props: {
            alertList: toastAlert.$data.alertList
          },
          on: {
            removeLast: () => {
              toastAlert.alertList.shift()
            },
            removeAlert: alertId => {
              toastAlert.alertList = toastAlert.alertList.filter(
                alert => alert.id !== alertId
              )
            }
          }
        })
      }
    }).$mount()
    document.body.appendChild(alertManager.$el)

    Vue.prototype.$toastAlert = alert => {
      alert.id = toastAlert.alertLastId
      toastAlert.alertList.push(alert)
      toastAlert.alertLastId++
    }
    Vue.prototype.$alertList = () => toastAlert.$data.alertList
  }
}
