const global = {
  state: {
    navbarHeight: "", // tum navbar fixed alan oldugu icin sayfalarda ne kadar bosluk degerinin verilmesi bu deger ile yapilir.
    topNavbarHeight: "", // navbar-in yukarisinda acilacak olan alan yuksekligi
  },
  getters: {},
  mutations: {
    setNavbarHeight: (state, val) => (state.navbarHeight = val),
    setTopNavbarHeight: (state, val) => (state.topNavbarHeight = val),
  },
  actions: {
    updateNavbarHeight({ commit }, height) {
      commit("setNavbarHeight", height);
    },
    updateTopNavbarHeight({ commit }, height) {
      commit("setTopNavbarHeight", height);
    },
  },
  namespaced: true,
};
export default global;
