const allReportsComponent = () => import(/* webpackChunkName: "reports" */ "./allReports/index")
const reportsComponent = () => import(/* webpackChunkName: "reports" */ "./index")
export const reports = [
  {
    path: "/all-reports/:basePacketId/:topicId",
    name: "allReports",
    component: allReportsComponent,
    meta: {
      title: "Tüm Raporlarım",
      bgColorGray: true,
      acceptedRoles: "STUDENT,SCL_COORDINATOR,STUDENT_PARENT"
    }
  },
  {
    path: "/reports/:testId",
    name: "reports",
    component: reportsComponent,
    meta: {
      title: "Raporlarım",
      bgColorGray: true,
      acceptedRoles: "STUDENT,SCL_COORDINATOR,STUDENT_PARENT"
    }
  },
]
