const moduleComponent = () => import(/* webpackChunkName: "legal" */ "./index");

const sss = () => import(/* webpackChunkName: "sss" */ "./pages/sss");

export const help = [
  {
    path: "/",
    name: "help",
    component: moduleComponent,
    meta: {
      title: "Yardım",
      notRequiresAuth: true,
    },
    children: [
      {
        path: "/sikca-sorulan-sorular",
        name: "sss",
        component: sss,
        meta: {
          title: "Sıkça Sorulan Sorular",
          notRequiresAuth: true,
        },
      },
    ],
  },
];
