import axios from "@/services/axios/authAxios"
import { app } from "@/main.js"
import store from "@/store"

const sendQuestionReport = async report => {
  await axios.post("/relatedQuestionIssue", report)
}
const setFavorite = async question => {
  const data = {
    question: {
      id: question.questionId
    },
    status: !question.fav ? 1 : 0
  }
  const response = await axios.post("/userfavques", data)
  return response.status
}
const setReserve = async question => {
  const response = await axios.post("/school/questionReserve/" + question.questionId)
  return response.status
}
const deleteReserve = async question => {
  const response = await axios.delete("/school/questionReserve/" + question.questionId)
  return response.status
}
const saveQuestion = async (testUserId, questionId, bookmark) => {
  const response = await axios.post("/question/questionBookmark", {
    testUserId,
    questionId,
    bookmark
  })
  return response.status
}
const pullQuestion = async list => {
  return await axios
    .get("/question/getAllQuestions/" + list.toString())
    .then(response => response.data)
}
const testQuestions = async testId => {
  return await axios
    .get("/question/getAllQuestionsByTestId/" + testId)
    .then(response => response.data)
}
const answerQuestion = async (testUserId, questionId, optionCodeType, clientCode) => {
  return await axios
    .post("/question/questionOptionMark", {
      testUserId,
      questionId,
      optionCodeType,
      clientCode
    })
    .then(response => response.data)
}
const getQuestionOptionByIndex = index => {
  const options = ["A", "B", "C", "D", "E"]
  return options[index - 1]
}
const getQuestionOptionList = () => {
  const options = ["A", "B", "C", "D"]
  return options
}
const questionaddOneSolutionVideoViewCount = async (
  testId,
  questionId,
  testUserId = null
) => {
  await axios.put("/questionUserProperties/addOneSolutionVideoViewCount", {
    testId,
    questionId,
    testUserId
  })
}
const questionaddViewDuration = async (testId, questionId, duration) => {
  await axios.put("/questionUserProperties/addViewDuration", {
    testId,
    questionId,
    duration
  })
}

const setFavoriteWInfo = async question => {
  // if (question.favoritePending) return
  // question.favoritePending = true
  // let message = ""
  await setFavorite(question)
    .then(() => {
      // message = question.fav
      //   ? app.$t("question.successRemovedFromFavorite")
      //   : app.$t("question.successAddedToFavorite")
      question.fav = !question.fav
      // app.$toastAlert({
      //   type: "success",
      //   title: app.$t("general.success"),
      //   message
      // })
    })
    .catch(() => {
      alert("favoriye eklenirken hata olustu")
      // message = question.fav
      //   ? app.$t("question.errorRemovedFromFavorite")
      //   : app.$t("question.errorAddedToFavorite")
      // app.$toastAlert({
      //   type: "error",
      //   title: app.$t("general.error"),
      //   message
      // })
    })
    // .finally(() => (question.favoritePending = false))
}

const setReserveWInfo = question => {
  if (question.reservePending) return
  question.reservePending = true
  if(question.reserve == undefined){
    if(question.reserveSchoolIds != null && question.reserveSchoolIds.includes(store.state.user.schoolId)){
      deleteReserveFunc(question)
    }else{
      setReserveFunc(question)
    }
  }else{
    question.reserve = !question.reserve
    if(question.reserve == true) setReserveFunc(question)
    else deleteReserveFunc(question)
  }
}

async function deleteReserveFunc(question){
  await deleteReserve(question)
    .then(() => {
      question.reserve = false
      app.$toastAlert({
        type: "success",
        title: app.$t("general.success"),
        message: "Soru rezervlerden kaldırıldı."
      })
    })
    .catch(() => {
      app.$toastAlert({
        type: "error",
        title: app.$t("general.error"),
        message: "Soru rezervlerden kaldırılırken bir hata oluştu."
      })
    })
    .finally(() => (question.reservePending = false))
}
async function setReserveFunc(question){
  await setReserve(question)
    .then(() => {
      question.reserve = true
      app.$toastAlert({
        type: "success",
        title: app.$t("general.success"),
        message: "Soru rezervlere eklendi."
      })
    })
    .catch(error => {
      if(error.response.data.shrtCode == "UNQ_INDEX"){
        question.reserve = true
        app.$toastAlert({
          type: "error",
          title: app.$t("general.error"),
          message: "Bu soru zaten rezervlerinizde ekli."
        })
      }else{
        app.$toastAlert({
          type: "error",
          title: app.$t("general.error"),
          message: "Soru rezervlere eklenirken bir hata oluştu."
        })
      }
    })
    .finally(() => (question.reservePending = false))
}

const questionObjectProperties = {
  testId: "",
  courseId: "",
  questionImageUrl: "",
  questionImageWidth: "",
  questionImageHeight: "",
  diffSituation: "",
  questionTypeId: "",
  questionPoolId: "",
  isNewGen: true,
  orderNo: null,
  isEnglish: false,
  topicId: "",
  questionCode: 20111030,
  questionStarRank: "",
  answerId: "",
  trueOptionCode: "",
  answerImageUrl: null,
  answerImageWidth: null,
  answerImageHeight: null,
  answerSolutionVideoUrl: "",
  hintInfo: "",
  tagInfo: "",
  questionStarRankOrder: 101,
  mainAttainmentId: "",
  topicName: "",
  mainAttainmentName: "",
  questionTypeShrtCode: "",
  hintUrl: null,
  questionOrder: null,
  active: true
}
export {
  sendQuestionReport,
  setFavorite,
  setReserve,
  deleteReserve,
  saveQuestion,
  pullQuestion,
  testQuestions,
  answerQuestion,
  getQuestionOptionByIndex,
  getQuestionOptionList,
  questionaddOneSolutionVideoViewCount,
  questionaddViewDuration,
  questionObjectProperties,
  setFavoriteWInfo,
  setReserveWInfo
}
