<template>
  <div id="navbarId" class="fixed top-0 right-0 flex flex-col justify-center w-full z-40">
    <!-- kupon alani START -->
    <div id="topNavbarId" class="hidden invisible cursor-default z-50">
      <Transition name="slide">
        <div v-if="showSecondNav && !isLoggedIn"
          class="w-full hidden md:flex justify-center background-animation text-white">
          <div class="w-full max-w-container-lg flex items-center justify-between gap-3 py-3 px-3 md:px-20">
            <router-link :to="{ name: 'packets' }" class="w-full text-left">
              <span class="text-white text-sm lg:text-base">🎒 Okula Dönüş Kampanyası: AIMAT25 İndirim Kodu ile %25
                İndirim Fırsatını Kaçırma! Hemen Kayıt Ol, Yapay Zeka ile Matematikte Öne Geç!
              </span>
              <!-- <strong class="text-white text-sm lg:text-base">ETZ20 </strong>
              <span class="text-white text-sm lg:text-base">Kodu ile </span> -->
            </router-link>
            <div class="flex items-center gap-4">
              <router-link :to="{ name: 'packets' }"
                class="hidden md:block bg-white font-medium px-3 py-2 rounded-lg hover:bg-opacity-90">
                <span class="text-sm lg:text-base text-black-800">Paketler</span>
              </router-link>
              <button @click="showSecondNav = false"
                class="p-2 rounded-lg transition-all hover:bg-white hover:text-black-800">
                <svg-icon name="close" class="w-3 h-3" />
              </button>
            </div>
          </div>
        </div>
      </Transition>
    </div>
    <!-- kupon alani END -->
    <div class="flex items-center justify-center bg-white w-full navbar" :class="isLandingPage ? null : 'border-b'">
      <div class="flex items-center justify-between px-3 md:px-20 w-full max-w-container-lg transition-all">
        <div class="flex items-center 310:gap-1 md:gap-4 lg:gap-8">
          <button @click="homePage()" aria-label="Ana sayfa" class="flex items-start w-full">
            <svg-icon name="logo" class="h-5 lg:h-6 w-24" />
          </button>
          <div
            v-if="!isBasketPagePaymentStep"
            class="flex items-center gap-4 lg:gap-6"
          >
            <router-link
              v-if="isLoggedIn && !isCoordinatorRole && !isStudentParentRole"
              :to="{ name: 'dashboard' }"
              class="hidden md:block text-sm lg:text-base font-medium text-black-800 hover:text-dolgerBlue transition-all"
            >
              Çalışmalarım
            </router-link>
            <router-link v-if="isLoggedIn && isCoordinatorRole" :to="{ name: 'studentList' }"
              class="hidden md:block text-sm lg:text-base font-medium text-black-800 hover:text-dolgerBlue transition-all w-max">
              Öğrenci listesi
            </router-link>
            <router-link
              v-if="isLoggedIn && isStudentParentRole"
              :to="{ name: 'dashboardPreviewStudent' }"
              class="hidden md:block text-sm lg:text-base font-medium text-black-800 hover:text-dolgerBlue transition-all w-max"
            >
              Çalışmalar
            </router-link>
            <router-link
              :to="{ name: 'howItWorks' }"
              class="hidden md:block text-sm lg:text-base font-medium text-black-800 hover:text-dolgerBlue transition-all w-max"
            >
              Nasıl çalışır
            </router-link>
            <router-link v-if="!isLoggedIn" :to="{ name: 'hakkimizda' }"
              class="hidden md:block text-sm lg:text-base font-medium text-black-800 hover:text-dolgerBlue transition-all">
              Hakkımızda
            </router-link>
            <router-link
              v-if="!isCoordinatorRole && !isStudentParentRole"
              :to="{ name: 'packets' }"
              class="hidden md:block text-sm lg:text-base font-medium text-black-800 hover:text-dolgerBlue transition-all"
            >
              Paketler
            </router-link>
            <!-- <router-link
              v-if="isFreeTrial"
              :to="{ name: 'diagnosticTest' }"
              class="hidden md:block text-sm lg:text-base font-medium text-black-800 w-max"
            >
              Ücretsiz Deneme
            </router-link> -->
            <router-link :to="{ name: 'contactUs' }"
              class="hidden md:block text-sm lg:text-base font-medium text-black-800 hover:text-dolgerBlue transition-all">
              İletişim
            </router-link>
          </div>
        </div>
        <!-- Odeme sayfasinda "Guvenli Odeme" gosterilecek -->
        <div v-if="isBasketPagePaymentStep" class="hidden md:flex items-center gap-2">
          <svg-icon name="secure-payment" class="w-4 h-4 lg:w-5 lg:h-5" />
          <div class="text-sm lg:text-base font-medium text-success-400">
            Güvenli ödeme
          </div>
        </div>
        <div class="flex items-center gap-4 lg:gap-8">
          <!-- <a href="tel:0537 568 09 02" aria-label="0537 568 09 02"
            class="hidden md:flex items-center gap-2.5 text-black-800"
          >
            <div class="hidden lg:block text-sm lg:text-base font-medium">0537 568 09 02</div>
            <svg-icon name="hotline" class="w-4 h-4 lg:w-5 lg:h-5" />
          </a> -->
          <div class="flex items-center gap-2 lg:gap-6">
            <a
              v-if="!isStudentParentRole"
              href="https://wa.me/905418989088?text=Merhaba"
              target="_blank"
              class="flex items-center gap-1 text-sm lg:text-base font-medium text-black-800 hover:text-dolgerBlue transition-all"
            >
              <svg-icon
                name="whatsapp"
                class="w-7 h-7 wp-shadow transition-all"
              />
              <div class="hidden xl:block text-sm lg:text-base font-medium">
                Görüşme Başlat
              </div>
            </a>
            <!-- <a
              href="mailto:destek@edunext.ai"
              target="_blank"
              class="hidden md:flex items-center gap-2 text-sm lg:text-base font-medium text-black-800"
            >
              <svg-icon name="email" class="w-5 h-5" />
              <div class="hidden 1100:block text-sm lg:text-base font-medium">
                destek@edunext.ai
              </div>
            </a> -->
            <router-link
              v-if="showBasketArea && !isCoordinatorRole && !isStudentParentRole"
              :to="{ name: 'basket' }"
              class="flex items-center relative gap-2.5 text-sm lg:text-base font-medium text-black-800 hover:text-dolgerBlue transition-all"
            >
              <span
                class="absolute -top-1.5 -right-2 flex items-center justify-center bg-dolgerBlue text-white text-2xs rounded-full h-3.5 lg:h-4 px-1 lg:px-1.5">{{
                basketLength }}</span>
              <div class="hidden xl:block text-sm lg:text-base font-medium">
                Sepet
              </div>
              <svg-icon name="basket" class="w-4.5 h-4.5 lg:w-5 lg:h-5" />
            </router-link>
          </div>
          <div v-if="isLoggedIn" class="group relative">
            <router-link :to="{ name: 'profile' }"
              class="hidden md:flex items-center gap-2.5 text-black-800 hover:text-dolgerBlue transition-all">
              <div class="hidden xl:block text-sm lg:text-base font-medium">
                Hesabım
              </div>
              <svg-icon name="my-account" class="w-4.5 h-4.5 lg:w-5 lg:h-5" />
            </router-link>
            <!-- Hover oldugunda gorunecek menu -->
            <div
              class="group-hover:max-h-[500px] max-h-0 absolute top-8 -right-1 flex flex-col w-72 bg-white rounded-md shadow-lg transition-all overflow-hidden"
            >
              <div
                class="text-xl text-white bg-dolgerBlue text-left px-4 py-3 rounded-t-md"
              >
                Hesabım
              </div>
              <div class="flex flex-col text-left mx-4 font-normal py-1.5">
                <router-link :to="{ name: 'profileInformation' }"
                  class="py-3 border-b hover:bg-gray-100 hover:-mx-4 hover:px-4">
                  Profil bilgileri
                </router-link>
                <router-link
                  v-if="isStudentRole"
                  :to="{ name: 'parentInformation' }"
                  class="py-3 border-b hover:bg-gray-100 hover:-mx-4 hover:px-4"
                >
                  <div class="flex items-center gap-3">
                    <div>Veli bilgileri</div>
                    <div v-if="!hasParent" class="text-xs font-medium bg-green-100 text-green-400 rounded-lg px-3 py-1">Ekle</div>
                  </div>
                </router-link>
                <router-link
                  v-if="isStudentRole"
                  :to="{ name: 'schoolInformation' }"
                  class="py-3 border-b hover:bg-gray-100 hover:-mx-4 hover:px-4"
                >
                  Okul bilgileri
                </router-link>
                <router-link :to="{ name: 'changePassword' }"
                  class="py-3 border-b hover:bg-gray-100 hover:-mx-4 hover:px-4">
                  Şifre değiştirme
                </router-link>
                <router-link
                  v-if="isStudentRole"
                  :to="{ name: 'myPackets' }"
                  class="py-3 border-b hover:bg-gray-100 hover:-mx-4 hover:px-4"
                >
                  Eğitim paketlerim
                </router-link>
                <router-link :to="{ name: 'help' }" class="py-3 border-b hover:bg-gray-100 hover:-mx-4 hover:px-4">
                  Yardım
                </router-link>
                <!-- <router-link :to="{ name: 'belirsizlink' }" class="py-3 border-b hover:bg-gray-100 hover:-mx-4 hover:px-4">Hizmet Şartları</router-link>
                <div class="cursor-pointer py-3 border-b hover:bg-gray-100 hover:-mx-4 hover:px-4">
                  Hesabı Sil
                </div> -->
                <div @click="logOutFunc()"
                  class="cursor-pointer py-3 text-red-450 hover:bg-red-450 hover:bg-opacity-10 hover:-mx-4 hover:px-4">
                  Çıkış Yap
                </div>
              </div>
            </div>
          </div>
          <div v-else class="relative group flex items-center gap-2">
            <router-link :to="{ name: 'login' }" class="text-sm lg:text-base font-medium text-black-800">
              Giriş yap
            </router-link>
            <div
              class="group-hover:visible invisible absolute top-6 right-1/2 translate-x-1/2 flex flex-col gap-2 p-3 bg-white border rounded-lg w-40">
              <router-link :to="{ name: 'login' }"
                class="text-sm lg:text-base font-medium text-dolgerBlue bg-dolgerBlue bg-opacity-10 transition-all hover:bg-opacity-100 hover:text-white p-2 rounded-lg">
                Giriş yap
              </router-link>
              <router-link :to="{ name: 'register' }"
                class="text-sm lg:text-base font-medium text-black-800 p-2 border transition-all hover:bg-dolgerBlue hover:text-white rounded-lg">
                Üye ol
              </router-link>
            </div>
          </div>
          <button @click="ShowMenu()" aria-label="Menü aç/kapat" class="md:hidden block">
            <div class="toggle" :class="mobileMenuVisibility ? 'active' : null">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>
        </div>
      </div>
    </div>

    <!-- Mobil menu -->
    <div class="relative z-50">
      <left-to-left>
        <template v-slot:main>
          <!-- 55 su anki navbar yuksekligi -->
          <div v-if="mobileMenuVisibility"
            class="fixed bg-white flex flex-col justify-between w-full max-w-container-lg h-full overflow-y-auto pt-14"
            :class="$store.state.global.navbarHeight < 55 ? 'pb-14' : 'pb-24'">
            <div>
              <img src="@/assets/images/illustrations/magnifying-glass.webp" alt="Magnifying glass"
                class="absolute top-4 right-14 z-negative" />
              <div v-for="(menu, index) in navbarMenus" :key="index">
                <router-link :to="menu.link" v-slot="{ isActive, route, isExactActive, href, navigate }" custom
                  class="px-3" v-if="menu.forLoggingInOnly">
                  <a @click="navigate" :href="href" class="flex items-center justify-between h-14 border-b" :class="isExactActive || (isActive && route.name != 'home')
                      ? 'text-dolgerBlue bg-dolgerBlue bg-opacity-10'
                      : ''
                    ">
                    <div class="font-medium">
                      {{ $t("menuTitles." + menu.label) }}
                    </div>
                    <svg-icon name="chevron-right" class="w-4 h-4 lg:w-5 lg:h-5" :class="isExactActive || (isActive && route.name != 'home')
                        ? 'text-dolgerBlue'
                        : 'text-black-800'
                      " />
                  </a>
                </router-link>
              </div>
              <div v-if="!isLoggedIn" class="flex flex-col gap-3 px-3 mt-10 mb-4">
                <button @click="$router.push({ name: 'register' })" aria-label="Üye ol"
                  class="flex items-center justify-center w-full h-[42px] bg-dolgerBlue text-white font-medium rounded-full">
                  Üye Ol
                </button>
                <div>veya</div>
                <button @click="$router.push({ name: 'login' })" aria-label="Giriş yap"
                  class="flex items-center justify-center w-full h-[42px] bg-dolgerBlue bg-opacity-10 text-dolgerBlue font-medium rounded-full">
                  Giriş Yap
                </button>
              </div>
            </div>
            <div>
              <a
                v-if="!isStudentParentRole"
                href="https://wa.me/905418989088?text=Merhaba"
                target="_blank"
                class="flex items-center gap-1 text-base font-medium text-black-800 px-3 my-4"
              >
                <svg-icon name="whatsapp" class="w-7 h-7" />
                <div class="text-base font-medium">Görüşme Başlat</div>
              </a>
              <!-- <a
                href="mailto:destek@edunext.ai"
                target="_blank"
                class="flex items-center gap-1 text-base font-medium text-black-800 px-3 my-4"
              >
                <svg-icon name="email" class="w-5 h-5" />
                <div class="text-base font-medium">destek@edunext.ai</div>
              </a> -->
            </div>
          </div>
        </template>
      </left-to-left>
    </div>
  </div>
</template>

<script>
import leftToLeft from "../animations/leftToLeft.vue";
import { authenticationService } from "@/services/auth/index";
import { userService } from "@/services/user/index";

export default {
  components: {
    leftToLeft,
  },
  data() {
    return {
      mobileMenuVisibility: false,
      isMobile: false,
      navbarMenus: [
        // Mobil ekranda gosterilecek menu
        {
          label: "myStudies",
          link: "/dashboard",
          forLoggingInOnly:
            this.$store.state.user.isLoggedIn_Store &&
            !"SCL_COORDINATOR".includes(userService.userRole()) &&
            "STUDENT_PARENT" != userService.userRole(),
        },
        {
          label: "studentList",
          link: "/student-list",
          forLoggingInOnly:
            this.$store.state.user.isLoggedIn_Store &&
            "SCL_COORDINATOR".includes(userService.userRole()),
        },
        {
          label: "studies",
          link: "/dashboard-preview-student",
          forLoggingInOnly:
            this.$store.state.user.isLoggedIn_Store &&
            "STUDENT_PARENT" == userService.userRole(),
        },
        // {
        //   label: "myStudies",
        //   link: "/my-studies",
        //   forLoggingInOnly: this.$store.state.user.isLoggedIn_Store,
        // },
        {
          label: "howItWorks",
          link: "/nasil-calisir",
          forLoggingInOnly: true,
        },
        {
          label: "aboutUs",
          link: "/hakkimizda",
          forLoggingInOnly: !this.$store.state.user.isLoggedIn_Store,
        },
        {
          label: "packets",
          link: "/paketler",
          forLoggingInOnly:
            !"SCL_COORDINATOR".includes(userService.userRole()) &&
            "STUDENT_PARENT" != userService.userRole(),
        },
        {
          label: "contactUs",
          link: "/iletisim",
          forLoggingInOnly: true,
        },
        {
          label: "basket",
          link: "/sepetim",
          forLoggingInOnly:
            this.$store.state.user.isLoggedIn_Store &&
            !"SCL_COORDINATOR".includes(userService.userRole()) &&
            "STUDENT_PARENT" != userService.userRole(),
        },
        // {
        //   label: "supportCenter",
        //   link: "/support-center",
        //   forLoggingInOnly: true,
        // },
        // {
        //   label: "favorites",
        //   link: "/favorites",
        //   forLoggingInOnly: this.$store.state.user.isLoggedIn_Store,
        // },
        {
          label: "myAccount",
          link: "/profile",
          forLoggingInOnly: this.$store.state.user.isLoggedIn_Store,
        },
      ],
      showSecondNav: true,
      basketLength: 0,
    };
  },
  watch: {
    "$route.name"() {
      this.mobileMenuVisibility = false;
    },
    mobileMenuVisibility() {
      if (this.$route.name == "login" || this.$route.name == "register") return;
    },
    "$store.state.user.basketList"(val) {
      this.basketLength = val?.length;
    },
  },
  mounted() {
    if (typeof window !== 'undefined') {
      this.resizeWindow(); // sayfa ilk yuklendiginda mobil ekrani mi degil mi ogrenmek icin burada cagrildi
      window.addEventListener("resize", this.resizeWindow);
      // tum navbar olcu degeri
      const resize_ob_nav = new ResizeObserver((entries) => {
        let rect = entries[0].contentRect;
        let height = rect.height;
        this.$store.dispatch("global/updateNavbarHeight", height);
      });
      resize_ob_nav.observe(document.getElementById("navbarId"));
      // navbar-in yukarisinda acilacak olan alanin olcu degeri
      const resize_ob_topNav = new ResizeObserver((entries) => {
        let rect = entries[0].contentRect;
        let height = rect.height;
        this.$store.dispatch("global/updateTopNavbarHeight", height);
      });
      resize_ob_topNav.observe(document.getElementById("topNavbarId"));
      this.basketLength = this.$store.state.user.basketList?.length;
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeWindow);
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.user.isLoggedIn_Store;
    },
    isBasketPage() {
      return this.$route.name == "basket";
    },
    isBasketPagePaymentStep() {
      return (
        this.$route.name == "basket" &&
        this.$route.query.step == "payment-information"
      );
    },
    showBasketArea() {
      let isShow = true;
      if (this.isMobile) {
        isShow = true;
      } else {
        if (!this.isBasketPage) isShow = true;
        else isShow = false;
      }
      return isShow;
    },
    isLandingPage() {
      return this.$route.name == "landingPage";
    },
    // isFreeTrial() {
    //   return this.$store.state.user.isFreeTrial;
    // },
    isCoordinatorRole() {
      return "SCL_COORDINATOR".includes(userService.userRole());
    },
    isStudentParentRole() {
      return userService.userRole() == "STUDENT_PARENT";
    },
    isStudentRole() {
      return userService.userRole() == "STUDENT"
    },
    hasParent() {
      return this.$store.state.user.parent?.name ? true : false
    },
  },
  methods: {
    ShowMenu() {
      this.mobileMenuVisibility
        ? (this.mobileMenuVisibility = false)
        : (this.mobileMenuVisibility = true);
    },
    resizeWindow() {
      if (window.innerWidth > 768) {
        this.mobileMenuVisibility = false;
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
    homePage() {
      if (this.$route.name == "landingPage") {
        this.mobileMenuVisibility = false;
        return;
      }
      this.$router.push({ name: "landingPage" });
    },
    logOutFunc() {
      this.$popupModal({
        type: "error",
        title: "Çıkış yapmak istediğinize emin misiniz?",
        buttonText: "Çıkış yap",
      }).then((res) => {
        if (res) {
          this.$router.push({ name: "login" });
          authenticationService.logOut();
        }
      });
    },
  },
};
</script>

<style scoped>
@media only screen and (min-width: 1024px) {
  .navbar {
    height: 80px !important;
    min-height: 80px !important;
    max-height: 80px !important;
  }
}

.navbar {
  height: 52px;
  min-height: 52px;
  max-height: 52px;
  transition: 0.25s;
}

.toggle {
  position: relative;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* border: 1px solid red; */
}

.toggle span {
  position: absolute;
  width: 10px;
  height: 2.4px;
  background: #242424;
  border-radius: 4px;
  transition: 0.5s;
}

.toggle span:nth-child(1) {
  transform: translateY(-8px);
  width: 25px;
  left: 0px;
}

.toggle.active span:nth-child(1) {
  width: 25px;
  transform: translateY(0px) rotate(45deg);
  transition-delay: 0.125s;
}

.toggle span:nth-child(2) {
  transform: translateY(8px);
  width: 25px;
  left: 0px;
}

.toggle.active span:nth-child(2) {
  width: 25px;
  transform: translateY(0px) rotate(315deg);
  transition-delay: 0.25s;
}

.toggle span:nth-child(3) {
  width: 25px;
  left: 0px;
}

.toggle.active span:nth-child(3) {
  transform: translateX(30px);
}

.slide-leave-active,
.slide-enter-active {
  transition: all 0.4s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(-100px);
  opacity: 0;
}

/* Demo Purpose Only*/
.demo {
  font-family: "Raleway", sans-serif;
  color: #fff;
  display: block;
  margin: 0 auto;
  padding: 15px 0;
  text-align: center;
}

.demo a {
  font-family: "Raleway", sans-serif;
  color: #000;
}

.background-animation {
  background: linear-gradient(132deg, #df5b93, #1c7bff, #333333);
  background-size: 400% 400%;
}

@media screen and (max-width: 1280px) {
  .wp-shadow {
    filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.6));
  }
}
</style>