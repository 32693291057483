<template>
  <div
    class="fixed top-4 right-4 flex flex-col alert-width z-40"
    @mouseover="mouseOver = true"
    @mouseleave="mouseOver = false"
  >
    <transition-group name="alert-list">
      <alertItem
        v-for="alert in alertList"
        :type="alert.type"
        :key="alert.id"
        :id="alert.id"
        @removeLast="$emit('removeLast')"
        @removeAlert="removeAlert"
      >
        <template v-slot:title>
          {{ alert.title }}
        </template>
        <template v-slot:content>
          {{ alert.message }}
        </template>
        <template v-slot:buttonName>
          <button
            v-if="alert.buttonName"
            class="bg-dolgerBlue text-white text-xs px-2 py-1 rounded-full"
            :aria-label="alert.buttonName"
          >
            {{ alert.buttonName }}
          </button>
        </template>
      </alertItem>
    </transition-group>
  </div>
</template>

<script>
import alertItem from "./alert";

export default {
  components: {
    alertItem,
  },
  props: {
    alertList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      mouseOver: false,
      isRemoving: false,
      timer: setTimeout(() => {
        this.isRemoving = false;
        this.$emit("removeLast");
      }, 3000),
    };
  },
  watch: {
    alertList(value) {
      if (value.length) {
        if (this.mouseOver) {
          clearTimeout(this.timer);
        } else {
          this.isRemoving ? clearTimeout(this.timer) : null;
          this.isRemoving = true;
          this.timer = setTimeout(() => {
            this.isRemoving = false;
            this.$emit("removeLast");
          }, 3000);
        }
      }
    },
    mouseOver(value) {
      value
        ? clearTimeout(this.timer)
        : this.isRemoving
        ? (this.timer = setTimeout(() => {
            this.isRemoving = false;
            this.$emit("removeLast");
          }, 3000))
        : null;
    },
  },
  methods: {
    removeAlert(id) {
      this.$emit("removeAlert", id);
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 450px) {
  .alert-width {
    width: calc(100% - 30px) !important;
  }
}
.alert-width {
  width: 420px;
}
.alert-list-enter-active,
.alert-list-leave-active {
  transition: all 0.3s ease-in-out;
}
.alert-list-enter,
.alert-list-leave-to {
  opacity: 0;
  transform: translateX(80px);
}
.alert-list-leave-active {
  position: absolute;
}
.alert-list-move {
  transition: transform 0.5s ease-in-out;
  transition-delay: 0.3s;
}
</style>
