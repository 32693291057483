const contactUsComponent = () =>
  import(/* webpackChunkName: "contactUs" */ "./index");

export const contactUs = [
  {
    path: "/iletisim",
    name: "contactUs",
    component: contactUsComponent,
    meta: {
      title: "İletişim",
      notRequiresAuth: true,
    },
  },
];
