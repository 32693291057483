<template>
  <div
    :class="[
      appBgClassString,
      isAnyAdmin ? 'md:w-screen md:h-screen md:flex md:justify-between' : null,
    ]"
  >
    <div v-if="isAnyAdmin" class="w-min h-full">
      <sideBar />
    </div>
    <div
      ref="appBody"
      id="appBody"
      class="appBody flex justify-center min-h-full relative"
      :class="[
        isAnyAdmin ? 'md:w-admin-body md:pt-0 pt-16 h-screen overflow-y-auto' : null
      ]"
    >
      <!-- navbar her sayfada tanimlanmak yerine burada tek tanimlandi. Bu yuzden max uzunluk burada tanimlandigi kadar oluyor. Max uzunluk sayfa uzunluguymus gibi gorunsun diye bu div eklendi. -->
      <!-- <div v-if="!isAnyAdmin" class="hidden lg:block bg-white absolute w-full h-20 border-b z-0"></div> -->
      <Navbar v-if="!isAnyAdmin" />
      <div
        id="pageBody"
        class="max-w-container-lg flex flex-col w-full min-h-full z-10"
        :class="!isAnyAdmin ? 'router-margin-top' : ''"
      >
        <!-- NOT: "<transition name="view" appear>" ile sayfalar arasi fade animation eklenebilir -->
        <router-view />
        <FooterBar v-if="!isAnyAdmin" />
      </div>
    </div>
    <QuestionGalleryViewRoot />
  </div>
</template>

<script>
import Navbar from "@/common/components/bars/navbar.vue";
import sideBar from "@/common/components/sideBar/index.vue";
import QuestionGalleryViewRoot from "@/common/components/modals/questionGalleryViewRoot.vue";
import FooterBar from "@/common/components/bars/footerBar";

export default {
  data() {
    return {
      keypressArr: "",
    };
  },
  components: {
    Navbar,
    sideBar,
    QuestionGalleryViewRoot,
    FooterBar
  },
  computed: {
    appBgClassString() {
      var classString = "bg-white";
      if (this.$store.state.appSettings.bgColorGray)
        classString = "bg-appBackground";
      if (this.$store.state.appSettings.bgColorBlue)
        classString = "bg-dolgerBlue";
      return classString;
    },
    isAnyAdmin() {
      if (
        "ADMIN,SUPERADMIN,MATH_ADMIN,SCIENCE_ADMIN,ADMIN,SOCIAL_ADMIN,TURKISH_ADMIN".includes(
          this.$store.state.user.userRole
        )
      )
        return true;
      else return false;
    },
    scrollUnvisibility() {
      return this.$store.state.global.overflowHidden;
    },
  },
};
</script>

<style>
/* margin-top degerleri navbar height degerleri ile ayni olmali */
@media only screen and (min-width: 1024px) {
  .router-margin-top {
    margin-top: 80px !important;
  }
}
.router-margin-top {
  margin-top: 52px;
  transition: 0.25s;
}
:root {
  --test-side-bar-w: 280px;
  --test-side-bar-w-mini: 80px;
  --test-side-bar-w-cont: 200px;
  --admin-side-bar-w: 250px;
  --subtopic-side-bar-w: 280px;
}
.adminMiniSideBar {
  --admin-side-bar-w: 100px;
  transition-delay: 0.2s;
}
.subtopicMiniSideBar {
  --subtopic-side-bar-w: 80px;
}
.appBody {
  transition: width 0.2s linear;
}

/* "view" adindaki Router Transition. Ileride eklenmek istenirse silinmedi */
/* .view-leave-active {
  transition: opacity 0.1s ease-in-out, transform 0.1s ease;
}
.view-enter-active {
  transition: opacity 0.1s ease-in-out, transform 0.1s ease;
  transition-delay: 0.1s;
}
.view-enter,
.view-leave-to {
  opacity: 0;
}
.view-enter-to,
.view-leave {
  opacity: 1;
} */
</style>