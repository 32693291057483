const moduleComponent = () =>
    import(/* webpackChunkName: "parentRegister" */ "./index");
  
  export const parentRegister = [
    {
      path: "/parent-register",
      name: "parentRegister",
      component: moduleComponent,
      meta: {
        title: "Veli kayıt",
        notRequiresAuth: true,
      },
    },
  ];
  