const developmentTestComponent = () => import(/* webpackChunkName: "developmentTest" */ "./index")
const topicCompletedComponent = () => import(/* webpackChunkName: "topicCompleted" */ "./pages/topicCompleted")
export const developmentTest = [
  {
    path: "/development-test/:testId",
    name: "developmentTest",
    component: developmentTestComponent,
    meta: {
      title: "Gelişim testi",
      acceptedRoles: "STUDENT"
    }
  },
  {
    path: "/topic-completed/:testId",
    name: "topicCompleted",
    component: topicCompletedComponent,
    meta: {
      title: "Bölümü başarıyla tamamladın!",
      acceptedRoles: "STUDENT",
      bgColorGray: true
    }
  },
]
