const studentListComp = () =>
  import(/* webpackChunkName: "studentListComp" */ "./index");

export const studentList = [
  {
    path: "/student-list",
    name: "studentList",
    component: studentListComp,
    meta: {
      title: "Öğrenci listesi",
      acceptedRoles: "SCL_COORDINATOR",
      bgColorGray: true,
    },
  },
];
