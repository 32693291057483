const moduleComponent = () =>
  import(/* webpackChunkName: "loginPage" */ "./index");
const loginGeciciComponent = () =>
  import(/* webpackChunkName: "loginGecici" */ "./index-gecici");
import { authenticationService } from "@/services/auth/index";
import { isCookieEnabled, getCookie } from "@/common/helpers/user"

export const loginPage = [
  {
    path: "/giris-yap",
    name: "login",
    component: moduleComponent,
    meta: {
      title: "Giriş yap",
      acceptedRoles: "ALL"
    },
    beforeEnter: (to, from, next) => {
      let accessTokenB = isCookieEnabled() ? getCookie("accessTokenB") : false
      let refreshTokenB = isCookieEnabled() ? getCookie("refreshTokenB") : false
      if (accessTokenB || refreshTokenB) {
        authenticationService.logOut(); //signIn ekranına yönlendirince eğer ki cookie doluysa çıkış yapıyor
      }
      next();
    },
  },
  //sorulacak
  {
    path: "/login-gecici",
    name: "login-gecici",
    component: loginGeciciComponent,
    meta: {
      acceptedRoles: "ALL"
    }
  },
];
