const moduleComponent = () =>
  import(/* webpackChunkName: "packets" */ "./index");
const packetsPacketComponent = () =>
  import(/* webpackChunkName: "packetsPacket" */ "./pages/packet");

export const packets = [
  {
    path: "/paketler",
    name: "packets",
    component: moduleComponent,
    meta: {
      title: "Paketler",
      notRequiresAuth: true,
      bgColorGray: true
    },
  },
  {
    path: "/paketler/:packetName",
    name: "packetsPacket",
    component: packetsPacketComponent,
    meta: {
      title: "Paketler",
      notRequiresAuth: true,
      bgColorGray: true
    },
  },
];
