import Vue from "vue"
import Vuex from "vuex"
import auth from "../services/auth/store/index"
import appSettings from "../services/appSettings/store/index"
import user from "../services/user/store/index"
import global from "./modules/global"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    user,
    appSettings,
    global
  }
})
