const basketComponent = () =>
  import(/* webpackChunkName: "basket" */ "./index");

export const basket = [
  {
    path: "/sepetim",
    name: "basket",
    component: basketComponent,
    meta: {
      title: "Sepetim",
      notRequiresAuth: true,
      bgColorGray: true,
    },
  },
];
