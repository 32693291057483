<template>
  <!-- <div
    class="flex xs:flex-row items-start justify-between gap-3 w-[calc(100%-70px)] md:w-[400px] fixed left-8 top-24 bg-dolgerBlue text-white px-3 py-3 rounded-md shadow-lg z-50 transition-all"
    v-if="updateExists"
  >
    <div class="flex flex-col gap-1">
      <span class="text-sm text-left">Yeni sürüm mevcut!</span>
      <a href="#" @click="refreshApp" class="text-sm text-left underline"
        >Güncellemek için tıklayın</a
      >
    </div>
    <button type="button" class="close" data-dismiss="alert">
      &times;
    </button>
  </div> -->
  <div class="absolute"></div>
</template>

<script>
export default {
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    };
  },
  methods: {
    showRefreshUI(e) {
      this.registration = e.detail;
      this.updateExists = true;
      this.refreshApp() // yeni versiyon oldugunda kullaniciya tiklamasi icin buton yerine otomatik getiriyor
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        // yeni version zamani ekran yenilenmesi
        window.location.reload();
      });
    }
  },
};
</script>

<style scoped>
</style>