const howItWorksComponent = () =>
  import(/* webpackChunkName: "howItWorks" */ "./index");

export const howItWorks = [
  {
    path: "/nasil-calisir",
    name: "howItWorks",
    component: howItWorksComponent,
    meta: {
      title: "Nasıl çalışır",
      notRequiresAuth: true,
    },
  },
];
