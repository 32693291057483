<template>
  <div
    v-if="status"
    class="fixed top-0 left-0 w-full h-full bg-black-800 text-green-400 z-50 overflow-y-auto"
  >
    <div class="grid grid-cols-2 gap-6 px-3 md:px-20 my-20">
      <div
        class="flex items-center justify-between gap-3 border border-green-400 rounded-md p-3"
      >
        <div class="flex items-center gap-3 overflow-hidden">
          <div class="text-xs truncate overflow-hidden">
            {{ siteMap.text || "--" }}
          </div>
          <button
            v-if="siteMap.text"
            @click="copyText(siteMap.text, 'siteMap')"
            :aria-label="siteMap.copyButtonName"
            class="text-2xs border border-green-400 hover:bg-green-400 hover:text-black-800 rounded-md px-2 py-1 transition-all mr-10"
          >
            {{ siteMap.copyButtonName }}
          </button>
        </div>
        <button @click="makeSiteMap()" aria-label="Sitemap oluştur" class="text-xs">SİTEMAP OLUŞTUR</button>
      </div>
      <!-- <div
        class="flex items-center justify-between gap-3 border border-green-400 rounded-md p-3"
      >
        <div class="text-xs">Çerezler</div>
        <button aria-label="Izni kaldır" class="text-xs">İZNİ KALDIR</button>
      </div> -->
    </div>
    <button @click="status = false" aria-label="Kapat" class="absolute top-4 right-4">
      <svg-icon name="close" class="w-4 h-4" />
    </button>
  </div>
</template>

<script>
import { manage } from "@/common/eventBuses/manage";
import router from "@/router/index";

export default {
  data() {
    return {
      status: false,
      siteMap: {
        text: "",
        copyButtonName: "KOPYALA",
      },
    };
  },
  created() {
    manage.$on("changeStatus", (data) => {
      this.status = data;
    });
  },
  methods: {
    async copyText(text, data) {
      await navigator.clipboard.writeText(text);
      this[data].copyButtonName = "Tamam";
      setTimeout(() => {
        this[data].copyButtonName = "KOPYALA";
      }, 2000);
    },
    makeSiteMap() {
      const routes = router
        .getRoutes()
        .map((r) => r.path)
        .filter((r) => !r.includes(":")) // removes routes with params
        .map((r) => `<url><loc>https://edunext.ai${r}</loc></url>`);
      let siteMap = `
          <?xml version='1.0' encoding='UTF-8'?>
          <urlset xmlns='http://www.sitemaps.org/schemas/sitemap/0.9'>
              ${routes.join("\n")}
          </urlset>
        `;
      this.siteMap.text = siteMap;

      // arama motoruna oncelik eklenmek istenirse lastmod sonrasina "priority: 1.0," eklenebilir
      let routesArr = router
        .getRoutes()
        .map((r) => r.path)
        .filter((r) => !r.includes(":")) // removes routes with params
        .map(
          (r) => `{
            path: "${r}",
            lastmod: "${new Date().toISOString().slice(0, 10)}",
            changefreq: "yearly",
          },`
        );
      console.log(String(routesArr.join("\n")));
    },
  },
};
</script>

<style>
</style>