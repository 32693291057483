import store from "@/store"
import { isCookieEnabled, getCookie } from "@/common/helpers/user"

export const userService = {
  menuList: () => {
    return store.state.user.menuList
  },
  pullUserInfo: async () => {
    await store.dispatch("user/userProfile")
  },
  pullUserPermissions: async () => {
    await store.dispatch("user/menuPermissions")
    // await store.dispatch("user/roleMenuPermissions")
  },
  getBasketList: async (couponCode) => {
    await store.dispatch("user/basketList", couponCode)
  },
  getUserPackets: async () => {
    await store.dispatch("user/packetList")
  },
  userPackets: () => {
    return store.state.user.packetList
  },
  canAccess: path => {
    return store.state.user.userRolePermissions.filter(obj =>
      obj.routerLink.filter(link => link == path)
    ).length
  },
  isAuthenticated: () => {
    let isAuthenticated = true
    let accessTokenB = isCookieEnabled() ? getCookie("accessTokenB") : false
    let refreshTokenB = isCookieEnabled() ? getCookie("refreshTokenB") : false
    !accessTokenB ? (isAuthenticated = false) : null
    !refreshTokenB ? (isAuthenticated = false) : null
    // !store.state.user.menuList.length ? (isAuthenticated = false) : null
    !store.state.user.userRole ? (isAuthenticated = false) : null
    // //!store.state.user.userType ? (isAuthenticated = false) : null
    // !store.state.user.userRolePermissions.length
    //   ? (isAuthenticated = false)
    //   : null
    return isAuthenticated
  },
  userInfo: () => {
    return {
      id: store.state.user.userId,
      name: store.state.user.name,
      surname: store.state.user.surname,
      fullName: store.getters["user/fullName"],
      role: store.state.user.userRole,
      photo: store.state.user.photo,
      schoolLogoUrl: store.state.user.schoolLogoUrl,
      gender: store.state.user.gender
    }
  },
  userRole: () => {
    return store.state.user.userRole
  },
  level: () => {
    return store.state.user.level
  },
  userCourses: () => {
    return store.state.user.courseList
  },
  userCoursesWithPackage: () => {
    return store.state.user.courseListWithPackage
  },
  schoolLogoUrl: () => {
    return store.state.user.schoolLogoUrl
  },
  activeCourse: () => {
    return store.state.user.activeCourse
  },
  activePackage: () => {
    return store.state.user.activePackage
  },
  changeCourse: course => {
    store.dispatch("user/activeCourse", course)
  }
}
