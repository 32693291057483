const landingPageComp = () =>
  import(/* webpackChunkName: "landingPage" */ "./index");

export const landingPage = [
  {
    path: "/",
    name: "landingPage",
    component: landingPageComp,
    meta: {
      bgColorGray: true,
      notRequiresAuth: true,
    }
  },
];
