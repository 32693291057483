const blogComponent = () =>
  import(/* webpackChunkName: "blog" */ "./index");

export const blog = [
  {
    path: "/blog/:title",
    name: "blog",
    component: blogComponent,
    meta: {
      title: "Blog",
      notRequiresAuth: true,
    },
  },
];
