const aboutUsComponent = () =>
  import(/* webpackChunkName: "hakkmizda" */ "./index");

export const aboutUs = [
  {
    path: "/hakkimizda",
    name: "hakkimizda",
    component: aboutUsComponent,
    meta: {
      title: "Hakkımızda",
      notRequiresAuth: true,
    },
  },
];
