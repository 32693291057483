const moduleComponent = () =>
  import(/* webpackChunkName: "myStudies" */ "./index");
const myStudiesStudyComponent = () =>
  import(/* webpackChunkName: "myStudiesStudy" */ "./pages/study");

export const myStudies = [
  {
    path: "/my-studies",
    name: "myStudies",
    component: moduleComponent,
    meta: {
      title: "Çalışmalarım",
      acceptedRoles: "STUDENT",
      bgColorGray: true
    },
  },
  {
    path: "/my-studies/study/:topicId/:basePacketId/:topicName",
    name: "myStudiesStudy",
    component: myStudiesStudyComponent,
    meta: {
      acceptedRoles: "STUDENT",
      bgColorGray: true
    },
  },
];
