import popup from "./components/popup"
export default {
  install(Vue) {
    Vue.prototype.$popup = options => {
      return new Promise(resolve => {
        const dialog = new Vue({
          methods: {
            closeHandler(val) {
              this.$destroy()
              this.$el.remove()
              return val
            }
          },
          render(h) {
            return h(popup, {
              props: {
                ...options
              },
              on: {
                confirm: val => resolve(this.closeHandler(val))
              }
            })
          }
        }).$mount()
        document.body.appendChild(dialog.$el)
      })
    }
  }
}
