const diagnosticTestGtbComponent = () => import(/* webpackChunkName: "diagnosticTestGtb" */ "./index")
const diagnosticTestGtbReadyComponent = () => import(/* webpackChunkName: "diagnosticTestGtbReady" */ "./pages/ready")
export const diagnosticTestGtb = [
  {
    path: "/diagnostic-test-gtb/",
    name: "diagnosticTestGtb",
    component: diagnosticTestGtbComponent,
    meta: {
      title: "Konu seç",
      notRequiresAuth: true,
      bgColorGray: true
    }
  },
  {
    path: "/diagnostic-test-gtb/ready/:testUserId",
    name: "diagnosticTestGtbReady",
    component: diagnosticTestGtbReadyComponent,
    meta: {
      title: "Testi çözmeye hazır mısın?",
      acceptedRoles: "STUDENT",
      bgColorBlue: true
    }
  }
]
