import axios from "axios"
import router from "@/router/index"
import { isCookieEnabled, getCookie } from "@/common/helpers/user"
// import { authenticationService } from "../auth/index"
// import store from "@/store"

let VUE_APP_API_BASEURL = window.location.origin
if (VUE_APP_API_BASEURL.includes("localhost")) {
  VUE_APP_API_BASEURL = process.env.VUE_APP_API_BASEURL;
} else {
  VUE_APP_API_BASEURL = VUE_APP_API_BASEURL + ":8443/api/"
}

const authAxios = axios.create({
  baseURL: VUE_APP_API_BASEURL,
  headers: {
    "content-type": "application/json"
  }
})
authAxios.interceptors.request.use(
  config => {
    const accessToken = isCookieEnabled() ? getCookie("accessTokenB") : ""
    // const activeCourse = localStorage.activeCourse
    config.headers.courseShrtCode = "COURSE_MATH"
    if (config.url == "insecure/credential/logout") {
      config.headers.Authorization = "Bearer " + accessToken
      return config
    }
    // if (activeCourse) config.headers.courseShrtCode = activeCourse
    // else if (router.history.current.name != "register")
    //   router.push({ name: "register" })
    if (accessToken) config.headers.Authorization = "Bearer " + accessToken
    // else if (router.history.current.name != "register")
    //   router.push({ name: "register" })
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
authAxios.interceptors.response.use(
  response => {
    //Versiyon kontrolü
    // if (
    //   response.headers.backendversioncache &&
    //   Number(response.headers.backendversioncache) >
    //     Number(process.env.VUE_APP_VERSION)
    // ) {
    //   window.location.reload()
    //   return
    // }

    return response
  },
  async error => {
    const originalRequest = error.config
    var refreshTokenB = isCookieEnabled() ? getCookie("refreshTokenB") : false
    if (error.config.url == "insecure/credential/logout")
      return Promise.reject(error)
    if (!refreshTokenB && router.history.current.name != "login")
      // router.push({ name: "login" })
      console.log("")
    else if (
      (error.response?.status === 403 || error.response?.status === 401) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true
      // await authenticationService
      //   .refreshAccessToken()
      //   .then(response => (localStorage.accessToken = response))
      //   .catch(() => {
      //     if (router.history.current.name != "register") // baska hesaptan girilirse register sayfasina yonlendirilir
      //       // store.dispatch("global/setAccLoggedOut") // kullaniciya bildiri gosterebilmek icin action calisir. bir data-nin degeri her baska hesaptan girildiginda 1 arttirilir. ve bu data degisimi kontrolu App.vue icinde yapildi
      //       router.push({ name: "register" })
      //   })
      return authAxios(originalRequest)
    }
    return Promise.reject(error)
  }
)
export default authAxios
