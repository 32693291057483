<template>
  <Transition>
    <div
      v-if="isOpen"
      class="flex xs:flex-row flex-col items-center gap-3 md:w-[600px] fixed md:left-8 md:bottom-8 bottom-0 bg-black-800 text-white px-3 md:py-3 py-8 rounded-t-md md:rounded-b-md z-50 transition-all"
    >
      <div class="flex flex-col gap-2 text-left">
        <div class="font-medium text-sm">
          Daha iyi bir deneyim için izninize ihtiyacımız var
        </div>
        <div>
          <span class="text-xs mr-2">
            Sizlere daha iyi bir deneyim sunabilmek için sitemizde çerezler
            kullanılmaktadır. Detaylı bilgi:
          </span>
          <router-link
            :to="{ name: 'cookies' }"
            class="underline font-medium text-xs"
            >Çerez politikası</router-link
          >
        </div>
      </div>
      <button
        @click="acceptCookies()"
        aria-label="Kabul et"
        class="px-8 py-2 bg-green-500 text-xs w-36 h-min rounded-md hover:bg-opacity-80"
      >
        Kabul Et
      </button>
    </div>
  </Transition>
</template>

<script>
import { isCookieEnabled } from "@/common/helpers/user"

export default {
  data() {
    return {
      isOpen: false,
    };
  },
  created() {
    if (!this.getCookiePermit === true) {
      this.isOpen = true;
    }
  },
  computed: {
    getCookiePermit() {
      return isCookieEnabled() ? this.getCookie('cookiePermit') : false;
    },
  },
  methods: {
    acceptCookies() {
      this.isOpen = false;
      isCookieEnabled() ? this.setCookie("cookiePermit", true) : null;
    },
  },
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>