const dashboardComp = () =>
  import(/* webpackChunkName: "dashboard" */ "./index");

import store from "@/store";

export const dashboard = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: dashboardComp,
    meta: {
      title: "Çalışmalarım",
      bgColorGray: true,
      acceptedRoles: "STUDENT",
    },
    beforeEnter: (to, from, next) => {
      if (store.state.user.forceFreeTrialPage) {
        next({ name: "diagnosticTest" });
      } else if (store.state.user.forceFreeTrialGtbTtbPage) {
        next({ name: "diagnosticTestGtb" });
      } else if (store.state.user.forcePacket) {
        next({ name: "packets" });
      } else {
        next();
      }
    },
  },
];
