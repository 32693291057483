<template>
  <div
    class="rounded-xl mb-3 rounded-2xl grid grid-cols-6 gap-1 px-2 py-5 items-center min-w-full z-50"
    :style="alertClassString"
  >
    <div class="flex justify-center h-full">
      <svgIcon :name="iconName" class="w-7 h-7" :class="iconColor" />
    </div>
    <div class="flex justify-between col-span-4">
      <div>
        <div class="font-medium text-sm lg:text-base">
          <slot name="title"></slot>
        </div>
        <div class="text-xs lg:text-sm">
          <slot name="content"></slot>
        </div>
      </div>
      <div class="flex items-center">
        <slot name="buttonName"></slot>
      </div>
    </div>
    <button
      class="flex justify-center h-full"
      @click="$emit('removeAlert', id)"
      aria-label="Kaldır"
    >
      <svgIcon name="close" class="w-4 h-4" style="color: #8c94a0" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "",
    },
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    alertClassString() {
      let classString = "";
      this.type === "success"
        ? (classString = "background-color:#daf5f0;")
        : this.type === "error"
        ? (classString = "background-color:#fedddf;")
        : this.type === "warning"
        ? (classString = "background-color:#fef5db;")
        : (classString = "background-color:#d2e5ff;");
      return classString;
    },
    iconName() {
      let iconName = "";
      this.type === "success"
        ? (iconName = "badge-success")
        : this.type === "error"
        ? (iconName = "warning-triangle")
        : this.type === "warning"
        ? (iconName = "attention-circle")
        : (iconName = "alert-circle");
      return iconName;
    },
    iconColor() {
      let iconClass = "";
      this.type === "success"
        ? (iconClass = "text-success-400")
        : this.type === "error"
        ? (iconClass = "text-red-450")
        : this.type === "warning"
        ? (iconClass = "text-warning-400")
        : (iconClass = "text-dolgerBlue");
      return iconClass;
    },
  },
};
</script>
<style></style>
