import axios from "axios"

let VUE_APP_API_BASEURL = window.location.origin
if (VUE_APP_API_BASEURL.includes("localhost")) {
  VUE_APP_API_BASEURL = process.env.VUE_APP_API_BASEURL;
} else {
  VUE_APP_API_BASEURL = VUE_APP_API_BASEURL + ":8443/api/"
}

const loginAxios = axios.create({
  baseURL: VUE_APP_API_BASEURL,
  headers: {
    "content-type": "application/json"
  }
})
export default loginAxios
