const diagnosticTestComponent = () => import(/* webpackChunkName: "diagnosticTest" */ "./index")
const diagnosticTestReadyComponent = () => import(/* webpackChunkName: "diagnosticTestReady" */ "./pages/ready")
export const diagnosticTest = [
  {
    path: "/diagnostic-test/",
    name: "diagnosticTest",
    component: diagnosticTestComponent,
    meta: {
      title: "Tanılama testi",
      notRequiresAuth: true,
      bgColorGray: true
    }
  },
  {
    path: "/diagnostic-test/ready/:testUserId",
    name: "diagnosticTestReady",
    component: diagnosticTestReadyComponent,
    meta: {
      title: "Tanılama testini çözmeye hazır mısın?",
      acceptedRoles: "STUDENT",
      bgColorBlue: true
    }
  }
]
