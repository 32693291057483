const moduleComponent = () => import(/* webpackChunkName: "legal" */ "./index");

const kvkk = () => import(/* webpackChunkName: "kvkk" */ "./pages/kvkk");
const distanceSellingContract = () => import(/* webpackChunkName: "distanceSellingContract" */ "./pages/distanceSellingContract");
const edunextTransactionGuide = () => import(/* webpackChunkName: "edunextTransactionGuide" */ "./pages/edunextTransactionGuide");
const cookies = () => import(/* webpackChunkName: "cookies" */ "./pages/cookies");
const informationSecurityPolicy = () => import(/* webpackChunkName: "informationSecurityPolicy" */ "./pages/informationSecurityPolicy");
const privacyPolicy = () => import(/* webpackChunkName: "privacyPolicy" */ "./pages/privacyPolicy");

export const legal = [
  {
    path: "/",
    name: "legal",
    component: moduleComponent,
    meta: {
      title: "Yasal",
      notRequiresAuth: true,
    },
    children: [
      {
        path: "/kvkk",
        name: "kvkk",
        component: kvkk,
        meta: {
          title: "Kişisel Verilerin Korunması",
          notRequiresAuth: true,
        },
      },
      {
        path: "/mesafeli-satis-sozlesmesi",
        name: "distanceSellingContract",
        component: distanceSellingContract,
        meta: {
          title: "Mesafeli Satış Sözleşmesi",
          notRequiresAuth: true,
        },
      },
      {
        path: "/edunext-islem-rehberi",
        name: "edunextTransactionGuide",
        component: edunextTransactionGuide,
        meta: {
          title: "Edunext İşlem Rehberi",
          notRequiresAuth: true,
        },
      },
      {
        path: "/cerez-politikasi",
        name: "cookies",
        component: cookies,
        meta: {
          title: "Çerez Politikası",
          notRequiresAuth: true,
        },
      },
      {
        path: "/bilgi-guvenligi-politikasi",
        name: "informationSecurityPolicy",
        component: informationSecurityPolicy,
        meta: {
          title: "Bilgi Güvenliği Politikası",
          notRequiresAuth: true,
        },
      },
      {
        path: "/gizlilik-politikasi",
        name: "privacyPolicy",
        component: privacyPolicy,
        meta: {
          title: "Privacy Policy",
          notRequiresAuth: true,
        },
      },
    ],
  },
];
