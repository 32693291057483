<template>
  <div class="flex flex-col items-center gap-3 md:px-0 px-3">
    <div class="flex flex-col items-center">
      <span class="text-2xl font-semibold py-3"
        >edu
        <span class="text-dolgerBlue text-2xl font-semibold -ml-1"
          >next</span
        ></span
      >
      <hr class="w-32" />
    </div>
    <div class="text-3xl">Çerezler devre dışı</div>
    <div class="flex flex-col">
      {{ infoText }}
      <a
        target="_blank"
        href="https://support.google.com/accounts/answer/61416?hl=tr"
        class="text-dolgerBlue"
        >Daha fazla bilgi</a
      >
    </div>
    <button
      v-if="!isIphoneOrIPad"
      @click="tryAgain()"
      class="text-white bg-dolgerBlue hover:bg-opacity-80 px-3 py-1 rounded-lg w-max"
    >
      Tekrar dene
    </button>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      meta: [{ name: "description", content: "Edunext.ai çerez politikası engellendi. Çerez ayarlarınızı güncelleyin." }],
    };
  },
  data() {
    return {
      infoText: "",
      isIphoneOrIPad: false,
    };
  },
  mounted() {
    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    if (iOSSafari) {
      this.isIphoneOrIPad = true
      this.infoText = `Safari'de çerezler devre dışı bırakılmış durumda. Önce çerezleri etkinleştirin. Ardından, Safari'yi yeniden başlatın.`;
    } else {
      this.infoText = `Tarayıcınızda çerezler devre dışı bırakılmış. Çerezlerin etkin olduğundan emin olun. Tarayıcınızı yeniden başlatın.`;
    }
  },
  methods: {
    tryAgain() {
      window.location.reload();
    },
  },
};
</script>

<style>
</style>