const testComponent = () => import(/* webpackChunkName: "test" */ "./index")
const testCompletedComponent = () => import(/* webpackChunkName: "testCompleted" */ "./pages/completed")
export const test = [
  {
    path: "/test/:testId",
    name: "test",
    component: testComponent,
    meta: {
      title: "Test",
      acceptedRoles: "STUDENT"
    }
  },
  {
    path: "/test-completed/",
    name: "testCompleted",
    component: testCompletedComponent,
    meta: {
      title: "Test tamamlandı",
      acceptedRoles: "STUDENT",
      bgColorGray: true
    },
    beforeEnter: (to, from, next) => {
      if(to.query?.testId) {
        next()
      }else {
        next("/")
      }
    },
  },
]
