<template>
  <div>
    <Navbar />
    <div class="flex flex-col items-center px-10 xs:px-3 lg:mt-20 mt-13">
      <div class="flex flex-col items-center gap-12 max-w-sm mt-20">
        <div class="flex justify-center select-none">
          <img src="@/assets/images/illustrations/404.webp" alt="404" class="" />
        </div>
        <div class="text-base xs:text-xl sm:text-2xl font-medium">
          Böyle Bir Sayfa Bulunamadı :/
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/common/components/bars/navbar.vue";

export default {
  metaInfo() {
    return {
      meta: [{ name: "description", content: "Sayfa bulunamadı - Edunext.ai'ye geri dönün ve matematik öğrenmeye devam edin." }],
    };
  },
  components: {
    Navbar
  }
};
</script>

<style></style>
