<template>
  <transition v-if="value" name="modal">
    <div class="z-50 fixed left-0 top-0 w-screen h-screen">
      <div
        class="w-full h-full flex items-center justify-center bg-black-800 bg-opacity-60"
        @click.self="close"
      >
        <div class="modal-content w-full max-w-sm overflow-hidden relative bg-white rounded-lg">
          <div
            class="text-black-800 bg-white border-b py-4 mx-4 text-center font-semibold"
          >
            Bağlantı Kesildi
          </div>
          <div class="p-6 overflow-y-auto max-h-[calc(100vh_-_170px)]">
            İnternet bağlantınız kesildi. Lütfen bağlantınızı kontrol edip tekrar deneyin
            <main-button
              @click="checkConnection()"
              text="Tekrar dene"
              :size="1"
              class="mt-4"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import mainButton from "@/common/components/buttons/mainButton.vue";

export default {
  data() {
    return {
      value: false,
    };
  },
  components: {
    mainButton,
  },
  mounted() {
    window.addEventListener("offline", this.checkConnection);
    window.addEventListener("online", this.checkConnection);
  },
  beforeDestroy() {
    window.removeEventListener("offline", this.checkConnection);
    window.removeEventListener("online", this.checkConnection);
  },
  methods: {
    checkConnection() {
      if (navigator.onLine) {
        this.value = false;
      } else {
        this.value = true;
      }
    },
    close() {
      this.value = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.4s ease;
  .modal-content {
    transition: transform 0.2s ease;
  }
}
.modal-enter,
.modal-leave-to {
  opacity: 0;
  .modal-content {
    transform: scale(0.7);
  }
}
</style>