import axios from "@/services/axios/authAxios";

const changePhoneNumber = async (phone) => {
  // telefon numarasi degistir
  await axios.put("user/changePhoneNumber", {
    phone: phone,
  });
};

const verifyPhoneNumber = async (code) => {
  // telefon numarasini onayla
  await axios.put("user/verifyPhoneNumber", {
    code: code,
  });
};

const updateProfile = async (
  name,
  surname,
  city,
  levelOrder,
  state,
  gender,
  username,
  schoolName,
  schoolBranch
) => {
  // profil guncelle
  await axios.put("user/profile", {
    name: name,
    surname: surname,
    city: city,
    levelOrder: levelOrder,
    state: state,
    gender: gender,
    username: username,
    schoolName: schoolName,
    schoolBranch: schoolBranch,
  });
};

const updateSchoolInfo = async (
  city,
  state,
  schoolName,
  levelOrder,
  schoolBranch,
) => {
  // profil guncelle
  await axios.put("user/schoolInfo", {
    city: city,
    state: state,
    schoolName: schoolName,
    levelOrder: levelOrder,
    schoolBranch: schoolBranch,
  });
};

const updateEmail = async (email) => {
  // email guncelleme
  await axios.put("user/profile/email", {
    email: email,
  });
};

const verifyUpdateEmail = async (confirmCode) => {
  // email guncelleme onay kodu
  await axios.post("user/profile/email/verify", {
    confirmCode: confirmCode,
  });
};

const getUserInvoiceProfile = async () => {
  return await axios.get("user/userInvoiceProfile");
};

const updateUserInvoiceProfile = async (params) => {
  return await axios.put("user/userInvoiceProfile", {
    ...params,
  });
};

const createUserInvoiceProfile = async (params) => {
  return await axios.post("user/userInvoiceProfile", {
    ...params,
  });
};

const citiesJson = [
  {
    id: 0,
    name: "Şehir seç",
    value: null,
  },
  {
    id: 1,
    name: "Adana",
    value: "Adana",
  },
  {
    id: 2,
    name: "Adıyaman",
    value: "Adıyaman",
  },
  {
    id: 3,
    name: "Afyonkarahisar",
    value: "Afyonkarahisar",
  },
  {
    id: 4,
    name: "Ağrı",
    value: "Ağrı",
  },
  {
    id: 68,
    name: "Aksaray",
    value: "Aksaray",
  },
  {
    id: 5,
    name: "Amasya",
    value: "Amasya",
  },
  {
    id: 6,
    name: "Ankara",
    value: "Ankara",
  },
  {
    id: 7,
    name: "Antalya",
    value: "Antalya",
  },
  {
    id: 75,
    name: "Ardahan",
    value: "Ardahan",
  },
  {
    id: 8,
    name: "Artvin",
    value: "Artvin",
  },
  {
    id: 9,
    name: "Aydın",
    value: "Aydın",
  },
  {
    id: 10,
    name: "Balıkesir",
    value: "Balıkesir",
  },
  {
    id: 74,
    name: "Bartın",
    value: "Bartın",
  },
  {
    id: 72,
    name: "Batman",
    value: "Batman",
  },
  {
    id: 69,
    name: "Bayburt",
    value: "Bayburt",
  },
  {
    id: 11,
    name: "Bilecik",
    value: "Bilecik",
  },
  {
    id: 12,
    name: "Bingöl",
    value: "Bingöl",
  },
  {
    id: 13,
    name: "Bitlis",
    value: "Bitlis",
  },
  {
    id: 14,
    name: "Bolu",
    value: "Bolu",
  },
  {
    id: 15,
    name: "Burdur",
    value: "Burdur",
  },
  {
    id: 16,
    name: "Bursa",
    value: "Bursa",
  },
  {
    id: 17,
    name: "Çanakkale",
    value: "Çanakkale",
  },
  {
    id: 18,
    name: "Çankırı",
    value: "Çankırı",
  },
  {
    id: 19,
    name: "Çorum",
    value: "Çorum",
  },
  {
    id: 20,
    name: "Denizli",
    value: "Denizli",
  },
  {
    id: 21,
    name: "Diyarbakır",
    value: "Diyarbakır",
  },
  {
    id: 81,
    name: "Düzce",
    value: "Düzce",
  },
  {
    id: 22,
    name: "Edirne",
    value: "Edirne",
  },
  {
    id: 23,
    name: "Elazığ",
    value: "Elazığ",
  },
  {
    id: 24,
    name: "Erzincan",
    value: "Erzincan",
  },
  {
    id: 25,
    name: "Erzurum",
    value: "Erzurum",
  },
  {
    id: 26,
    name: "Eskişehir",
    value: "Eskişehir",
  },
  {
    id: 27,
    name: "Gaziantep",
    value: "Gaziantep",
  },
  {
    id: 28,
    name: "Giresun",
    value: "Giresun",
  },
  {
    id: 29,
    name: "Gümüşhane",
    value: "Gümüşhane",
  },
  {
    id: 30,
    name: "Hakkari",
    value: "Hakkari",
  },
  {
    id: 31,
    name: "Hatay",
    value: "Hatay",
  },
  {
    id: 76,
    name: "Iğdır",
    value: "Iğdır",
  },
  {
    id: 32,
    name: "Isparta",
    value: "Isparta",
  },
  {
    id: 34,
    name: "İstanbul",
    value: "İstanbul",
  },
  {
    id: 35,
    name: "İzmir",
    value: "İzmir",
  },
  {
    id: 46,
    name: "Kahramanmaraş",
    value: "Kahramanmaraş",
  },
  {
    id: 78,
    name: "Karabük",
    value: "Karabük",
  },
  {
    id: 70,
    name: "Karaman",
    value: "Karaman",
  },
  {
    id: 36,
    name: "Kars",
    value: "Kars",
  },
  {
    id: 37,
    name: "Kastamonu",
    value: "Kastamonu",
  },
  {
    id: 38,
    name: "Kayseri",
    value: "Kayseri",
  },
  {
    id: 71,
    name: "Kırıkkale",
    value: "Kırıkkale",
  },
  {
    id: 39,
    name: "Kırklareli",
    value: "Kırklareli",
  },
  {
    id: 40,
    name: "Kırşehir",
    value: "Kırşehir",
  },
  {
    id: 79,
    name: "Kilis",
    value: "Kilis",
  },
  {
    id: 41,
    name: "Kocaeli",
    value: "Kocaeli",
  },
  {
    id: 42,
    name: "Konya",
    value: "Konya",
  },
  {
    id: 43,
    name: "Kütahya",
    value: "Kütahya",
  },
  {
    id: 44,
    name: "Malatya",
    value: "Malatya",
  },
  {
    id: 45,
    name: "Manisa",
    value: "Manisa",
  },
  {
    id: 47,
    name: "Mardin",
    value: "Mardin",
  },
  {
    id: 33,
    name: "Mersin",
    value: "Mersin",
  },
  {
    id: 48,
    name: "Muğla",
    value: "Muğla",
  },
  {
    id: 49,
    name: "Muş",
    value: "Muş",
  },
  {
    id: 50,
    name: "Nevşehir",
    value: "Nevşehir",
  },
  {
    id: 51,
    name: "Niğde",
    value: "Niğde",
  },
  {
    id: 52,
    name: "Ordu",
    value: "Ordu",
  },
  {
    id: 80,
    name: "Osmaniye",
    value: "Osmaniye",
  },
  {
    id: 53,
    name: "Rize",
    value: "Rize",
  },
  {
    id: 54,
    name: "Sakarya",
    value: "Sakarya",
  },
  {
    id: 55,
    name: "Samsun",
    value: "Samsun",
  },
  {
    id: 56,
    name: "Siirt",
    value: "Siirt",
  },
  {
    id: 57,
    name: "Sinop",
    value: "Sinop",
  },
  {
    id: 58,
    name: "Sivas",
    value: "Sivas",
  },
  {
    id: 63,
    name: "Şanlıurfa",
    value: "Şanlıurfa",
  },
  {
    id: 73,
    name: "Şırnak",
    value: "Şırnak",
  },
  {
    id: 59,
    name: "Tekirdağ",
    value: "Tekirdağ",
  },
  {
    id: 60,
    name: "Tokat",
    value: "Tokat",
  },
  {
    id: 61,
    name: "Trabzon",
    value: "Trabzon",
  },
  {
    id: 62,
    name: "Tunceli",
    value: "Tunceli",
  },
  {
    id: 64,
    name: "Uşak",
    value: "Uşak",
  },
  {
    id: 65,
    name: "Van",
    value: "Van",
  },
  {
    id: 77,
    name: "Yalova",
    value: "Yalova",
  },
  {
    id: 66,
    name: "Yozgat",
    value: "Yozgat",
  },
  {
    id: 67,
    name: "Zonguldak",
    value: "Zonguldak",
  },
];

const districtJson = [
  {
    id: 0,
    name: "İlçe seç",
    value: null,
  },
  {
    name: "Aladağ",
    cityName: "Adana",
    value: "Aladağ",
    id: 1,
  },
  {
    name: "Ceyhan",
    cityName: "Adana",
    value: "Ceyhan",
    id: 2,
  },
  {
    name: "Çukurova",
    cityName: "Adana",
    value: "Çukurova",
    id: 3,
  },
  {
    name: "Feke",
    cityName: "Adana",
    value: "Feke",
    id: 4,
  },
  {
    name: "İmamoğlu",
    cityName: "Adana",
    value: "İmamoğlu",
    id: 5,
  },
  {
    name: "Karai̇salı",
    cityName: "Adana",
    value: "Karai̇salı",
    id: 6,
  },
  {
    name: "Karataş",
    cityName: "Adana",
    value: "Karataş",
    id: 7,
  },
  {
    name: "Kozan",
    cityName: "Adana",
    value: "Kozan",
    id: 8,
  },
  {
    name: "Pozantı",
    cityName: "Adana",
    value: "Pozantı",
    id: 9,
  },
  {
    name: "Sai̇mbeyli̇",
    cityName: "Adana",
    value: "Sai̇mbeyli̇",
    id: 10,
  },
  {
    name: "Sariçam",
    cityName: "Adana",
    value: "Sariçam",
    id: 11,
  },
  {
    name: "Seyhan",
    cityName: "Adana",
    value: "Seyhan",
    id: 12,
  },
  {
    name: "Tufanbeyli̇",
    cityName: "Adana",
    value: "Tufanbeyli̇",
    id: 13,
  },
  {
    name: "Yumurtalık",
    cityName: "Adana",
    value: "Yumurtalık",
    id: 14,
  },
  {
    name: "Yüreği̇r",
    cityName: "Adana",
    value: "Yüreği̇r",
    id: 15,
  },
  {
    name: "Besni̇",
    cityName: "Adıyaman",
    value: "Besni̇",
    id: 16,
  },
  {
    name: "Çeli̇khan",
    cityName: "Adıyaman",
    value: "Çeli̇khan",
    id: 17,
  },
  {
    name: "Gerger",
    cityName: "Adıyaman",
    value: "Gerger",
    id: 18,
  },
  {
    name: "Gölbaşı",
    cityName: "Adıyaman",
    value: "Gölbaşı",
    id: 19,
  },
  {
    name: "Kahta",
    cityName: "Adıyaman",
    value: "Kahta",
    id: 20,
  },
  {
    name: "Merkez",
    cityName: "Adıyaman",
    value: "Merkez",
    id: 21,
  },
  {
    name: "Samsat",
    cityName: "Adıyaman",
    value: "Samsat",
    id: 22,
  },
  {
    name: "Si̇nci̇k",
    cityName: "Adıyaman",
    value: "Si̇nci̇k",
    id: 23,
  },
  {
    name: "Tut",
    cityName: "Adıyaman",
    value: "Tut",
    id: 24,
  },
  {
    name: "Başmakçı",
    cityName: "Afyonkarahisar",
    value: "Başmakçı",
    id: 25,
  },
  {
    name: "Bayat",
    cityName: "Afyonkarahisar",
    value: "Bayat",
    id: 26,
  },
  {
    name: "Bolvadi̇n",
    cityName: "Afyonkarahisar",
    value: "Bolvadi̇n",
    id: 27,
  },
  {
    name: "Çay",
    cityName: "Afyonkarahisar",
    value: "Çay",
    id: 28,
  },
  {
    name: "Çobanlar",
    cityName: "Afyonkarahisar",
    value: "Çobanlar",
    id: 29,
  },
  {
    name: "Dazkırı",
    cityName: "Afyonkarahisar",
    value: "Dazkırı",
    id: 30,
  },
  {
    name: "Di̇nar",
    cityName: "Afyonkarahisar",
    value: "Di̇nar",
    id: 31,
  },
  {
    name: "Emi̇rdağ",
    cityName: "Afyonkarahisar",
    value: "Emi̇rdağ",
    id: 32,
  },
  {
    name: "Evci̇ler",
    cityName: "Afyonkarahisar",
    value: "Evci̇ler",
    id: 33,
  },
  {
    name: "Hocalar",
    cityName: "Afyonkarahisar",
    value: "Hocalar",
    id: 34,
  },
  {
    name: "İhsani̇ye",
    cityName: "Afyonkarahisar",
    value: "İhsani̇ye",
    id: 35,
  },
  {
    name: "İscehi̇sar",
    cityName: "Afyonkarahisar",
    value: "İscehi̇sar",
    id: 36,
  },
  {
    name: "Kızılören",
    cityName: "Afyonkarahisar",
    value: "Kızılören",
    id: 37,
  },
  {
    name: "Merkez",
    cityName: "Afyonkarahisar",
    value: "Merkez",
    id: 38,
  },
  {
    name: "Sandıklı",
    cityName: "Afyonkarahisar",
    value: "Sandıklı",
    id: 39,
  },
  {
    name: "Si̇nanpaşa",
    cityName: "Afyonkarahisar",
    value: "Si̇nanpaşa",
    id: 40,
  },
  {
    name: "Sultandağı",
    cityName: "Afyonkarahisar",
    value: "Sultandağı",
    id: 41,
  },
  {
    name: "Şuhut",
    cityName: "Afyonkarahisar",
    value: "Şuhut",
    id: 42,
  },
  {
    name: "Di̇yadi̇n",
    cityName: "Ağrı",
    value: "Di̇yadi̇n",
    id: 43,
  },
  {
    name: "Doğubayazit",
    cityName: "Ağrı",
    value: "Doğubayazit",
    id: 44,
  },
  {
    name: "Eleşki̇rt",
    cityName: "Ağrı",
    value: "Eleşki̇rt",
    id: 45,
  },
  {
    name: "Hamur",
    cityName: "Ağrı",
    value: "Hamur",
    id: 46,
  },
  {
    name: "Merkez",
    cityName: "Ağrı",
    value: "Merkez",
    id: 47,
  },
  {
    name: "Patnos",
    cityName: "Ağrı",
    value: "Patnos",
    id: 48,
  },
  {
    name: "Taşlıçay",
    cityName: "Ağrı",
    value: "Taşlıçay",
    id: 49,
  },
  {
    name: "Tutak",
    cityName: "Ağrı",
    value: "Tutak",
    id: 50,
  },
  {
    name: "Ağaçören",
    cityName: "Aksaray",
    value: "Ağaçören",
    id: 51,
  },
  {
    name: "Eski̇l",
    cityName: "Aksaray",
    value: "Eski̇l",
    id: 52,
  },
  {
    name: "Gülağaç",
    cityName: "Aksaray",
    value: "Gülağaç",
    id: 53,
  },
  {
    name: "Güzelyurt",
    cityName: "Aksaray",
    value: "Güzelyurt",
    id: 54,
  },
  {
    name: "Merkez",
    cityName: "Aksaray",
    value: "Merkez",
    id: 55,
  },
  {
    name: "Ortaköy",
    cityName: "Aksaray",
    value: "Ortaköy",
    id: 56,
  },
  {
    name: "Sariyahşi̇",
    cityName: "Aksaray",
    value: "Sariyahşi̇",
    id: 57,
  },
  {
    name: "Sultanhanı",
    cityName: "Aksaray",
    value: "Sultanhanı",
    id: 58,
  },
  {
    name: "Göynücek",
    cityName: "Amasya",
    value: "Göynücek",
    id: 59,
  },
  {
    name: "Gümüşhacıköy",
    cityName: "Amasya",
    value: "Gümüşhacıköy",
    id: 60,
  },
  {
    name: "Hamamözü",
    cityName: "Amasya",
    value: "Hamamözü",
    id: 61,
  },
  {
    name: "Merkez",
    cityName: "Amasya",
    value: "Merkez",
    id: 62,
  },
  {
    name: "Merzi̇fon",
    cityName: "Amasya",
    value: "Merzi̇fon",
    id: 63,
  },
  {
    name: "Suluova",
    cityName: "Amasya",
    value: "Suluova",
    id: 64,
  },
  {
    name: "Taşova",
    cityName: "Amasya",
    value: "Taşova",
    id: 65,
  },
  {
    name: "Akyurt",
    cityName: "Ankara",
    value: "Akyurt",
    id: 66,
  },
  {
    name: "Altındağ",
    cityName: "Ankara",
    value: "Altındağ",
    id: 67,
  },
  {
    name: "Ayaş",
    cityName: "Ankara",
    value: "Ayaş",
    id: 68,
  },
  {
    name: "Bala",
    cityName: "Ankara",
    value: "Bala",
    id: 69,
  },
  {
    name: "Beypazarı",
    cityName: "Ankara",
    value: "Beypazarı",
    id: 70,
  },
  {
    name: "Çamlıdere",
    cityName: "Ankara",
    value: "Çamlıdere",
    id: 71,
  },
  {
    name: "Çankaya",
    cityName: "Ankara",
    value: "Çankaya",
    id: 72,
  },
  {
    name: "Çubuk",
    cityName: "Ankara",
    value: "Çubuk",
    id: 73,
  },
  {
    name: "Elmadağ",
    cityName: "Ankara",
    value: "Elmadağ",
    id: 74,
  },
  {
    name: "Eti̇mesgut",
    cityName: "Ankara",
    value: "Eti̇mesgut",
    id: 75,
  },
  {
    name: "Evren",
    cityName: "Ankara",
    value: "Evren",
    id: 76,
  },
  {
    name: "Gölbaşı",
    cityName: "Ankara",
    value: "Gölbaşı",
    id: 77,
  },
  {
    name: "Güdül",
    cityName: "Ankara",
    value: "Güdül",
    id: 78,
  },
  {
    name: "Haymana",
    cityName: "Ankara",
    value: "Haymana",
    id: 79,
  },
  {
    name: "Kahramankazan",
    cityName: "Ankara",
    value: "Kahramankazan",
    id: 80,
  },
  {
    name: "Kaleci̇k",
    cityName: "Ankara",
    value: "Kaleci̇k",
    id: 81,
  },
  {
    name: "Keçi̇ören",
    cityName: "Ankara",
    value: "Keçi̇ören",
    id: 82,
  },
  {
    name: "Kızılcahamam",
    cityName: "Ankara",
    value: "Kızılcahamam",
    id: 83,
  },
  {
    name: "Mamak",
    cityName: "Ankara",
    value: "Mamak",
    id: 84,
  },
  {
    name: "Nallıhan",
    cityName: "Ankara",
    value: "Nallıhan",
    id: 85,
  },
  {
    name: "Polatlı",
    cityName: "Ankara",
    value: "Polatlı",
    id: 86,
  },
  {
    name: "Pursaklar",
    cityName: "Ankara",
    value: "Pursaklar",
    id: 87,
  },
  {
    name: "Si̇ncan",
    cityName: "Ankara",
    value: "Si̇ncan",
    id: 88,
  },
  {
    name: "Şerefli̇koçhi̇sar",
    cityName: "Ankara",
    value: "Şerefli̇koçhi̇sar",
    id: 89,
  },
  {
    name: "Yeni̇mahalle",
    cityName: "Ankara",
    value: "Yeni̇mahalle",
    id: 90,
  },
  {
    name: "Akseki̇",
    cityName: "Antalya",
    value: "Akseki̇",
    id: 91,
  },
  {
    name: "Aksu",
    cityName: "Antalya",
    value: "Aksu",
    id: 92,
  },
  {
    name: "Alanya",
    cityName: "Antalya",
    value: "Alanya",
    id: 93,
  },
  {
    name: "Demre",
    cityName: "Antalya",
    value: "Demre",
    id: 94,
  },
  {
    name: "Döşemealtı",
    cityName: "Antalya",
    value: "Döşemealtı",
    id: 95,
  },
  {
    name: "Elmalı",
    cityName: "Antalya",
    value: "Elmalı",
    id: 96,
  },
  {
    name: "Fi̇ni̇ke",
    cityName: "Antalya",
    value: "Fi̇ni̇ke",
    id: 97,
  },
  {
    name: "Gazi̇paşa",
    cityName: "Antalya",
    value: "Gazi̇paşa",
    id: 98,
  },
  {
    name: "Gündoğmuş",
    cityName: "Antalya",
    value: "Gündoğmuş",
    id: 99,
  },
  {
    name: "İbradi",
    cityName: "Antalya",
    value: "İbradi",
    id: 100,
  },
  {
    name: "Kaş",
    cityName: "Antalya",
    value: "Kaş",
    id: 101,
  },
  {
    name: "Kemer",
    cityName: "Antalya",
    value: "Kemer",
    id: 102,
  },
  {
    name: "Kepez",
    cityName: "Antalya",
    value: "Kepez",
    id: 103,
  },
  {
    name: "Konyaaltı",
    cityName: "Antalya",
    value: "Konyaaltı",
    id: 104,
  },
  {
    name: "Korkuteli̇",
    cityName: "Antalya",
    value: "Korkuteli̇",
    id: 105,
  },
  {
    name: "Kumluca",
    cityName: "Antalya",
    value: "Kumluca",
    id: 106,
  },
  {
    name: "Manavgat",
    cityName: "Antalya",
    value: "Manavgat",
    id: 107,
  },
  {
    name: "Muratpaşa",
    cityName: "Antalya",
    value: "Muratpaşa",
    id: 108,
  },
  {
    name: "Seri̇k",
    cityName: "Antalya",
    value: "Seri̇k",
    id: 109,
  },
  {
    name: "Çıldır",
    cityName: "Ardahan",
    value: "Çıldır",
    id: 110,
  },
  {
    name: "Damal",
    cityName: "Ardahan",
    value: "Damal",
    id: 111,
  },
  {
    name: "Göle",
    cityName: "Ardahan",
    value: "Göle",
    id: 112,
  },
  {
    name: "Hanak",
    cityName: "Ardahan",
    value: "Hanak",
    id: 113,
  },
  {
    name: "Merkez",
    cityName: "Ardahan",
    value: "Merkez",
    id: 114,
  },
  {
    name: "Posof",
    cityName: "Ardahan",
    value: "Posof",
    id: 115,
  },
  {
    name: "Ardanuç",
    cityName: "Artvin",
    value: "Ardanuç",
    id: 116,
  },
  {
    name: "Arhavi̇",
    cityName: "Artvin",
    value: "Arhavi̇",
    id: 117,
  },
  {
    name: "Borçka",
    cityName: "Artvin",
    value: "Borçka",
    id: 118,
  },
  {
    name: "Hopa",
    cityName: "Artvin",
    value: "Hopa",
    id: 119,
  },
  {
    name: "Kemalpaşa",
    cityName: "Artvin",
    value: "Kemalpaşa",
    id: 120,
  },
  {
    name: "Merkez",
    cityName: "Artvin",
    value: "Merkez",
    id: 121,
  },
  {
    name: "Murgul",
    cityName: "Artvin",
    value: "Murgul",
    id: 122,
  },
  {
    name: "Şavşat",
    cityName: "Artvin",
    value: "Şavşat",
    id: 123,
  },
  {
    name: "Yusufeli̇",
    cityName: "Artvin",
    value: "Yusufeli̇",
    id: 124,
  },
  {
    name: "Bozdoğan",
    cityName: "Aydın",
    value: "Bozdoğan",
    id: 125,
  },
  {
    name: "Buharkent",
    cityName: "Aydın",
    value: "Buharkent",
    id: 126,
  },
  {
    name: "Çi̇ne",
    cityName: "Aydın",
    value: "Çi̇ne",
    id: 127,
  },
  {
    name: "Di̇di̇m",
    cityName: "Aydın",
    value: "Di̇di̇m",
    id: 128,
  },
  {
    name: "Efeler",
    cityName: "Aydın",
    value: "Efeler",
    id: 129,
  },
  {
    name: "Germenci̇k",
    cityName: "Aydın",
    value: "Germenci̇k",
    id: 130,
  },
  {
    name: "İnci̇rli̇ova",
    cityName: "Aydın",
    value: "İnci̇rli̇ova",
    id: 131,
  },
  {
    name: "Karacasu",
    cityName: "Aydın",
    value: "Karacasu",
    id: 132,
  },
  {
    name: "Karpuzlu",
    cityName: "Aydın",
    value: "Karpuzlu",
    id: 133,
  },
  {
    name: "Koçarlı",
    cityName: "Aydın",
    value: "Koçarlı",
    id: 134,
  },
  {
    name: "Köşk",
    cityName: "Aydın",
    value: "Köşk",
    id: 135,
  },
  {
    name: "Kuşadası",
    cityName: "Aydın",
    value: "Kuşadası",
    id: 136,
  },
  {
    name: "Kuyucak",
    cityName: "Aydın",
    value: "Kuyucak",
    id: 137,
  },
  {
    name: "Nazi̇lli̇",
    cityName: "Aydın",
    value: "Nazi̇lli̇",
    id: 138,
  },
  {
    name: "Söke",
    cityName: "Aydın",
    value: "Söke",
    id: 139,
  },
  {
    name: "Sultanhi̇sar",
    cityName: "Aydın",
    value: "Sultanhi̇sar",
    id: 140,
  },
  {
    name: "Yeni̇pazar",
    cityName: "Aydın",
    value: "Yeni̇pazar",
    id: 141,
  },
  {
    name: "Altıeylül",
    cityName: "Balıkesir",
    value: "Altıeylül",
    id: 142,
  },
  {
    name: "Ayvalık",
    cityName: "Balıkesir",
    value: "Ayvalık",
    id: 143,
  },
  {
    name: "Balya",
    cityName: "Balıkesir",
    value: "Balya",
    id: 144,
  },
  {
    name: "Bandırma",
    cityName: "Balıkesir",
    value: "Bandırma",
    id: 145,
  },
  {
    name: "Bi̇gadi̇ç",
    cityName: "Balıkesir",
    value: "Bi̇gadi̇ç",
    id: 146,
  },
  {
    name: "Burhani̇ye",
    cityName: "Balıkesir",
    value: "Burhani̇ye",
    id: 147,
  },
  {
    name: "Dursunbey",
    cityName: "Balıkesir",
    value: "Dursunbey",
    id: 148,
  },
  {
    name: "Edremi̇t",
    cityName: "Balıkesir",
    value: "Edremi̇t",
    id: 149,
  },
  {
    name: "Erdek",
    cityName: "Balıkesir",
    value: "Erdek",
    id: 150,
  },
  {
    name: "Gömeç",
    cityName: "Balıkesir",
    value: "Gömeç",
    id: 151,
  },
  {
    name: "Gönen",
    cityName: "Balıkesir",
    value: "Gönen",
    id: 152,
  },
  {
    name: "Havran",
    cityName: "Balıkesir",
    value: "Havran",
    id: 153,
  },
  {
    name: "İvri̇ndi̇",
    cityName: "Balıkesir",
    value: "İvri̇ndi̇",
    id: 154,
  },
  {
    name: "Karesi̇",
    cityName: "Balıkesir",
    value: "Karesi̇",
    id: 155,
  },
  {
    name: "Kepsut",
    cityName: "Balıkesir",
    value: "Kepsut",
    id: 156,
  },
  {
    name: "Manyas",
    cityName: "Balıkesir",
    value: "Manyas",
    id: 157,
  },
  {
    name: "Marmara",
    cityName: "Balıkesir",
    value: "Marmara",
    id: 158,
  },
  {
    name: "Savaştepe",
    cityName: "Balıkesir",
    value: "Savaştepe",
    id: 159,
  },
  {
    name: "Sindirgi",
    cityName: "Balıkesir",
    value: "Sindirgi",
    id: 160,
  },
  {
    name: "Susurluk",
    cityName: "Balıkesir",
    value: "Susurluk",
    id: 161,
  },
  {
    name: "Amasra",
    cityName: "Bartın",
    value: "Amasra",
    id: 162,
  },
  {
    name: "Kurucaşi̇le",
    cityName: "Bartın",
    value: "Kurucaşi̇le",
    id: 163,
  },
  {
    name: "Merkez",
    cityName: "Bartın",
    value: "Merkez",
    id: 164,
  },
  {
    name: "Ulus",
    cityName: "Bartın",
    value: "Ulus",
    id: 165,
  },
  {
    name: "Beşi̇ri̇",
    cityName: "Batman",
    value: "Beşi̇ri̇",
    id: 166,
  },
  {
    name: "Gercüş",
    cityName: "Batman",
    value: "Gercüş",
    id: 167,
  },
  {
    name: "Hasankeyf",
    cityName: "Batman",
    value: "Hasankeyf",
    id: 168,
  },
  {
    name: "Kozluk",
    cityName: "Batman",
    value: "Kozluk",
    id: 169,
  },
  {
    name: "Merkez",
    cityName: "Batman",
    value: "Merkez",
    id: 170,
  },
  {
    name: "Sason",
    cityName: "Batman",
    value: "Sason",
    id: 171,
  },
  {
    name: "Aydıntepe",
    cityName: "Bayburt",
    value: "Aydıntepe",
    id: 172,
  },
  {
    name: "Demi̇rözü",
    cityName: "Bayburt",
    value: "Demi̇rözü",
    id: 173,
  },
  {
    name: "Merkez",
    cityName: "Bayburt",
    value: "Merkez",
    id: 174,
  },
  {
    name: "Bozüyük",
    cityName: "Bilecik",
    value: "Bozüyük",
    id: 175,
  },
  {
    name: "Gölpazarı",
    cityName: "Bilecik",
    value: "Gölpazarı",
    id: 176,
  },
  {
    name: "İnhi̇sar",
    cityName: "Bilecik",
    value: "İnhi̇sar",
    id: 177,
  },
  {
    name: "Merkez",
    cityName: "Bilecik",
    value: "Merkez",
    id: 178,
  },
  {
    name: "Osmaneli̇",
    cityName: "Bilecik",
    value: "Osmaneli̇",
    id: 179,
  },
  {
    name: "Pazaryeri̇",
    cityName: "Bilecik",
    value: "Pazaryeri̇",
    id: 180,
  },
  {
    name: "Söğüt",
    cityName: "Bilecik",
    value: "Söğüt",
    id: 181,
  },
  {
    name: "Yeni̇pazar",
    cityName: "Bilecik",
    value: "Yeni̇pazar",
    id: 182,
  },
  {
    name: "Adaklı",
    cityName: "Bingöl",
    value: "Adaklı",
    id: 183,
  },
  {
    name: "Genç",
    cityName: "Bingöl",
    value: "Genç",
    id: 184,
  },
  {
    name: "Karlıova",
    cityName: "Bingöl",
    value: "Karlıova",
    id: 185,
  },
  {
    name: "Ki̇ğı",
    cityName: "Bingöl",
    value: "Ki̇ğı",
    id: 186,
  },
  {
    name: "Merkez",
    cityName: "Bingöl",
    value: "Merkez",
    id: 187,
  },
  {
    name: "Solhan",
    cityName: "Bingöl",
    value: "Solhan",
    id: 188,
  },
  {
    name: "Yayladere",
    cityName: "Bingöl",
    value: "Yayladere",
    id: 189,
  },
  {
    name: "Yedi̇su",
    cityName: "Bingöl",
    value: "Yedi̇su",
    id: 190,
  },
  {
    name: "Adi̇lcevaz",
    cityName: "Bitlis",
    value: "Adi̇lcevaz",
    id: 191,
  },
  {
    name: "Ahlat",
    cityName: "Bitlis",
    value: "Ahlat",
    id: 192,
  },
  {
    name: "Güroymak",
    cityName: "Bitlis",
    value: "Güroymak",
    id: 193,
  },
  {
    name: "Hi̇zan",
    cityName: "Bitlis",
    value: "Hi̇zan",
    id: 194,
  },
  {
    name: "Merkez",
    cityName: "Bitlis",
    value: "Merkez",
    id: 195,
  },
  {
    name: "Mutki̇",
    cityName: "Bitlis",
    value: "Mutki̇",
    id: 196,
  },
  {
    name: "Tatvan",
    cityName: "Bitlis",
    value: "Tatvan",
    id: 197,
  },
  {
    name: "Dörtdi̇van",
    cityName: "Bolu",
    value: "Dörtdi̇van",
    id: 198,
  },
  {
    name: "Gerede",
    cityName: "Bolu",
    value: "Gerede",
    id: 199,
  },
  {
    name: "Göynük",
    cityName: "Bolu",
    value: "Göynük",
    id: 200,
  },
  {
    name: "Kıbrıscık",
    cityName: "Bolu",
    value: "Kıbrıscık",
    id: 201,
  },
  {
    name: "Mengen",
    cityName: "Bolu",
    value: "Mengen",
    id: 202,
  },
  {
    name: "Merkez",
    cityName: "Bolu",
    value: "Merkez",
    id: 203,
  },
  {
    name: "Mudurnu",
    cityName: "Bolu",
    value: "Mudurnu",
    id: 204,
  },
  {
    name: "Seben",
    cityName: "Bolu",
    value: "Seben",
    id: 205,
  },
  {
    name: "Yeni̇çağa",
    cityName: "Bolu",
    value: "Yeni̇çağa",
    id: 206,
  },
  {
    name: "Ağlasun",
    cityName: "Burdur",
    value: "Ağlasun",
    id: 207,
  },
  {
    name: "Altınyayla",
    cityName: "Burdur",
    value: "Altınyayla",
    id: 208,
  },
  {
    name: "Bucak",
    cityName: "Burdur",
    value: "Bucak",
    id: 209,
  },
  {
    name: "Çavdır",
    cityName: "Burdur",
    value: "Çavdır",
    id: 210,
  },
  {
    name: "Çelti̇kçi̇",
    cityName: "Burdur",
    value: "Çelti̇kçi̇",
    id: 211,
  },
  {
    name: "Gölhi̇sar",
    cityName: "Burdur",
    value: "Gölhi̇sar",
    id: 212,
  },
  {
    name: "Karamanlı",
    cityName: "Burdur",
    value: "Karamanlı",
    id: 213,
  },
  {
    name: "Kemer",
    cityName: "Burdur",
    value: "Kemer",
    id: 214,
  },
  {
    name: "Merkez",
    cityName: "Burdur",
    value: "Merkez",
    id: 215,
  },
  {
    name: "Tefenni̇",
    cityName: "Burdur",
    value: "Tefenni̇",
    id: 216,
  },
  {
    name: "Yeşi̇lova",
    cityName: "Burdur",
    value: "Yeşi̇lova",
    id: 217,
  },
  {
    name: "Büyükorhan",
    cityName: "Bursa",
    value: "Büyükorhan",
    id: 218,
  },
  {
    name: "Gemli̇k",
    cityName: "Bursa",
    value: "Gemli̇k",
    id: 219,
  },
  {
    name: "Gürsu",
    cityName: "Bursa",
    value: "Gürsu",
    id: 220,
  },
  {
    name: "Harmancık",
    cityName: "Bursa",
    value: "Harmancık",
    id: 221,
  },
  {
    name: "İnegöl",
    cityName: "Bursa",
    value: "İnegöl",
    id: 222,
  },
  {
    name: "İzni̇k",
    cityName: "Bursa",
    value: "İzni̇k",
    id: 223,
  },
  {
    name: "Karacabey",
    cityName: "Bursa",
    value: "Karacabey",
    id: 224,
  },
  {
    name: "Keles",
    cityName: "Bursa",
    value: "Keles",
    id: 225,
  },
  {
    name: "Kestel",
    cityName: "Bursa",
    value: "Kestel",
    id: 226,
  },
  {
    name: "Mudanya",
    cityName: "Bursa",
    value: "Mudanya",
    id: 227,
  },
  {
    name: "Mustafakemalpaşa",
    cityName: "Bursa",
    value: "Mustafakemalpaşa",
    id: 228,
  },
  {
    name: "Ni̇lüfer",
    cityName: "Bursa",
    value: "Ni̇lüfer",
    id: 229,
  },
  {
    name: "Orhaneli̇",
    cityName: "Bursa",
    value: "Orhaneli̇",
    id: 230,
  },
  {
    name: "Orhangazi̇",
    cityName: "Bursa",
    value: "Orhangazi̇",
    id: 231,
  },
  {
    name: "Osmangazi̇",
    cityName: "Bursa",
    value: "Osmangazi̇",
    id: 232,
  },
  {
    name: "Yeni̇şehi̇r",
    cityName: "Bursa",
    value: "Yeni̇şehi̇r",
    id: 233,
  },
  {
    name: "Yıldırım",
    cityName: "Bursa",
    value: "Yıldırım",
    id: 234,
  },
  {
    name: "Ayvacık",
    cityName: "Çanakkale",
    value: "Ayvacık",
    id: 235,
  },
  {
    name: "Bayrami̇ç",
    cityName: "Çanakkale",
    value: "Bayrami̇ç",
    id: 236,
  },
  {
    name: "Bi̇ga",
    cityName: "Çanakkale",
    value: "Bi̇ga",
    id: 237,
  },
  {
    name: "Bozcaada",
    cityName: "Çanakkale",
    value: "Bozcaada",
    id: 238,
  },
  {
    name: "Çan",
    cityName: "Çanakkale",
    value: "Çan",
    id: 239,
  },
  {
    name: "Eceabat",
    cityName: "Çanakkale",
    value: "Eceabat",
    id: 240,
  },
  {
    name: "Ezi̇ne",
    cityName: "Çanakkale",
    value: "Ezi̇ne",
    id: 241,
  },
  {
    name: "Geli̇bolu",
    cityName: "Çanakkale",
    value: "Geli̇bolu",
    id: 242,
  },
  {
    name: "Gökçeada",
    cityName: "Çanakkale",
    value: "Gökçeada",
    id: 243,
  },
  {
    name: "Lapseki̇",
    cityName: "Çanakkale",
    value: "Lapseki̇",
    id: 244,
  },
  {
    name: "Merkez",
    cityName: "Çanakkale",
    value: "Merkez",
    id: 245,
  },
  {
    name: "Yeni̇ce",
    cityName: "Çanakkale",
    value: "Yeni̇ce",
    id: 246,
  },
  {
    name: "Atkaracalar",
    cityName: "Çankırı",
    value: "Atkaracalar",
    id: 247,
  },
  {
    name: "Bayramören",
    cityName: "Çankırı",
    value: "Bayramören",
    id: 248,
  },
  {
    name: "Çerkeş",
    cityName: "Çankırı",
    value: "Çerkeş",
    id: 249,
  },
  {
    name: "Eldi̇van",
    cityName: "Çankırı",
    value: "Eldi̇van",
    id: 250,
  },
  {
    name: "Ilgaz",
    cityName: "Çankırı",
    value: "Ilgaz",
    id: 251,
  },
  {
    name: "Kızılırmak",
    cityName: "Çankırı",
    value: "Kızılırmak",
    id: 252,
  },
  {
    name: "Korgun",
    cityName: "Çankırı",
    value: "Korgun",
    id: 253,
  },
  {
    name: "Kurşunlu",
    cityName: "Çankırı",
    value: "Kurşunlu",
    id: 254,
  },
  {
    name: "Merkez",
    cityName: "Çankırı",
    value: "Merkez",
    id: 255,
  },
  {
    name: "Orta",
    cityName: "Çankırı",
    value: "Orta",
    id: 256,
  },
  {
    name: "Şabanözü",
    cityName: "Çankırı",
    value: "Şabanözü",
    id: 257,
  },
  {
    name: "Yapraklı",
    cityName: "Çankırı",
    value: "Yapraklı",
    id: 258,
  },
  {
    name: "Alaca",
    cityName: "Çorum",
    value: "Alaca",
    id: 259,
  },
  {
    name: "Bayat",
    cityName: "Çorum",
    value: "Bayat",
    id: 260,
  },
  {
    name: "Boğazkale",
    cityName: "Çorum",
    value: "Boğazkale",
    id: 261,
  },
  {
    name: "Dodurga",
    cityName: "Çorum",
    value: "Dodurga",
    id: 262,
  },
  {
    name: "İski̇li̇p",
    cityName: "Çorum",
    value: "İski̇li̇p",
    id: 263,
  },
  {
    name: "Kargi",
    cityName: "Çorum",
    value: "Kargi",
    id: 264,
  },
  {
    name: "Laçi̇n",
    cityName: "Çorum",
    value: "Laçi̇n",
    id: 265,
  },
  {
    name: "Meci̇tözü",
    cityName: "Çorum",
    value: "Meci̇tözü",
    id: 266,
  },
  {
    name: "Merkez",
    cityName: "Çorum",
    value: "Merkez",
    id: 267,
  },
  {
    name: "Oğuzlar",
    cityName: "Çorum",
    value: "Oğuzlar",
    id: 268,
  },
  {
    name: "Ortaköy",
    cityName: "Çorum",
    value: "Ortaköy",
    id: 269,
  },
  {
    name: "Osmancık",
    cityName: "Çorum",
    value: "Osmancık",
    id: 270,
  },
  {
    name: "Sungurlu",
    cityName: "Çorum",
    value: "Sungurlu",
    id: 271,
  },
  {
    name: "Uğurludağ",
    cityName: "Çorum",
    value: "Uğurludağ",
    id: 272,
  },
  {
    name: "Acıpayam",
    cityName: "Denizli",
    value: "Acıpayam",
    id: 273,
  },
  {
    name: "Babadağ",
    cityName: "Denizli",
    value: "Babadağ",
    id: 274,
  },
  {
    name: "Baklan",
    cityName: "Denizli",
    value: "Baklan",
    id: 275,
  },
  {
    name: "Beki̇lli̇",
    cityName: "Denizli",
    value: "Beki̇lli̇",
    id: 276,
  },
  {
    name: "Beyağaç",
    cityName: "Denizli",
    value: "Beyağaç",
    id: 277,
  },
  {
    name: "Bozkurt",
    cityName: "Denizli",
    value: "Bozkurt",
    id: 278,
  },
  {
    name: "Buldan",
    cityName: "Denizli",
    value: "Buldan",
    id: 279,
  },
  {
    name: "Çal",
    cityName: "Denizli",
    value: "Çal",
    id: 280,
  },
  {
    name: "Çameli̇",
    cityName: "Denizli",
    value: "Çameli̇",
    id: 281,
  },
  {
    name: "Çardak",
    cityName: "Denizli",
    value: "Çardak",
    id: 282,
  },
  {
    name: "Çi̇vri̇l",
    cityName: "Denizli",
    value: "Çi̇vri̇l",
    id: 283,
  },
  {
    name: "Güney",
    cityName: "Denizli",
    value: "Güney",
    id: 284,
  },
  {
    name: "Honaz",
    cityName: "Denizli",
    value: "Honaz",
    id: 285,
  },
  {
    name: "Kale",
    cityName: "Denizli",
    value: "Kale",
    id: 286,
  },
  {
    name: "Merkezefendi̇",
    cityName: "Denizli",
    value: "Merkezefendi̇",
    id: 287,
  },
  {
    name: "Pamukkale",
    cityName: "Denizli",
    value: "Pamukkale",
    id: 288,
  },
  {
    name: "Sarayköy",
    cityName: "Denizli",
    value: "Sarayköy",
    id: 289,
  },
  {
    name: "Seri̇nhi̇sar",
    cityName: "Denizli",
    value: "Seri̇nhi̇sar",
    id: 290,
  },
  {
    name: "Tavas",
    cityName: "Denizli",
    value: "Tavas",
    id: 291,
  },
  {
    name: "Bağlar",
    cityName: "Diyarbakır",
    value: "Bağlar",
    id: 292,
  },
  {
    name: "Bi̇smi̇l",
    cityName: "Diyarbakır",
    value: "Bi̇smi̇l",
    id: 293,
  },
  {
    name: "Çermi̇k",
    cityName: "Diyarbakır",
    value: "Çermi̇k",
    id: 294,
  },
  {
    name: "Çınar",
    cityName: "Diyarbakır",
    value: "Çınar",
    id: 295,
  },
  {
    name: "Çüngüş",
    cityName: "Diyarbakır",
    value: "Çüngüş",
    id: 296,
  },
  {
    name: "Di̇cle",
    cityName: "Diyarbakır",
    value: "Di̇cle",
    id: 297,
  },
  {
    name: "Eği̇l",
    cityName: "Diyarbakır",
    value: "Eği̇l",
    id: 298,
  },
  {
    name: "Ergani̇",
    cityName: "Diyarbakır",
    value: "Ergani̇",
    id: 299,
  },
  {
    name: "Hani̇",
    cityName: "Diyarbakır",
    value: "Hani̇",
    id: 300,
  },
  {
    name: "Hazro",
    cityName: "Diyarbakır",
    value: "Hazro",
    id: 301,
  },
  {
    name: "Kayapınar",
    cityName: "Diyarbakır",
    value: "Kayapınar",
    id: 302,
  },
  {
    name: "Kocaköy",
    cityName: "Diyarbakır",
    value: "Kocaköy",
    id: 303,
  },
  {
    name: "Kulp",
    cityName: "Diyarbakır",
    value: "Kulp",
    id: 304,
  },
  {
    name: "Li̇ce",
    cityName: "Diyarbakır",
    value: "Li̇ce",
    id: 305,
  },
  {
    name: "Si̇lvan",
    cityName: "Diyarbakır",
    value: "Si̇lvan",
    id: 306,
  },
  {
    name: "Sur",
    cityName: "Diyarbakır",
    value: "Sur",
    id: 307,
  },
  {
    name: "Yeni̇şehi̇r",
    cityName: "Diyarbakır",
    value: "Yeni̇şehi̇r",
    id: 308,
  },
  {
    name: "Akçakoca",
    cityName: "Düzce",
    value: "Akçakoca",
    id: 309,
  },
  {
    name: "Cumayeri̇",
    cityName: "Düzce",
    value: "Cumayeri̇",
    id: 310,
  },
  {
    name: "Çi̇li̇mli̇",
    cityName: "Düzce",
    value: "Çi̇li̇mli̇",
    id: 311,
  },
  {
    name: "Gölyaka",
    cityName: "Düzce",
    value: "Gölyaka",
    id: 312,
  },
  {
    name: "Gümüşova",
    cityName: "Düzce",
    value: "Gümüşova",
    id: 313,
  },
  {
    name: "Kaynaşlı",
    cityName: "Düzce",
    value: "Kaynaşlı",
    id: 314,
  },
  {
    name: "Merkez",
    cityName: "Düzce",
    value: "Merkez",
    id: 315,
  },
  {
    name: "Yığılca",
    cityName: "Düzce",
    value: "Yığılca",
    id: 316,
  },
  {
    name: "Enez",
    cityName: "Edirne",
    value: "Enez",
    id: 317,
  },
  {
    name: "Havsa",
    cityName: "Edirne",
    value: "Havsa",
    id: 318,
  },
  {
    name: "İpsala",
    cityName: "Edirne",
    value: "İpsala",
    id: 319,
  },
  {
    name: "Keşan",
    cityName: "Edirne",
    value: "Keşan",
    id: 320,
  },
  {
    name: "Lalapaşa",
    cityName: "Edirne",
    value: "Lalapaşa",
    id: 321,
  },
  {
    name: "Meri̇ç",
    cityName: "Edirne",
    value: "Meri̇ç",
    id: 322,
  },
  {
    name: "Merkez",
    cityName: "Edirne",
    value: "Merkez",
    id: 323,
  },
  {
    name: "Süloğlu",
    cityName: "Edirne",
    value: "Süloğlu",
    id: 324,
  },
  {
    name: "Uzunköprü",
    cityName: "Edirne",
    value: "Uzunköprü",
    id: 325,
  },
  {
    name: "Ağın",
    cityName: "Elazığ",
    value: "Ağın",
    id: 326,
  },
  {
    name: "Alacakaya",
    cityName: "Elazığ",
    value: "Alacakaya",
    id: 327,
  },
  {
    name: "Arıcak",
    cityName: "Elazığ",
    value: "Arıcak",
    id: 328,
  },
  {
    name: "Baski̇l",
    cityName: "Elazığ",
    value: "Baski̇l",
    id: 329,
  },
  {
    name: "Karakoçan",
    cityName: "Elazığ",
    value: "Karakoçan",
    id: 330,
  },
  {
    name: "Keban",
    cityName: "Elazığ",
    value: "Keban",
    id: 331,
  },
  {
    name: "Kovancılar",
    cityName: "Elazığ",
    value: "Kovancılar",
    id: 332,
  },
  {
    name: "Maden",
    cityName: "Elazığ",
    value: "Maden",
    id: 333,
  },
  {
    name: "Merkez",
    cityName: "Elazığ",
    value: "Merkez",
    id: 334,
  },
  {
    name: "Palu",
    cityName: "Elazığ",
    value: "Palu",
    id: 335,
  },
  {
    name: "Si̇vri̇ce",
    cityName: "Elazığ",
    value: "Si̇vri̇ce",
    id: 336,
  },
  {
    name: "Çayırlı",
    cityName: "Erzincan",
    value: "Çayırlı",
    id: 337,
  },
  {
    name: "İli̇ç",
    cityName: "Erzincan",
    value: "İli̇ç",
    id: 338,
  },
  {
    name: "Kemah",
    cityName: "Erzincan",
    value: "Kemah",
    id: 339,
  },
  {
    name: "Kemali̇ye",
    cityName: "Erzincan",
    value: "Kemali̇ye",
    id: 340,
  },
  {
    name: "Merkez",
    cityName: "Erzincan",
    value: "Merkez",
    id: 341,
  },
  {
    name: "Otlukbeli̇",
    cityName: "Erzincan",
    value: "Otlukbeli̇",
    id: 342,
  },
  {
    name: "Refahi̇ye",
    cityName: "Erzincan",
    value: "Refahi̇ye",
    id: 343,
  },
  {
    name: "Tercan",
    cityName: "Erzincan",
    value: "Tercan",
    id: 344,
  },
  {
    name: "Üzümlü",
    cityName: "Erzincan",
    value: "Üzümlü",
    id: 345,
  },
  {
    name: "Aşkale",
    cityName: "Erzurum",
    value: "Aşkale",
    id: 346,
  },
  {
    name: "Azi̇zi̇ye",
    cityName: "Erzurum",
    value: "Azi̇zi̇ye",
    id: 347,
  },
  {
    name: "Çat",
    cityName: "Erzurum",
    value: "Çat",
    id: 348,
  },
  {
    name: "Hınıs",
    cityName: "Erzurum",
    value: "Hınıs",
    id: 349,
  },
  {
    name: "Horasan",
    cityName: "Erzurum",
    value: "Horasan",
    id: 350,
  },
  {
    name: "İspi̇r",
    cityName: "Erzurum",
    value: "İspi̇r",
    id: 351,
  },
  {
    name: "Karaçoban",
    cityName: "Erzurum",
    value: "Karaçoban",
    id: 352,
  },
  {
    name: "Karayazı",
    cityName: "Erzurum",
    value: "Karayazı",
    id: 353,
  },
  {
    name: "Köprüköy",
    cityName: "Erzurum",
    value: "Köprüköy",
    id: 354,
  },
  {
    name: "Narman",
    cityName: "Erzurum",
    value: "Narman",
    id: 355,
  },
  {
    name: "Oltu",
    cityName: "Erzurum",
    value: "Oltu",
    id: 356,
  },
  {
    name: "Olur",
    cityName: "Erzurum",
    value: "Olur",
    id: 357,
  },
  {
    name: "Palandöken",
    cityName: "Erzurum",
    value: "Palandöken",
    id: 358,
  },
  {
    name: "Pasi̇nler",
    cityName: "Erzurum",
    value: "Pasi̇nler",
    id: 359,
  },
  {
    name: "Pazaryolu",
    cityName: "Erzurum",
    value: "Pazaryolu",
    id: 360,
  },
  {
    name: "Şenkaya",
    cityName: "Erzurum",
    value: "Şenkaya",
    id: 361,
  },
  {
    name: "Tekman",
    cityName: "Erzurum",
    value: "Tekman",
    id: 362,
  },
  {
    name: "Tortum",
    cityName: "Erzurum",
    value: "Tortum",
    id: 363,
  },
  {
    name: "Uzundere",
    cityName: "Erzurum",
    value: "Uzundere",
    id: 364,
  },
  {
    name: "Yakuti̇ye",
    cityName: "Erzurum",
    value: "Yakuti̇ye",
    id: 365,
  },
  {
    name: "Alpu",
    cityName: "Eskişehir",
    value: "Alpu",
    id: 366,
  },
  {
    name: "Beyli̇kova",
    cityName: "Eskişehir",
    value: "Beyli̇kova",
    id: 367,
  },
  {
    name: "Çi̇fteler",
    cityName: "Eskişehir",
    value: "Çi̇fteler",
    id: 368,
  },
  {
    name: "Günyüzü",
    cityName: "Eskişehir",
    value: "Günyüzü",
    id: 369,
  },
  {
    name: "Han",
    cityName: "Eskişehir",
    value: "Han",
    id: 370,
  },
  {
    name: "İnönü",
    cityName: "Eskişehir",
    value: "İnönü",
    id: 371,
  },
  {
    name: "Mahmudi̇ye",
    cityName: "Eskişehir",
    value: "Mahmudi̇ye",
    id: 372,
  },
  {
    name: "Mi̇halgazi̇",
    cityName: "Eskişehir",
    value: "Mi̇halgazi̇",
    id: 373,
  },
  {
    name: "Mi̇haliççik",
    cityName: "Eskişehir",
    value: "Mi̇haliççik",
    id: 374,
  },
  {
    name: "Odunpazarı",
    cityName: "Eskişehir",
    value: "Odunpazarı",
    id: 375,
  },
  {
    name: "Sarıcakaya",
    cityName: "Eskişehir",
    value: "Sarıcakaya",
    id: 376,
  },
  {
    name: "Seyi̇tgazi̇",
    cityName: "Eskişehir",
    value: "Seyi̇tgazi̇",
    id: 377,
  },
  {
    name: "Si̇vri̇hi̇sar",
    cityName: "Eskişehir",
    value: "Si̇vri̇hi̇sar",
    id: 378,
  },
  {
    name: "Tepebaşı",
    cityName: "Eskişehir",
    value: "Tepebaşı",
    id: 379,
  },
  {
    name: "Araban",
    cityName: "Gaziantep",
    value: "Araban",
    id: 380,
  },
  {
    name: "İslahi̇ye",
    cityName: "Gaziantep",
    value: "İslahi̇ye",
    id: 381,
  },
  {
    name: "Karkamış",
    cityName: "Gaziantep",
    value: "Karkamış",
    id: 382,
  },
  {
    name: "Ni̇zi̇p",
    cityName: "Gaziantep",
    value: "Ni̇zi̇p",
    id: 383,
  },
  {
    name: "Nurdağı",
    cityName: "Gaziantep",
    value: "Nurdağı",
    id: 384,
  },
  {
    name: "Oğuzeli̇",
    cityName: "Gaziantep",
    value: "Oğuzeli̇",
    id: 385,
  },
  {
    name: "Şahi̇nbey",
    cityName: "Gaziantep",
    value: "Şahi̇nbey",
    id: 386,
  },
  {
    name: "Şehi̇tkami̇l",
    cityName: "Gaziantep",
    value: "Şehi̇tkami̇l",
    id: 387,
  },
  {
    name: "Yavuzeli̇",
    cityName: "Gaziantep",
    value: "Yavuzeli̇",
    id: 388,
  },
  {
    name: "Alucra",
    cityName: "Giresun",
    value: "Alucra",
    id: 389,
  },
  {
    name: "Bulancak",
    cityName: "Giresun",
    value: "Bulancak",
    id: 390,
  },
  {
    name: "Çamoluk",
    cityName: "Giresun",
    value: "Çamoluk",
    id: 391,
  },
  {
    name: "Çanakçı",
    cityName: "Giresun",
    value: "Çanakçı",
    id: 392,
  },
  {
    name: "Dereli̇",
    cityName: "Giresun",
    value: "Dereli̇",
    id: 393,
  },
  {
    name: "Doğankent",
    cityName: "Giresun",
    value: "Doğankent",
    id: 394,
  },
  {
    name: "Espi̇ye",
    cityName: "Giresun",
    value: "Espi̇ye",
    id: 395,
  },
  {
    name: "Eynesi̇l",
    cityName: "Giresun",
    value: "Eynesi̇l",
    id: 396,
  },
  {
    name: "Görele",
    cityName: "Giresun",
    value: "Görele",
    id: 397,
  },
  {
    name: "Güce",
    cityName: "Giresun",
    value: "Güce",
    id: 398,
  },
  {
    name: "Keşap",
    cityName: "Giresun",
    value: "Keşap",
    id: 399,
  },
  {
    name: "Merkez",
    cityName: "Giresun",
    value: "Merkez",
    id: 400,
  },
  {
    name: "Pi̇razi̇z",
    cityName: "Giresun",
    value: "Pi̇razi̇z",
    id: 401,
  },
  {
    name: "Şebi̇nkarahi̇sar",
    cityName: "Giresun",
    value: "Şebi̇nkarahi̇sar",
    id: 402,
  },
  {
    name: "Ti̇rebolu",
    cityName: "Giresun",
    value: "Ti̇rebolu",
    id: 403,
  },
  {
    name: "Yağlıdere",
    cityName: "Giresun",
    value: "Yağlıdere",
    id: 404,
  },
  {
    name: "Kelki̇t",
    cityName: "Gümüşhane",
    value: "Kelki̇t",
    id: 405,
  },
  {
    name: "Köse",
    cityName: "Gümüşhane",
    value: "Köse",
    id: 406,
  },
  {
    name: "Kürtün",
    cityName: "Gümüşhane",
    value: "Kürtün",
    id: 407,
  },
  {
    name: "Merkez",
    cityName: "Gümüşhane",
    value: "Merkez",
    id: 408,
  },
  {
    name: "Şi̇ran",
    cityName: "Gümüşhane",
    value: "Şi̇ran",
    id: 409,
  },
  {
    name: "Torul",
    cityName: "Gümüşhane",
    value: "Torul",
    id: 410,
  },
  {
    name: "Çukurca",
    cityName: "Hakkari̇",
    value: "Çukurca",
    id: 411,
  },
  {
    name: "Dereci̇k",
    cityName: "Hakkari̇",
    value: "Dereci̇k",
    id: 412,
  },
  {
    name: "Merkez",
    cityName: "Hakkari̇",
    value: "Merkez",
    id: 413,
  },
  {
    name: "Şemdi̇nli̇",
    cityName: "Hakkari̇",
    value: "Şemdi̇nli̇",
    id: 414,
  },
  {
    name: "Yüksekova",
    cityName: "Hakkari̇",
    value: "Yüksekova",
    id: 415,
  },
  {
    name: "Altınözü",
    cityName: "Hatay",
    value: "Altınözü",
    id: 416,
  },
  {
    name: "Antakya",
    cityName: "Hatay",
    value: "Antakya",
    id: 417,
  },
  {
    name: "Arsuz",
    cityName: "Hatay",
    value: "Arsuz",
    id: 418,
  },
  {
    name: "Belen",
    cityName: "Hatay",
    value: "Belen",
    id: 419,
  },
  {
    name: "Defne",
    cityName: "Hatay",
    value: "Defne",
    id: 420,
  },
  {
    name: "Dörtyol",
    cityName: "Hatay",
    value: "Dörtyol",
    id: 421,
  },
  {
    name: "Erzi̇n",
    cityName: "Hatay",
    value: "Erzi̇n",
    id: 422,
  },
  {
    name: "Hassa",
    cityName: "Hatay",
    value: "Hassa",
    id: 423,
  },
  {
    name: "İskenderun",
    cityName: "Hatay",
    value: "İskenderun",
    id: 424,
  },
  {
    name: "Kırıkhan",
    cityName: "Hatay",
    value: "Kırıkhan",
    id: 425,
  },
  {
    name: "Kumlu",
    cityName: "Hatay",
    value: "Kumlu",
    id: 426,
  },
  {
    name: "Payas",
    cityName: "Hatay",
    value: "Payas",
    id: 427,
  },
  {
    name: "Reyhanlı",
    cityName: "Hatay",
    value: "Reyhanlı",
    id: 428,
  },
  {
    name: "Samandağ",
    cityName: "Hatay",
    value: "Samandağ",
    id: 429,
  },
  {
    name: "Yayladağı",
    cityName: "Hatay",
    value: "Yayladağı",
    id: 430,
  },
  {
    name: "Aralık",
    cityName: "Iğdır",
    value: "Aralık",
    id: 431,
  },
  {
    name: "Karakoyunlu",
    cityName: "Iğdır",
    value: "Karakoyunlu",
    id: 432,
  },
  {
    name: "Merkez",
    cityName: "Iğdır",
    value: "Merkez",
    id: 433,
  },
  {
    name: "Tuzluca",
    cityName: "Iğdır",
    value: "Tuzluca",
    id: 434,
  },
  {
    name: "Aksu",
    cityName: "Isparta",
    value: "Aksu",
    id: 435,
  },
  {
    name: "Atabey",
    cityName: "Isparta",
    value: "Atabey",
    id: 436,
  },
  {
    name: "Eği̇rdi̇r",
    cityName: "Isparta",
    value: "Eği̇rdi̇r",
    id: 437,
  },
  {
    name: "Gelendost",
    cityName: "Isparta",
    value: "Gelendost",
    id: 438,
  },
  {
    name: "Gönen",
    cityName: "Isparta",
    value: "Gönen",
    id: 439,
  },
  {
    name: "Keçi̇borlu",
    cityName: "Isparta",
    value: "Keçi̇borlu",
    id: 440,
  },
  {
    name: "Merkez",
    cityName: "Isparta",
    value: "Merkez",
    id: 441,
  },
  {
    name: "Seni̇rkent",
    cityName: "Isparta",
    value: "Seni̇rkent",
    id: 442,
  },
  {
    name: "Sütçüler",
    cityName: "Isparta",
    value: "Sütçüler",
    id: 443,
  },
  {
    name: "Şarki̇karaağaç",
    cityName: "Isparta",
    value: "Şarki̇karaağaç",
    id: 444,
  },
  {
    name: "Uluborlu",
    cityName: "Isparta",
    value: "Uluborlu",
    id: 445,
  },
  {
    name: "Yalvaç",
    cityName: "Isparta",
    value: "Yalvaç",
    id: 446,
  },
  {
    name: "Yeni̇şarbademli̇",
    cityName: "Isparta",
    value: "Yeni̇şarbademli̇",
    id: 447,
  },
  {
    name: "Adalar",
    cityName: "İstanbul",
    value: "Adalar",
    id: 448,
  },
  {
    name: "Arnavutköy",
    cityName: "İstanbul",
    value: "Arnavutköy",
    id: 449,
  },
  {
    name: "Ataşehi̇r",
    cityName: "İstanbul",
    value: "Ataşehi̇r",
    id: 450,
  },
  {
    name: "Avcılar",
    cityName: "İstanbul",
    value: "Avcılar",
    id: 451,
  },
  {
    name: "Bağcılar",
    cityName: "İstanbul",
    value: "Bağcılar",
    id: 452,
  },
  {
    name: "Bahçeli̇evler",
    cityName: "İstanbul",
    value: "Bahçeli̇evler",
    id: 453,
  },
  {
    name: "Bakırköy",
    cityName: "İstanbul",
    value: "Bakırköy",
    id: 454,
  },
  {
    name: "Başakşehi̇r",
    cityName: "İstanbul",
    value: "Başakşehi̇r",
    id: 455,
  },
  {
    name: "Bayrampaşa",
    cityName: "İstanbul",
    value: "Bayrampaşa",
    id: 456,
  },
  {
    name: "Beşi̇ktaş",
    cityName: "İstanbul",
    value: "Beşi̇ktaş",
    id: 457,
  },
  {
    name: "Beykoz",
    cityName: "İstanbul",
    value: "Beykoz",
    id: 458,
  },
  {
    name: "Beyli̇kdüzü",
    cityName: "İstanbul",
    value: "Beyli̇kdüzü",
    id: 459,
  },
  {
    name: "Beyoğlu",
    cityName: "İstanbul",
    value: "Beyoğlu",
    id: 460,
  },
  {
    name: "Büyükçekmece",
    cityName: "İstanbul",
    value: "Büyükçekmece",
    id: 461,
  },
  {
    name: "Çatalca",
    cityName: "İstanbul",
    value: "Çatalca",
    id: 462,
  },
  {
    name: "Çekmeköy",
    cityName: "İstanbul",
    value: "Çekmeköy",
    id: 463,
  },
  {
    name: "Esenler",
    cityName: "İstanbul",
    value: "Esenler",
    id: 464,
  },
  {
    name: "Esenyurt",
    cityName: "İstanbul",
    value: "Esenyurt",
    id: 465,
  },
  {
    name: "Eyüpsultan",
    cityName: "İstanbul",
    value: "Eyüpsultan",
    id: 466,
  },
  {
    name: "Fati̇h",
    cityName: "İstanbul",
    value: "Fati̇h",
    id: 467,
  },
  {
    name: "Gazi̇osmanpaşa",
    cityName: "İstanbul",
    value: "Gazi̇osmanpaşa",
    id: 468,
  },
  {
    name: "Güngören",
    cityName: "İstanbul",
    value: "Güngören",
    id: 469,
  },
  {
    name: "Kadıköy",
    cityName: "İstanbul",
    value: "Kadıköy",
    id: 470,
  },
  {
    name: "Kağıthane",
    cityName: "İstanbul",
    value: "Kağıthane",
    id: 471,
  },
  {
    name: "Kartal",
    cityName: "İstanbul",
    value: "Kartal",
    id: 472,
  },
  {
    name: "Küçükçekmece",
    cityName: "İstanbul",
    value: "Küçükçekmece",
    id: 473,
  },
  {
    name: "Maltepe",
    cityName: "İstanbul",
    value: "Maltepe",
    id: 474,
  },
  {
    name: "Pendi̇k",
    cityName: "İstanbul",
    value: "Pendi̇k",
    id: 475,
  },
  {
    name: "Sancaktepe",
    cityName: "İstanbul",
    value: "Sancaktepe",
    id: 476,
  },
  {
    name: "Sarıyer",
    cityName: "İstanbul",
    value: "Sarıyer",
    id: 477,
  },
  {
    name: "Si̇li̇vri̇",
    cityName: "İstanbul",
    value: "Si̇li̇vri̇",
    id: 478,
  },
  {
    name: "Sultanbeyli̇",
    cityName: "İstanbul",
    value: "Sultanbeyli̇",
    id: 479,
  },
  {
    name: "Sultangazi̇",
    cityName: "İstanbul",
    value: "Sultangazi̇",
    id: 480,
  },
  {
    name: "Şi̇le",
    cityName: "İstanbul",
    value: "Şi̇le",
    id: 481,
  },
  {
    name: "Şi̇şli̇",
    cityName: "İstanbul",
    value: "Şi̇şli̇",
    id: 482,
  },
  {
    name: "Tuzla",
    cityName: "İstanbul",
    value: "Tuzla",
    id: 483,
  },
  {
    name: "Ümrani̇ye",
    cityName: "İstanbul",
    value: "Ümrani̇ye",
    id: 484,
  },
  {
    name: "Üsküdar",
    cityName: "İstanbul",
    value: "Üsküdar",
    id: 485,
  },
  {
    name: "Zeyti̇nburnu",
    cityName: "İstanbul",
    value: "Zeyti̇nburnu",
    id: 486,
  },
  {
    name: "Ali̇ağa",
    cityName: "İzmir",
    value: "Ali̇ağa",
    id: 487,
  },
  {
    name: "Balçova",
    cityName: "İzmir",
    value: "Balçova",
    id: 488,
  },
  {
    name: "Bayındır",
    cityName: "İzmir",
    value: "Bayındır",
    id: 489,
  },
  {
    name: "Bayraklı",
    cityName: "İzmir",
    value: "Bayraklı",
    id: 490,
  },
  {
    name: "Bergama",
    cityName: "İzmir",
    value: "Bergama",
    id: 491,
  },
  {
    name: "Beydağ",
    cityName: "İzmir",
    value: "Beydağ",
    id: 492,
  },
  {
    name: "Bornova",
    cityName: "İzmir",
    value: "Bornova",
    id: 493,
  },
  {
    name: "Buca",
    cityName: "İzmir",
    value: "Buca",
    id: 494,
  },
  {
    name: "Çeşme",
    cityName: "İzmir",
    value: "Çeşme",
    id: 495,
  },
  {
    name: "Çi̇ğli̇",
    cityName: "İzmir",
    value: "Çi̇ğli̇",
    id: 496,
  },
  {
    name: "Di̇ki̇li̇",
    cityName: "İzmir",
    value: "Di̇ki̇li̇",
    id: 497,
  },
  {
    name: "Foça",
    cityName: "İzmir",
    value: "Foça",
    id: 498,
  },
  {
    name: "Gazi̇emi̇r",
    cityName: "İzmir",
    value: "Gazi̇emi̇r",
    id: 499,
  },
  {
    name: "Güzelbahçe",
    cityName: "İzmir",
    value: "Güzelbahçe",
    id: 500,
  },
  {
    name: "Karabağlar",
    cityName: "İzmir",
    value: "Karabağlar",
    id: 501,
  },
  {
    name: "Karaburun",
    cityName: "İzmir",
    value: "Karaburun",
    id: 502,
  },
  {
    name: "Karşıyaka",
    cityName: "İzmir",
    value: "Karşıyaka",
    id: 503,
  },
  {
    name: "Kemalpaşa",
    cityName: "İzmir",
    value: "Kemalpaşa",
    id: 504,
  },
  {
    name: "Kınık",
    cityName: "İzmir",
    value: "Kınık",
    id: 505,
  },
  {
    name: "Ki̇raz",
    cityName: "İzmir",
    value: "Ki̇raz",
    id: 506,
  },
  {
    name: "Konak",
    cityName: "İzmir",
    value: "Konak",
    id: 507,
  },
  {
    name: "Menderes",
    cityName: "İzmir",
    value: "Menderes",
    id: 508,
  },
  {
    name: "Menemen",
    cityName: "İzmir",
    value: "Menemen",
    id: 509,
  },
  {
    name: "Narlıdere",
    cityName: "İzmir",
    value: "Narlıdere",
    id: 510,
  },
  {
    name: "Ödemi̇ş",
    cityName: "İzmir",
    value: "Ödemi̇ş",
    id: 511,
  },
  {
    name: "Seferi̇hi̇sar",
    cityName: "İzmir",
    value: "Seferi̇hi̇sar",
    id: 512,
  },
  {
    name: "Selçuk",
    cityName: "İzmir",
    value: "Selçuk",
    id: 513,
  },
  {
    name: "Ti̇re",
    cityName: "İzmir",
    value: "Ti̇re",
    id: 514,
  },
  {
    name: "Torbalı",
    cityName: "İzmir",
    value: "Torbalı",
    id: 515,
  },
  {
    name: "Urla",
    cityName: "İzmir",
    value: "Urla",
    id: 516,
  },
  {
    name: "Afşi̇n",
    cityName: "Kahramanmaraş",
    value: "Afşi̇n",
    id: 517,
  },
  {
    name: "Andırın",
    cityName: "Kahramanmaraş",
    value: "Andırın",
    id: 518,
  },
  {
    name: "Çağlayanceri̇t",
    cityName: "Kahramanmaraş",
    value: "Çağlayanceri̇t",
    id: 519,
  },
  {
    name: "Dulkadi̇roğlu",
    cityName: "Kahramanmaraş",
    value: "Dulkadi̇roğlu",
    id: 520,
  },
  {
    name: "Eki̇nözü",
    cityName: "Kahramanmaraş",
    value: "Eki̇nözü",
    id: 521,
  },
  {
    name: "Elbi̇stan",
    cityName: "Kahramanmaraş",
    value: "Elbi̇stan",
    id: 522,
  },
  {
    name: "Göksun",
    cityName: "Kahramanmaraş",
    value: "Göksun",
    id: 523,
  },
  {
    name: "Nurhak",
    cityName: "Kahramanmaraş",
    value: "Nurhak",
    id: 524,
  },
  {
    name: "Oni̇ki̇şubat",
    cityName: "Kahramanmaraş",
    value: "Oni̇ki̇şubat",
    id: 525,
  },
  {
    name: "Pazarcık",
    cityName: "Kahramanmaraş",
    value: "Pazarcık",
    id: 526,
  },
  {
    name: "Türkoğlu",
    cityName: "Kahramanmaraş",
    value: "Türkoğlu",
    id: 527,
  },
  {
    name: "Eflani̇",
    cityName: "Karabük",
    value: "Eflani̇",
    id: 528,
  },
  {
    name: "Eski̇pazar",
    cityName: "Karabük",
    value: "Eski̇pazar",
    id: 529,
  },
  {
    name: "Merkez",
    cityName: "Karabük",
    value: "Merkez",
    id: 530,
  },
  {
    name: "Ovacık",
    cityName: "Karabük",
    value: "Ovacık",
    id: 531,
  },
  {
    name: "Safranbolu",
    cityName: "Karabük",
    value: "Safranbolu",
    id: 532,
  },
  {
    name: "Yeni̇ce",
    cityName: "Karabük",
    value: "Yeni̇ce",
    id: 533,
  },
  {
    name: "Ayrancı",
    cityName: "Karaman",
    value: "Ayrancı",
    id: 534,
  },
  {
    name: "Başyayla",
    cityName: "Karaman",
    value: "Başyayla",
    id: 535,
  },
  {
    name: "Ermenek",
    cityName: "Karaman",
    value: "Ermenek",
    id: 536,
  },
  {
    name: "Kazımkarabeki̇r",
    cityName: "Karaman",
    value: "Kazımkarabeki̇r",
    id: 537,
  },
  {
    name: "Merkez",
    cityName: "Karaman",
    value: "Merkez",
    id: 538,
  },
  {
    name: "Sarıveli̇ler",
    cityName: "Karaman",
    value: "Sarıveli̇ler",
    id: 539,
  },
  {
    name: "Akyaka",
    cityName: "Kars",
    value: "Akyaka",
    id: 540,
  },
  {
    name: "Arpaçay",
    cityName: "Kars",
    value: "Arpaçay",
    id: 541,
  },
  {
    name: "Di̇gor",
    cityName: "Kars",
    value: "Di̇gor",
    id: 542,
  },
  {
    name: "Kağızman",
    cityName: "Kars",
    value: "Kağızman",
    id: 543,
  },
  {
    name: "Merkez",
    cityName: "Kars",
    value: "Merkez",
    id: 544,
  },
  {
    name: "Sarıkamış",
    cityName: "Kars",
    value: "Sarıkamış",
    id: 545,
  },
  {
    name: "Seli̇m",
    cityName: "Kars",
    value: "Seli̇m",
    id: 546,
  },
  {
    name: "Susuz",
    cityName: "Kars",
    value: "Susuz",
    id: 547,
  },
  {
    name: "Abana",
    cityName: "Kastamonu",
    value: "Abana",
    id: 548,
  },
  {
    name: "Ağlı",
    cityName: "Kastamonu",
    value: "Ağlı",
    id: 549,
  },
  {
    name: "Araç",
    cityName: "Kastamonu",
    value: "Araç",
    id: 550,
  },
  {
    name: "Azdavay",
    cityName: "Kastamonu",
    value: "Azdavay",
    id: 551,
  },
  {
    name: "Bozkurt",
    cityName: "Kastamonu",
    value: "Bozkurt",
    id: 552,
  },
  {
    name: "Ci̇de",
    cityName: "Kastamonu",
    value: "Ci̇de",
    id: 553,
  },
  {
    name: "Çatalzeyti̇n",
    cityName: "Kastamonu",
    value: "Çatalzeyti̇n",
    id: 554,
  },
  {
    name: "Daday",
    cityName: "Kastamonu",
    value: "Daday",
    id: 555,
  },
  {
    name: "Devrekanı",
    cityName: "Kastamonu",
    value: "Devrekanı",
    id: 556,
  },
  {
    name: "Doğanyurt",
    cityName: "Kastamonu",
    value: "Doğanyurt",
    id: 557,
  },
  {
    name: "Hanönü",
    cityName: "Kastamonu",
    value: "Hanönü",
    id: 558,
  },
  {
    name: "İhsangazi̇",
    cityName: "Kastamonu",
    value: "İhsangazi̇",
    id: 559,
  },
  {
    name: "İnebolu",
    cityName: "Kastamonu",
    value: "İnebolu",
    id: 560,
  },
  {
    name: "Küre",
    cityName: "Kastamonu",
    value: "Küre",
    id: 561,
  },
  {
    name: "Merkez",
    cityName: "Kastamonu",
    value: "Merkez",
    id: 562,
  },
  {
    name: "Pınarbaşı",
    cityName: "Kastamonu",
    value: "Pınarbaşı",
    id: 563,
  },
  {
    name: "Seydi̇ler",
    cityName: "Kastamonu",
    value: "Seydi̇ler",
    id: 564,
  },
  {
    name: "Şenpazar",
    cityName: "Kastamonu",
    value: "Şenpazar",
    id: 565,
  },
  {
    name: "Taşköprü",
    cityName: "Kastamonu",
    value: "Taşköprü",
    id: 566,
  },
  {
    name: "Tosya",
    cityName: "Kastamonu",
    value: "Tosya",
    id: 567,
  },
  {
    name: "Akkışla",
    cityName: "Kayseri̇",
    value: "Akkışla",
    id: 568,
  },
  {
    name: "Bünyan",
    cityName: "Kayseri̇",
    value: "Bünyan",
    id: 569,
  },
  {
    name: "Develi̇",
    cityName: "Kayseri̇",
    value: "Develi̇",
    id: 570,
  },
  {
    name: "Felahi̇ye",
    cityName: "Kayseri̇",
    value: "Felahi̇ye",
    id: 571,
  },
  {
    name: "Hacılar",
    cityName: "Kayseri̇",
    value: "Hacılar",
    id: 572,
  },
  {
    name: "İncesu",
    cityName: "Kayseri̇",
    value: "İncesu",
    id: 573,
  },
  {
    name: "Kocasi̇nan",
    cityName: "Kayseri̇",
    value: "Kocasi̇nan",
    id: 574,
  },
  {
    name: "Meli̇kgazi̇",
    cityName: "Kayseri̇",
    value: "Meli̇kgazi̇",
    id: 575,
  },
  {
    name: "Özvatan",
    cityName: "Kayseri̇",
    value: "Özvatan",
    id: 576,
  },
  {
    name: "Pınarbaşı",
    cityName: "Kayseri̇",
    value: "Pınarbaşı",
    id: 577,
  },
  {
    name: "Sarıoğlan",
    cityName: "Kayseri̇",
    value: "Sarıoğlan",
    id: 578,
  },
  {
    name: "Sarız",
    cityName: "Kayseri̇",
    value: "Sarız",
    id: 579,
  },
  {
    name: "Talas",
    cityName: "Kayseri̇",
    value: "Talas",
    id: 580,
  },
  {
    name: "Tomarza",
    cityName: "Kayseri̇",
    value: "Tomarza",
    id: 581,
  },
  {
    name: "Yahyalı",
    cityName: "Kayseri̇",
    value: "Yahyalı",
    id: 582,
  },
  {
    name: "Yeşi̇lhi̇sar",
    cityName: "Kayseri̇",
    value: "Yeşi̇lhi̇sar",
    id: 583,
  },
  {
    name: "Bahşili",
    cityName: "Kırıkkale",
    value: "Bahşili",
    id: 584,
  },
  {
    name: "Balışeyh",
    cityName: "Kırıkkale",
    value: "Balışeyh",
    id: 585,
  },
  {
    name: "Çelebi̇",
    cityName: "Kırıkkale",
    value: "Çelebi̇",
    id: 586,
  },
  {
    name: "Deli̇ce",
    cityName: "Kırıkkale",
    value: "Deli̇ce",
    id: 587,
  },
  {
    name: "Karakeçi̇li̇",
    cityName: "Kırıkkale",
    value: "Karakeçi̇li̇",
    id: 588,
  },
  {
    name: "Keski̇n",
    cityName: "Kırıkkale",
    value: "Keski̇n",
    id: 589,
  },
  {
    name: "Merkez",
    cityName: "Kırıkkale",
    value: "Merkez",
    id: 590,
  },
  {
    name: "Sulakyurt",
    cityName: "Kırıkkale",
    value: "Sulakyurt",
    id: 591,
  },
  {
    name: "Yahşi̇han",
    cityName: "Kırıkkale",
    value: "Yahşi̇han",
    id: 592,
  },
  {
    name: "Babaeski̇",
    cityName: "Kırklareli̇",
    value: "Babaeski̇",
    id: 593,
  },
  {
    name: "Demi̇rköy",
    cityName: "Kırklareli̇",
    value: "Demi̇rköy",
    id: 594,
  },
  {
    name: "Kofçaz",
    cityName: "Kırklareli̇",
    value: "Kofçaz",
    id: 595,
  },
  {
    name: "Lüleburgaz",
    cityName: "Kırklareli̇",
    value: "Lüleburgaz",
    id: 596,
  },
  {
    name: "Merkez",
    cityName: "Kırklareli̇",
    value: "Merkez",
    id: 597,
  },
  {
    name: "Pehli̇vanköy",
    cityName: "Kırklareli̇",
    value: "Pehli̇vanköy",
    id: 598,
  },
  {
    name: "Pınarhi̇sar",
    cityName: "Kırklareli̇",
    value: "Pınarhi̇sar",
    id: 599,
  },
  {
    name: "Vi̇ze",
    cityName: "Kırklareli̇",
    value: "Vi̇ze",
    id: 600,
  },
  {
    name: "Akçakent",
    cityName: "Kırşehir",
    value: "Akçakent",
    id: 601,
  },
  {
    name: "Akpınar",
    cityName: "Kırşehir",
    value: "Akpınar",
    id: 602,
  },
  {
    name: "Boztepe",
    cityName: "Kırşehir",
    value: "Boztepe",
    id: 603,
  },
  {
    name: "Çi̇çekdağı",
    cityName: "Kırşehir",
    value: "Çi̇çekdağı",
    id: 604,
  },
  {
    name: "Kaman",
    cityName: "Kırşehir",
    value: "Kaman",
    id: 605,
  },
  {
    name: "Merkez",
    cityName: "Kırşehir",
    value: "Merkez",
    id: 606,
  },
  {
    name: "Mucur",
    cityName: "Kırşehir",
    value: "Mucur",
    id: 607,
  },
  {
    name: "Elbeyli̇",
    cityName: "Kilis",
    value: "Elbeyli̇",
    id: 608,
  },
  {
    name: "Merkez",
    cityName: "Kilis",
    value: "Merkez",
    id: 609,
  },
  {
    name: "Musabeyli̇",
    cityName: "Kilis",
    value: "Musabeyli̇",
    id: 610,
  },
  {
    name: "Polateli̇",
    cityName: "Kilis",
    value: "Polateli̇",
    id: 611,
  },
  {
    name: "Başi̇skele",
    cityName: "Kocaeli̇",
    value: "Başi̇skele",
    id: 612,
  },
  {
    name: "Çayırova",
    cityName: "Kocaeli̇",
    value: "Çayırova",
    id: 613,
  },
  {
    name: "Darıca",
    cityName: "Kocaeli̇",
    value: "Darıca",
    id: 614,
  },
  {
    name: "Deri̇nce",
    cityName: "Kocaeli̇",
    value: "Deri̇nce",
    id: 615,
  },
  {
    name: "Di̇lovası",
    cityName: "Kocaeli̇",
    value: "Di̇lovası",
    id: 616,
  },
  {
    name: "Gebze",
    cityName: "Kocaeli̇",
    value: "Gebze",
    id: 617,
  },
  {
    name: "Gölcük",
    cityName: "Kocaeli̇",
    value: "Gölcük",
    id: 618,
  },
  {
    name: "İzmi̇t",
    cityName: "Kocaeli̇",
    value: "İzmi̇t",
    id: 619,
  },
  {
    name: "Kandıra",
    cityName: "Kocaeli̇",
    value: "Kandıra",
    id: 620,
  },
  {
    name: "Karamürsel",
    cityName: "Kocaeli̇",
    value: "Karamürsel",
    id: 621,
  },
  {
    name: "Kartepe",
    cityName: "Kocaeli̇",
    value: "Kartepe",
    id: 622,
  },
  {
    name: "Körfez",
    cityName: "Kocaeli̇",
    value: "Körfez",
    id: 623,
  },
  {
    name: "Ahırlı",
    cityName: "Konya",
    value: "Ahırlı",
    id: 624,
  },
  {
    name: "Akören",
    cityName: "Konya",
    value: "Akören",
    id: 625,
  },
  {
    name: "Akşehi̇r",
    cityName: "Konya",
    value: "Akşehi̇r",
    id: 626,
  },
  {
    name: "Altıneki̇n",
    cityName: "Konya",
    value: "Altıneki̇n",
    id: 627,
  },
  {
    name: "Beyşehi̇r",
    cityName: "Konya",
    value: "Beyşehi̇r",
    id: 628,
  },
  {
    name: "Bozkır",
    cityName: "Konya",
    value: "Bozkır",
    id: 629,
  },
  {
    name: "Ci̇hanbeyli̇",
    cityName: "Konya",
    value: "Ci̇hanbeyli̇",
    id: 630,
  },
  {
    name: "Çelti̇k",
    cityName: "Konya",
    value: "Çelti̇k",
    id: 631,
  },
  {
    name: "Çumra",
    cityName: "Konya",
    value: "Çumra",
    id: 632,
  },
  {
    name: "Derbent",
    cityName: "Konya",
    value: "Derbent",
    id: 633,
  },
  {
    name: "Derebucak",
    cityName: "Konya",
    value: "Derebucak",
    id: 634,
  },
  {
    name: "Doğanhi̇sar",
    cityName: "Konya",
    value: "Doğanhi̇sar",
    id: 635,
  },
  {
    name: "Emi̇rgazi̇",
    cityName: "Konya",
    value: "Emi̇rgazi̇",
    id: 636,
  },
  {
    name: "Ereğli̇",
    cityName: "Konya",
    value: "Ereğli̇",
    id: 637,
  },
  {
    name: "Güneysınır",
    cityName: "Konya",
    value: "Güneysınır",
    id: 638,
  },
  {
    name: "Hadım",
    cityName: "Konya",
    value: "Hadım",
    id: 639,
  },
  {
    name: "Halkapınar",
    cityName: "Konya",
    value: "Halkapınar",
    id: 640,
  },
  {
    name: "Hüyük",
    cityName: "Konya",
    value: "Hüyük",
    id: 641,
  },
  {
    name: "Ilgın",
    cityName: "Konya",
    value: "Ilgın",
    id: 642,
  },
  {
    name: "Kadınhanı",
    cityName: "Konya",
    value: "Kadınhanı",
    id: 643,
  },
  {
    name: "Karapınar",
    cityName: "Konya",
    value: "Karapınar",
    id: 644,
  },
  {
    name: "Karatay",
    cityName: "Konya",
    value: "Karatay",
    id: 645,
  },
  {
    name: "Kulu",
    cityName: "Konya",
    value: "Kulu",
    id: 646,
  },
  {
    name: "Meram",
    cityName: "Konya",
    value: "Meram",
    id: 647,
  },
  {
    name: "Sarayönü",
    cityName: "Konya",
    value: "Sarayönü",
    id: 648,
  },
  {
    name: "Selçuklu",
    cityName: "Konya",
    value: "Selçuklu",
    id: 649,
  },
  {
    name: "Seydi̇şehi̇r",
    cityName: "Konya",
    value: "Seydi̇şehi̇r",
    id: 650,
  },
  {
    name: "Taşkent",
    cityName: "Konya",
    value: "Taşkent",
    id: 651,
  },
  {
    name: "Tuzlukçu",
    cityName: "Konya",
    value: "Tuzlukçu",
    id: 652,
  },
  {
    name: "Yalıhüyük",
    cityName: "Konya",
    value: "Yalıhüyük",
    id: 653,
  },
  {
    name: "Yunak",
    cityName: "Konya",
    value: "Yunak",
    id: 654,
  },
  {
    name: "Altıntaş",
    cityName: "Kütahya",
    value: "Altıntaş",
    id: 655,
  },
  {
    name: "Aslanapa",
    cityName: "Kütahya",
    value: "Aslanapa",
    id: 656,
  },
  {
    name: "Çavdarhi̇sar",
    cityName: "Kütahya",
    value: "Çavdarhi̇sar",
    id: 657,
  },
  {
    name: "Domani̇ç",
    cityName: "Kütahya",
    value: "Domani̇ç",
    id: 658,
  },
  {
    name: "Dumlupinar",
    cityName: "Kütahya",
    value: "Dumlupinar",
    id: 659,
  },
  {
    name: "Emet",
    cityName: "Kütahya",
    value: "Emet",
    id: 660,
  },
  {
    name: "Gedi̇z",
    cityName: "Kütahya",
    value: "Gedi̇z",
    id: 661,
  },
  {
    name: "Hi̇sarcık",
    cityName: "Kütahya",
    value: "Hi̇sarcık",
    id: 662,
  },
  {
    name: "Merkez",
    cityName: "Kütahya",
    value: "Merkez",
    id: 663,
  },
  {
    name: "Pazarlar",
    cityName: "Kütahya",
    value: "Pazarlar",
    id: 664,
  },
  {
    name: "Si̇mav",
    cityName: "Kütahya",
    value: "Si̇mav",
    id: 665,
  },
  {
    name: "Şaphane",
    cityName: "Kütahya",
    value: "Şaphane",
    id: 666,
  },
  {
    name: "Tavşanlı",
    cityName: "Kütahya",
    value: "Tavşanlı",
    id: 667,
  },
  {
    name: "Akçadağ",
    cityName: "Malatya",
    value: "Akçadağ",
    id: 668,
  },
  {
    name: "Arapgi̇r",
    cityName: "Malatya",
    value: "Arapgi̇r",
    id: 669,
  },
  {
    name: "Arguvan",
    cityName: "Malatya",
    value: "Arguvan",
    id: 670,
  },
  {
    name: "Battalgazi̇",
    cityName: "Malatya",
    value: "Battalgazi̇",
    id: 671,
  },
  {
    name: "Darende",
    cityName: "Malatya",
    value: "Darende",
    id: 672,
  },
  {
    name: "Doğanşehi̇r",
    cityName: "Malatya",
    value: "Doğanşehi̇r",
    id: 673,
  },
  {
    name: "Doğanyol",
    cityName: "Malatya",
    value: "Doğanyol",
    id: 674,
  },
  {
    name: "Heki̇mhan",
    cityName: "Malatya",
    value: "Heki̇mhan",
    id: 675,
  },
  {
    name: "Kale",
    cityName: "Malatya",
    value: "Kale",
    id: 676,
  },
  {
    name: "Kuluncak",
    cityName: "Malatya",
    value: "Kuluncak",
    id: 677,
  },
  {
    name: "Pütürge",
    cityName: "Malatya",
    value: "Pütürge",
    id: 678,
  },
  {
    name: "Yazihan",
    cityName: "Malatya",
    value: "Yazihan",
    id: 679,
  },
  {
    name: "Yeşi̇lyurt",
    cityName: "Malatya",
    value: "Yeşi̇lyurt",
    id: 680,
  },
  {
    name: "Ahmetli̇",
    cityName: "Manisa",
    value: "Ahmetli̇",
    id: 681,
  },
  {
    name: "Akhi̇sar",
    cityName: "Manisa",
    value: "Akhi̇sar",
    id: 682,
  },
  {
    name: "Alaşehi̇r",
    cityName: "Manisa",
    value: "Alaşehi̇r",
    id: 683,
  },
  {
    name: "Demi̇rci̇",
    cityName: "Manisa",
    value: "Demi̇rci̇",
    id: 684,
  },
  {
    name: "Gölmarmara",
    cityName: "Manisa",
    value: "Gölmarmara",
    id: 685,
  },
  {
    name: "Gördes",
    cityName: "Manisa",
    value: "Gördes",
    id: 686,
  },
  {
    name: "Kırkağaç",
    cityName: "Manisa",
    value: "Kırkağaç",
    id: 687,
  },
  {
    name: "Köprübaşı",
    cityName: "Manisa",
    value: "Köprübaşı",
    id: 688,
  },
  {
    name: "Kula",
    cityName: "Manisa",
    value: "Kula",
    id: 689,
  },
  {
    name: "Sali̇hli̇",
    cityName: "Manisa",
    value: "Sali̇hli̇",
    id: 690,
  },
  {
    name: "Sarıgöl",
    cityName: "Manisa",
    value: "Sarıgöl",
    id: 691,
  },
  {
    name: "Saruhanlı",
    cityName: "Manisa",
    value: "Saruhanlı",
    id: 692,
  },
  {
    name: "Selendi̇",
    cityName: "Manisa",
    value: "Selendi̇",
    id: 693,
  },
  {
    name: "Soma",
    cityName: "Manisa",
    value: "Soma",
    id: 694,
  },
  {
    name: "Şehzadeler",
    cityName: "Manisa",
    value: "Şehzadeler",
    id: 695,
  },
  {
    name: "Turgutlu",
    cityName: "Manisa",
    value: "Turgutlu",
    id: 696,
  },
  {
    name: "Yunusemre",
    cityName: "Manisa",
    value: "Yunusemre",
    id: 697,
  },
  {
    name: "Artuklu",
    cityName: "Mardin",
    value: "Artuklu",
    id: 698,
  },
  {
    name: "Dargeçi̇t",
    cityName: "Mardin",
    value: "Dargeçi̇t",
    id: 699,
  },
  {
    name: "Deri̇k",
    cityName: "Mardin",
    value: "Deri̇k",
    id: 700,
  },
  {
    name: "Kızıltepe",
    cityName: "Mardin",
    value: "Kızıltepe",
    id: 701,
  },
  {
    name: "Mazidağı",
    cityName: "Mardin",
    value: "Mazidağı",
    id: 702,
  },
  {
    name: "Mi̇dyat",
    cityName: "Mardin",
    value: "Mi̇dyat",
    id: 703,
  },
  {
    name: "Nusaybi̇n",
    cityName: "Mardin",
    value: "Nusaybi̇n",
    id: 704,
  },
  {
    name: "Ömerli̇",
    cityName: "Mardin",
    value: "Ömerli̇",
    id: 705,
  },
  {
    name: "Savur",
    cityName: "Mardin",
    value: "Savur",
    id: 706,
  },
  {
    name: "Yeşi̇lli̇",
    cityName: "Mardin",
    value: "Yeşi̇lli̇",
    id: 707,
  },
  {
    name: "Akdeni̇z",
    cityName: "Mersin",
    value: "Akdeni̇z",
    id: 708,
  },
  {
    name: "Anamur",
    cityName: "Mersin",
    value: "Anamur",
    id: 709,
  },
  {
    name: "Aydıncık",
    cityName: "Mersin",
    value: "Aydıncık",
    id: 710,
  },
  {
    name: "Bozyazı",
    cityName: "Mersin",
    value: "Bozyazı",
    id: 711,
  },
  {
    name: "Çamlıyayla",
    cityName: "Mersin",
    value: "Çamlıyayla",
    id: 712,
  },
  {
    name: "Erdemli̇",
    cityName: "Mersin",
    value: "Erdemli̇",
    id: 713,
  },
  {
    name: "Gülnar",
    cityName: "Mersin",
    value: "Gülnar",
    id: 714,
  },
  {
    name: "Mezi̇tli̇",
    cityName: "Mersin",
    value: "Mezi̇tli̇",
    id: 715,
  },
  {
    name: "Mut",
    cityName: "Mersin",
    value: "Mut",
    id: 716,
  },
  {
    name: "Si̇li̇fke",
    cityName: "Mersin",
    value: "Si̇li̇fke",
    id: 717,
  },
  {
    name: "Tarsus",
    cityName: "Mersin",
    value: "Tarsus",
    id: 718,
  },
  {
    name: "Toroslar",
    cityName: "Mersin",
    value: "Toroslar",
    id: 719,
  },
  {
    name: "Yeni̇şehi̇r",
    cityName: "Mersin",
    value: "Yeni̇şehi̇r",
    id: 720,
  },
  {
    name: "Bodrum",
    cityName: "Muğla",
    value: "Bodrum",
    id: 721,
  },
  {
    name: "Dalaman",
    cityName: "Muğla",
    value: "Dalaman",
    id: 722,
  },
  {
    name: "Datça",
    cityName: "Muğla",
    value: "Datça",
    id: 723,
  },
  {
    name: "Fethi̇ye",
    cityName: "Muğla",
    value: "Fethi̇ye",
    id: 724,
  },
  {
    name: "Kavaklıdere",
    cityName: "Muğla",
    value: "Kavaklıdere",
    id: 725,
  },
  {
    name: "Köyceği̇z",
    cityName: "Muğla",
    value: "Köyceği̇z",
    id: 726,
  },
  {
    name: "Marmari̇s",
    cityName: "Muğla",
    value: "Marmari̇s",
    id: 727,
  },
  {
    name: "Menteşe",
    cityName: "Muğla",
    value: "Menteşe",
    id: 728,
  },
  {
    name: "Mi̇las",
    cityName: "Muğla",
    value: "Mi̇las",
    id: 729,
  },
  {
    name: "Ortaca",
    cityName: "Muğla",
    value: "Ortaca",
    id: 730,
  },
  {
    name: "Seydi̇kemer",
    cityName: "Muğla",
    value: "Seydi̇kemer",
    id: 731,
  },
  {
    name: "Ula",
    cityName: "Muğla",
    value: "Ula",
    id: 732,
  },
  {
    name: "Yatağan",
    cityName: "Muğla",
    value: "Yatağan",
    id: 733,
  },
  {
    name: "Bulanık",
    cityName: "Muş",
    value: "Bulanık",
    id: 734,
  },
  {
    name: "Hasköy",
    cityName: "Muş",
    value: "Hasköy",
    id: 735,
  },
  {
    name: "Korkut",
    cityName: "Muş",
    value: "Korkut",
    id: 736,
  },
  {
    name: "Malazgi̇rt",
    cityName: "Muş",
    value: "Malazgi̇rt",
    id: 737,
  },
  {
    name: "Merkez",
    cityName: "Muş",
    value: "Merkez",
    id: 738,
  },
  {
    name: "Varto",
    cityName: "Muş",
    value: "Varto",
    id: 739,
  },
  {
    name: "Acıgöl",
    cityName: "Nevşehir",
    value: "Acıgöl",
    id: 740,
  },
  {
    name: "Avanos",
    cityName: "Nevşehir",
    value: "Avanos",
    id: 741,
  },
  {
    name: "Deri̇nkuyu",
    cityName: "Nevşehir",
    value: "Deri̇nkuyu",
    id: 742,
  },
  {
    name: "Gülşehi̇r",
    cityName: "Nevşehir",
    value: "Gülşehi̇r",
    id: 743,
  },
  {
    name: "Hacıbektaş",
    cityName: "Nevşehir",
    value: "Hacıbektaş",
    id: 744,
  },
  {
    name: "Kozaklı",
    cityName: "Nevşehir",
    value: "Kozaklı",
    id: 745,
  },
  {
    name: "Merkez",
    cityName: "Nevşehir",
    value: "Merkez",
    id: 746,
  },
  {
    name: "Ürgüp",
    cityName: "Nevşehir",
    value: "Ürgüp",
    id: 747,
  },
  {
    name: "Altunhi̇sar",
    cityName: "Niğde",
    value: "Altunhi̇sar",
    id: 748,
  },
  {
    name: "Bor",
    cityName: "Niğde",
    value: "Bor",
    id: 749,
  },
  {
    name: "Çamardı",
    cityName: "Niğde",
    value: "Çamardı",
    id: 750,
  },
  {
    name: "Çi̇ftli̇k",
    cityName: "Niğde",
    value: "Çi̇ftli̇k",
    id: 751,
  },
  {
    name: "Merkez",
    cityName: "Niğde",
    value: "Merkez",
    id: 752,
  },
  {
    name: "Ulukışla",
    cityName: "Niğde",
    value: "Ulukışla",
    id: 753,
  },
  {
    name: "Akkuş",
    cityName: "Ordu",
    value: "Akkuş",
    id: 754,
  },
  {
    name: "Altınordu",
    cityName: "Ordu",
    value: "Altınordu",
    id: 755,
  },
  {
    name: "Aybastı",
    cityName: "Ordu",
    value: "Aybastı",
    id: 756,
  },
  {
    name: "Çamaş",
    cityName: "Ordu",
    value: "Çamaş",
    id: 757,
  },
  {
    name: "Çatalpınar",
    cityName: "Ordu",
    value: "Çatalpınar",
    id: 758,
  },
  {
    name: "Çaybaşı",
    cityName: "Ordu",
    value: "Çaybaşı",
    id: 759,
  },
  {
    name: "Fatsa",
    cityName: "Ordu",
    value: "Fatsa",
    id: 760,
  },
  {
    name: "Gölköy",
    cityName: "Ordu",
    value: "Gölköy",
    id: 761,
  },
  {
    name: "Gülyalı",
    cityName: "Ordu",
    value: "Gülyalı",
    id: 762,
  },
  {
    name: "Gürgentepe",
    cityName: "Ordu",
    value: "Gürgentepe",
    id: 763,
  },
  {
    name: "İki̇zce",
    cityName: "Ordu",
    value: "İki̇zce",
    id: 764,
  },
  {
    name: "Kabadüz",
    cityName: "Ordu",
    value: "Kabadüz",
    id: 765,
  },
  {
    name: "Kabataş",
    cityName: "Ordu",
    value: "Kabataş",
    id: 766,
  },
  {
    name: "Korgan",
    cityName: "Ordu",
    value: "Korgan",
    id: 767,
  },
  {
    name: "Kumru",
    cityName: "Ordu",
    value: "Kumru",
    id: 768,
  },
  {
    name: "Mesudi̇ye",
    cityName: "Ordu",
    value: "Mesudi̇ye",
    id: 769,
  },
  {
    name: "Perşembe",
    cityName: "Ordu",
    value: "Perşembe",
    id: 770,
  },
  {
    name: "Ulubey",
    cityName: "Ordu",
    value: "Ulubey",
    id: 771,
  },
  {
    name: "Ünye",
    cityName: "Ordu",
    value: "Ünye",
    id: 772,
  },
  {
    name: "Bahçe",
    cityName: "Osmaniye",
    value: "Bahçe",
    id: 773,
  },
  {
    name: "Düzi̇çi̇",
    cityName: "Osmaniye",
    value: "Düzi̇çi̇",
    id: 774,
  },
  {
    name: "Hasanbeyli̇",
    cityName: "Osmaniye",
    value: "Hasanbeyli̇",
    id: 775,
  },
  {
    name: "Kadi̇rli̇",
    cityName: "Osmaniye",
    value: "Kadi̇rli̇",
    id: 776,
  },
  {
    name: "Merkez",
    cityName: "Osmaniye",
    value: "Merkez",
    id: 777,
  },
  {
    name: "Sumbas",
    cityName: "Osmaniye",
    value: "Sumbas",
    id: 778,
  },
  {
    name: "Toprakkale",
    cityName: "Osmaniye",
    value: "Toprakkale",
    id: 779,
  },
  {
    name: "Ardeşen",
    cityName: "Rize",
    value: "Ardeşen",
    id: 780,
  },
  {
    name: "Çamlıhemşi̇n",
    cityName: "Rize",
    value: "Çamlıhemşi̇n",
    id: 781,
  },
  {
    name: "Çayeli̇",
    cityName: "Rize",
    value: "Çayeli̇",
    id: 782,
  },
  {
    name: "Derepazarı",
    cityName: "Rize",
    value: "Derepazarı",
    id: 783,
  },
  {
    name: "Fındıklı",
    cityName: "Rize",
    value: "Fındıklı",
    id: 784,
  },
  {
    name: "Güneysu",
    cityName: "Rize",
    value: "Güneysu",
    id: 785,
  },
  {
    name: "Hemşi̇n",
    cityName: "Rize",
    value: "Hemşi̇n",
    id: 786,
  },
  {
    name: "İki̇zdere",
    cityName: "Rize",
    value: "İki̇zdere",
    id: 787,
  },
  {
    name: "İyi̇dere",
    cityName: "Rize",
    value: "İyi̇dere",
    id: 788,
  },
  {
    name: "Kalkandere",
    cityName: "Rize",
    value: "Kalkandere",
    id: 789,
  },
  {
    name: "Merkez",
    cityName: "Rize",
    value: "Merkez",
    id: 790,
  },
  {
    name: "Pazar",
    cityName: "Rize",
    value: "Pazar",
    id: 791,
  },
  {
    name: "Adapazarı",
    cityName: "Sakarya",
    value: "Adapazarı",
    id: 792,
  },
  {
    name: "Akyazı",
    cityName: "Sakarya",
    value: "Akyazı",
    id: 793,
  },
  {
    name: "Ari̇fi̇ye",
    cityName: "Sakarya",
    value: "Ari̇fi̇ye",
    id: 794,
  },
  {
    name: "Erenler",
    cityName: "Sakarya",
    value: "Erenler",
    id: 795,
  },
  {
    name: "Feri̇zli̇",
    cityName: "Sakarya",
    value: "Feri̇zli̇",
    id: 796,
  },
  {
    name: "Geyve",
    cityName: "Sakarya",
    value: "Geyve",
    id: 797,
  },
  {
    name: "Hendek",
    cityName: "Sakarya",
    value: "Hendek",
    id: 798,
  },
  {
    name: "Karapürçek",
    cityName: "Sakarya",
    value: "Karapürçek",
    id: 799,
  },
  {
    name: "Karasu",
    cityName: "Sakarya",
    value: "Karasu",
    id: 800,
  },
  {
    name: "Kaynarca",
    cityName: "Sakarya",
    value: "Kaynarca",
    id: 801,
  },
  {
    name: "Kocaali̇",
    cityName: "Sakarya",
    value: "Kocaali̇",
    id: 802,
  },
  {
    name: "Pamukova",
    cityName: "Sakarya",
    value: "Pamukova",
    id: 803,
  },
  {
    name: "Sapanca",
    cityName: "Sakarya",
    value: "Sapanca",
    id: 804,
  },
  {
    name: "Serdi̇van",
    cityName: "Sakarya",
    value: "Serdi̇van",
    id: 805,
  },
  {
    name: "Söğütlü",
    cityName: "Sakarya",
    value: "Söğütlü",
    id: 806,
  },
  {
    name: "Taraklı",
    cityName: "Sakarya",
    value: "Taraklı",
    id: 807,
  },
  {
    name: "19 mayıs",
    cityName: "Samsun",
    value: "19 mayıs",
    id: 808,
  },
  {
    name: "Alaçam",
    cityName: "Samsun",
    value: "Alaçam",
    id: 809,
  },
  {
    name: "Asarcık",
    cityName: "Samsun",
    value: "Asarcık",
    id: 810,
  },
  {
    name: "Atakum",
    cityName: "Samsun",
    value: "Atakum",
    id: 811,
  },
  {
    name: "Ayvacık",
    cityName: "Samsun",
    value: "Ayvacık",
    id: 812,
  },
  {
    name: "Bafra",
    cityName: "Samsun",
    value: "Bafra",
    id: 813,
  },
  {
    name: "Cani̇k",
    cityName: "Samsun",
    value: "Cani̇k",
    id: 814,
  },
  {
    name: "Çarşamba",
    cityName: "Samsun",
    value: "Çarşamba",
    id: 815,
  },
  {
    name: "Havza",
    cityName: "Samsun",
    value: "Havza",
    id: 816,
  },
  {
    name: "İlkadım",
    cityName: "Samsun",
    value: "İlkadım",
    id: 817,
  },
  {
    name: "Kavak",
    cityName: "Samsun",
    value: "Kavak",
    id: 818,
  },
  {
    name: "Ladi̇k",
    cityName: "Samsun",
    value: "Ladi̇k",
    id: 819,
  },
  {
    name: "Salıpazarı",
    cityName: "Samsun",
    value: "Salıpazarı",
    id: 820,
  },
  {
    name: "Tekkeköy",
    cityName: "Samsun",
    value: "Tekkeköy",
    id: 821,
  },
  {
    name: "Terme",
    cityName: "Samsun",
    value: "Terme",
    id: 822,
  },
  {
    name: "Vezi̇rköprü",
    cityName: "Samsun",
    value: "Vezi̇rköprü",
    id: 823,
  },
  {
    name: "Yakakent",
    cityName: "Samsun",
    value: "Yakakent",
    id: 824,
  },
  {
    name: "Baykan",
    cityName: "Siirt",
    value: "Baykan",
    id: 825,
  },
  {
    name: "Eruh",
    cityName: "Siirt",
    value: "Eruh",
    id: 826,
  },
  {
    name: "Kurtalan",
    cityName: "Siirt",
    value: "Kurtalan",
    id: 827,
  },
  {
    name: "Merkez",
    cityName: "Siirt",
    value: "Merkez",
    id: 828,
  },
  {
    name: "Pervari̇",
    cityName: "Siirt",
    value: "Pervari̇",
    id: 829,
  },
  {
    name: "Şi̇rvan",
    cityName: "Siirt",
    value: "Şi̇rvan",
    id: 830,
  },
  {
    name: "Ti̇llo",
    cityName: "Siirt",
    value: "Ti̇llo",
    id: 831,
  },
  {
    name: "Ayancık",
    cityName: "Sinop",
    value: "Ayancık",
    id: 832,
  },
  {
    name: "Boyabat",
    cityName: "Sinop",
    value: "Boyabat",
    id: 833,
  },
  {
    name: "Di̇kmen",
    cityName: "Sinop",
    value: "Di̇kmen",
    id: 834,
  },
  {
    name: "Durağan",
    cityName: "Sinop",
    value: "Durağan",
    id: 835,
  },
  {
    name: "Erfelek",
    cityName: "Sinop",
    value: "Erfelek",
    id: 836,
  },
  {
    name: "Gerze",
    cityName: "Sinop",
    value: "Gerze",
    id: 837,
  },
  {
    name: "Merkez",
    cityName: "Sinop",
    value: "Merkez",
    id: 838,
  },
  {
    name: "Saraydüzü",
    cityName: "Sinop",
    value: "Saraydüzü",
    id: 839,
  },
  {
    name: "Türkeli̇",
    cityName: "Sinop",
    value: "Türkeli̇",
    id: 840,
  },
  {
    name: "Akıncılar",
    cityName: "Sivas",
    value: "Akıncılar",
    id: 841,
  },
  {
    name: "Altınyayla",
    cityName: "Sivas",
    value: "Altınyayla",
    id: 842,
  },
  {
    name: "Di̇vri̇ği̇",
    cityName: "Sivas",
    value: "Di̇vri̇ği̇",
    id: 843,
  },
  {
    name: "Doğanşar",
    cityName: "Sivas",
    value: "Doğanşar",
    id: 844,
  },
  {
    name: "Gemerek",
    cityName: "Sivas",
    value: "Gemerek",
    id: 845,
  },
  {
    name: "Gölova",
    cityName: "Sivas",
    value: "Gölova",
    id: 846,
  },
  {
    name: "Gürün",
    cityName: "Sivas",
    value: "Gürün",
    id: 847,
  },
  {
    name: "Hafi̇k",
    cityName: "Sivas",
    value: "Hafi̇k",
    id: 848,
  },
  {
    name: "İmranlı",
    cityName: "Sivas",
    value: "İmranlı",
    id: 849,
  },
  {
    name: "Kangal",
    cityName: "Sivas",
    value: "Kangal",
    id: 850,
  },
  {
    name: "Koyulhi̇sar",
    cityName: "Sivas",
    value: "Koyulhi̇sar",
    id: 851,
  },
  {
    name: "Merkez",
    cityName: "Sivas",
    value: "Merkez",
    id: 852,
  },
  {
    name: "Suşehri̇",
    cityName: "Sivas",
    value: "Suşehri̇",
    id: 853,
  },
  {
    name: "Şarkışla",
    cityName: "Sivas",
    value: "Şarkışla",
    id: 854,
  },
  {
    name: "Ulaş",
    cityName: "Sivas",
    value: "Ulaş",
    id: 855,
  },
  {
    name: "Yıldızeli̇",
    cityName: "Sivas",
    value: "Yıldızeli̇",
    id: 856,
  },
  {
    name: "Zara",
    cityName: "Sivas",
    value: "Zara",
    id: 857,
  },
  {
    name: "Akçakale",
    cityName: "Şanlıurfa",
    value: "Akçakale",
    id: 858,
  },
  {
    name: "Bi̇reci̇k",
    cityName: "Şanlıurfa",
    value: "Bi̇reci̇k",
    id: 859,
  },
  {
    name: "Bozova",
    cityName: "Şanlıurfa",
    value: "Bozova",
    id: 860,
  },
  {
    name: "Ceylanpinar",
    cityName: "Şanlıurfa",
    value: "Ceylanpinar",
    id: 861,
  },
  {
    name: "Eyyübi̇ye",
    cityName: "Şanlıurfa",
    value: "Eyyübi̇ye",
    id: 862,
  },
  {
    name: "Halfeti̇",
    cityName: "Şanlıurfa",
    value: "Halfeti̇",
    id: 863,
  },
  {
    name: "Hali̇li̇ye",
    cityName: "Şanlıurfa",
    value: "Hali̇li̇ye",
    id: 864,
  },
  {
    name: "Harran",
    cityName: "Şanlıurfa",
    value: "Harran",
    id: 865,
  },
  {
    name: "Hi̇lvan",
    cityName: "Şanlıurfa",
    value: "Hi̇lvan",
    id: 866,
  },
  {
    name: "Karaköprü",
    cityName: "Şanlıurfa",
    value: "Karaköprü",
    id: 867,
  },
  {
    name: "Si̇verek",
    cityName: "Şanlıurfa",
    value: "Si̇verek",
    id: 868,
  },
  {
    name: "Suruç",
    cityName: "Şanlıurfa",
    value: "Suruç",
    id: 869,
  },
  {
    name: "Vi̇ranşehi̇r",
    cityName: "Şanlıurfa",
    value: "Vi̇ranşehi̇r",
    id: 870,
  },
  {
    name: "Beytüşşebap",
    cityName: "Şırnak",
    value: "Beytüşşebap",
    id: 871,
  },
  {
    name: "Ci̇zre",
    cityName: "Şırnak",
    value: "Ci̇zre",
    id: 872,
  },
  {
    name: "Güçlükonak",
    cityName: "Şırnak",
    value: "Güçlükonak",
    id: 873,
  },
  {
    name: "İdi̇l",
    cityName: "Şırnak",
    value: "İdi̇l",
    id: 874,
  },
  {
    name: "Merkez",
    cityName: "Şırnak",
    value: "Merkez",
    id: 875,
  },
  {
    name: "Si̇lopi̇",
    cityName: "Şırnak",
    value: "Si̇lopi̇",
    id: 876,
  },
  {
    name: "Uludere",
    cityName: "Şırnak",
    value: "Uludere",
    id: 877,
  },
  {
    name: "Çerkezköy",
    cityName: "Tekirdağ",
    value: "Çerkezköy",
    id: 878,
  },
  {
    name: "Çorlu",
    cityName: "Tekirdağ",
    value: "Çorlu",
    id: 879,
  },
  {
    name: "Ergene",
    cityName: "Tekirdağ",
    value: "Ergene",
    id: 880,
  },
  {
    name: "Hayrabolu",
    cityName: "Tekirdağ",
    value: "Hayrabolu",
    id: 881,
  },
  {
    name: "Kapaklı",
    cityName: "Tekirdağ",
    value: "Kapaklı",
    id: 882,
  },
  {
    name: "Malkara",
    cityName: "Tekirdağ",
    value: "Malkara",
    id: 883,
  },
  {
    name: "Marmaraereğli̇si̇",
    cityName: "Tekirdağ",
    value: "Marmaraereğli̇si̇",
    id: 884,
  },
  {
    name: "Muratlı",
    cityName: "Tekirdağ",
    value: "Muratlı",
    id: 885,
  },
  {
    name: "Saray",
    cityName: "Tekirdağ",
    value: "Saray",
    id: 886,
  },
  {
    name: "Süleymanpaşa",
    cityName: "Tekirdağ",
    value: "Süleymanpaşa",
    id: 887,
  },
  {
    name: "Şarköy",
    cityName: "Tekirdağ",
    value: "Şarköy",
    id: 888,
  },
  {
    name: "Almus",
    cityName: "Tokat",
    value: "Almus",
    id: 889,
  },
  {
    name: "Artova",
    cityName: "Tokat",
    value: "Artova",
    id: 890,
  },
  {
    name: "Başçi̇ftli̇k",
    cityName: "Tokat",
    value: "Başçi̇ftli̇k",
    id: 891,
  },
  {
    name: "Erbaa",
    cityName: "Tokat",
    value: "Erbaa",
    id: 892,
  },
  {
    name: "Merkez",
    cityName: "Tokat",
    value: "Merkez",
    id: 893,
  },
  {
    name: "Ni̇ksar",
    cityName: "Tokat",
    value: "Ni̇ksar",
    id: 894,
  },
  {
    name: "Pazar",
    cityName: "Tokat",
    value: "Pazar",
    id: 895,
  },
  {
    name: "Reşadi̇ye",
    cityName: "Tokat",
    value: "Reşadi̇ye",
    id: 896,
  },
  {
    name: "Sulusaray",
    cityName: "Tokat",
    value: "Sulusaray",
    id: 897,
  },
  {
    name: "Turhal",
    cityName: "Tokat",
    value: "Turhal",
    id: 898,
  },
  {
    name: "Yeşi̇lyurt",
    cityName: "Tokat",
    value: "Yeşi̇lyurt",
    id: 899,
  },
  {
    name: "Zi̇le",
    cityName: "Tokat",
    value: "Zi̇le",
    id: 900,
  },
  {
    name: "Akçaabat",
    cityName: "Trabzon",
    value: "Akçaabat",
    id: 901,
  },
  {
    name: "Araklı",
    cityName: "Trabzon",
    value: "Araklı",
    id: 902,
  },
  {
    name: "Arsi̇n",
    cityName: "Trabzon",
    value: "Arsi̇n",
    id: 903,
  },
  {
    name: "Beşi̇kdüzü",
    cityName: "Trabzon",
    value: "Beşi̇kdüzü",
    id: 904,
  },
  {
    name: "Çarşibaşi",
    cityName: "Trabzon",
    value: "Çarşibaşi",
    id: 905,
  },
  {
    name: "Çaykara",
    cityName: "Trabzon",
    value: "Çaykara",
    id: 906,
  },
  {
    name: "Dernekpazarı",
    cityName: "Trabzon",
    value: "Dernekpazarı",
    id: 907,
  },
  {
    name: "Düzköy",
    cityName: "Trabzon",
    value: "Düzköy",
    id: 908,
  },
  {
    name: "Hayrat",
    cityName: "Trabzon",
    value: "Hayrat",
    id: 909,
  },
  {
    name: "Köprübaşı",
    cityName: "Trabzon",
    value: "Köprübaşı",
    id: 910,
  },
  {
    name: "Maçka",
    cityName: "Trabzon",
    value: "Maçka",
    id: 911,
  },
  {
    name: "Of",
    cityName: "Trabzon",
    value: "Of",
    id: 912,
  },
  {
    name: "Ortahi̇sar",
    cityName: "Trabzon",
    value: "Ortahi̇sar",
    id: 913,
  },
  {
    name: "Sürmene",
    cityName: "Trabzon",
    value: "Sürmene",
    id: 914,
  },
  {
    name: "Şalpazarı",
    cityName: "Trabzon",
    value: "Şalpazarı",
    id: 915,
  },
  {
    name: "Tonya",
    cityName: "Trabzon",
    value: "Tonya",
    id: 916,
  },
  {
    name: "Vakfıkebi̇r",
    cityName: "Trabzon",
    value: "Vakfıkebi̇r",
    id: 917,
  },
  {
    name: "Yomra",
    cityName: "Trabzon",
    value: "Yomra",
    id: 918,
  },
  {
    name: "Çemi̇şgezek",
    cityName: "Tunceli̇",
    value: "Çemi̇şgezek",
    id: 919,
  },
  {
    name: "Hozat",
    cityName: "Tunceli̇",
    value: "Hozat",
    id: 920,
  },
  {
    name: "Mazgi̇rt",
    cityName: "Tunceli̇",
    value: "Mazgi̇rt",
    id: 921,
  },
  {
    name: "Merkez",
    cityName: "Tunceli̇",
    value: "Merkez",
    id: 922,
  },
  {
    name: "Nazimi̇ye",
    cityName: "Tunceli̇",
    value: "Nazimi̇ye",
    id: 923,
  },
  {
    name: "Ovacık",
    cityName: "Tunceli̇",
    value: "Ovacık",
    id: 924,
  },
  {
    name: "Pertek",
    cityName: "Tunceli̇",
    value: "Pertek",
    id: 925,
  },
  {
    name: "Pülümür",
    cityName: "Tunceli̇",
    value: "Pülümür",
    id: 926,
  },
  {
    name: "Banaz",
    cityName: "Uşak",
    value: "Banaz",
    id: 927,
  },
  {
    name: "Eşme",
    cityName: "Uşak",
    value: "Eşme",
    id: 928,
  },
  {
    name: "Karahallı",
    cityName: "Uşak",
    value: "Karahallı",
    id: 929,
  },
  {
    name: "Merkez",
    cityName: "Uşak",
    value: "Merkez",
    id: 930,
  },
  {
    name: "Sivaslı",
    cityName: "Uşak",
    value: "Sivaslı",
    id: 931,
  },
  {
    name: "Ulubey",
    cityName: "Uşak",
    value: "Ulubey",
    id: 932,
  },
  {
    name: "Bahçesaray",
    cityName: "Van",
    value: "Bahçesaray",
    id: 933,
  },
  {
    name: "Başkale",
    cityName: "Van",
    value: "Başkale",
    id: 934,
  },
  {
    name: "Çaldıran",
    cityName: "Van",
    value: "Çaldıran",
    id: 935,
  },
  {
    name: "Çatak",
    cityName: "Van",
    value: "Çatak",
    id: 936,
  },
  {
    name: "Edremi̇t",
    cityName: "Van",
    value: "Edremi̇t",
    id: 937,
  },
  {
    name: "Erci̇ş",
    cityName: "Van",
    value: "Erci̇ş",
    id: 938,
  },
  {
    name: "Gevaş",
    cityName: "Van",
    value: "Gevaş",
    id: 939,
  },
  {
    name: "Gürpınar",
    cityName: "Van",
    value: "Gürpınar",
    id: 940,
  },
  {
    name: "İpekyolu",
    cityName: "Van",
    value: "İpekyolu",
    id: 941,
  },
  {
    name: "Muradi̇ye",
    cityName: "Van",
    value: "Muradi̇ye",
    id: 942,
  },
  {
    name: "Özalp",
    cityName: "Van",
    value: "Özalp",
    id: 943,
  },
  {
    name: "Saray",
    cityName: "Van",
    value: "Saray",
    id: 944,
  },
  {
    name: "Tuşba",
    cityName: "Van",
    value: "Tuşba",
    id: 945,
  },
  {
    name: "Altınova",
    cityName: "Yalova",
    value: "Altınova",
    id: 946,
  },
  {
    name: "Armutlu",
    cityName: "Yalova",
    value: "Armutlu",
    id: 947,
  },
  {
    name: "Çınarcık",
    cityName: "Yalova",
    value: "Çınarcık",
    id: 948,
  },
  {
    name: "Çi̇ftli̇kköy",
    cityName: "Yalova",
    value: "Çi̇ftli̇kköy",
    id: 949,
  },
  {
    name: "Merkez",
    cityName: "Yalova",
    value: "Merkez",
    id: 950,
  },
  {
    name: "Termal",
    cityName: "Yalova",
    value: "Termal",
    id: 951,
  },
  {
    name: "Akdağmadeni̇",
    cityName: "Yozgat",
    value: "Akdağmadeni̇",
    id: 952,
  },
  {
    name: "Aydıncık",
    cityName: "Yozgat",
    value: "Aydıncık",
    id: 953,
  },
  {
    name: "Boğazlıyan",
    cityName: "Yozgat",
    value: "Boğazlıyan",
    id: 954,
  },
  {
    name: "Çandır",
    cityName: "Yozgat",
    value: "Çandır",
    id: 955,
  },
  {
    name: "Çayıralan",
    cityName: "Yozgat",
    value: "Çayıralan",
    id: 956,
  },
  {
    name: "Çekerek",
    cityName: "Yozgat",
    value: "Çekerek",
    id: 957,
  },
  {
    name: "Kadışehri̇",
    cityName: "Yozgat",
    value: "Kadışehri̇",
    id: 958,
  },
  {
    name: "Merkez",
    cityName: "Yozgat",
    value: "Merkez",
    id: 959,
  },
  {
    name: "Saraykent",
    cityName: "Yozgat",
    value: "Saraykent",
    id: 960,
  },
  {
    name: "Sarıkaya",
    cityName: "Yozgat",
    value: "Sarıkaya",
    id: 961,
  },
  {
    name: "Sorgun",
    cityName: "Yozgat",
    value: "Sorgun",
    id: 962,
  },
  {
    name: "Şefaatli̇",
    cityName: "Yozgat",
    value: "Şefaatli̇",
    id: 963,
  },
  {
    name: "Yeni̇fakili",
    cityName: "Yozgat",
    value: "Yeni̇fakili",
    id: 964,
  },
  {
    name: "Yerköy",
    cityName: "Yozgat",
    value: "Yerköy",
    id: 965,
  },
  {
    name: "Alaplı",
    cityName: "Zonguldak",
    value: "Alaplı",
    id: 966,
  },
  {
    name: "Çaycuma",
    cityName: "Zonguldak",
    value: "Çaycuma",
    id: 967,
  },
  {
    name: "Devrek",
    cityName: "Zonguldak",
    value: "Devrek",
    id: 968,
  },
  {
    name: "Ereğli̇",
    cityName: "Zonguldak",
    value: "Ereğli̇",
    id: 969,
  },
  {
    name: "Gökçebey",
    cityName: "Zonguldak",
    value: "Gökçebey",
    id: 970,
  },
  {
    name: "Ki̇li̇mli̇",
    cityName: "Zonguldak",
    value: "Ki̇li̇mli̇",
    id: 971,
  },
  {
    name: "Kozlu",
    cityName: "Zonguldak",
    value: "Kozlu",
    id: 972,
  },
  {
    name: "Merkez",
    cityName: "Zonguldak",
    value: "Merkez",
    id: 973,
  },
];

const TrToEn = (text) => {
  return String(text)
    .replaceAll("Ğ", "g")
    .replaceAll("Ü", "u")
    .replaceAll("Ş", "s")
    .replaceAll("I", "i")
    .replaceAll("İ", "i")
    .replaceAll("Ö", "o")
    .replaceAll("Ç", "c")
    .replaceAll("ğ", "g")
    .replaceAll("ü", "u")
    .replaceAll("ş", "s")
    .replaceAll("ı", "i")
    .replaceAll("ö", "o")
    .replaceAll("ç", "c");
};

// localStorage destekli mi degil mi kontrolu
const isLocalStorageEnabled = () => {
  try {
    const key = `__storage__test`;
    window.localStorage.setItem(key, null);
    window.localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

// cookie destekli mi degil mi kontrolu
const isCookieEnabled = () => {
  if (navigator.cookieEnabled) {
    return true
  } else {
    return false
  }
}

// Yeni cookie ekleme ve var olan cookie guncelleme
const setCookie = (cookieName, cookieValue, expDays = 365) => {
  if (typeof window !== 'undefined') {
  let date = new Date();
  date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = cookieName + "=" + cookieValue + "; " + expires + "; path=/";
  }
}

// Var olan cookie getirme
const getCookie = (cookieName) => {
  if (typeof window !== 'undefined') {
  const name = cookieName + "=";
  const cArr = document.cookie.split('; ');
  let res;
  cArr?.forEach(val => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  })
  return res;
}
}

// Var olan cookie silme
const deleteCookie = (cookieName) => {
  if (typeof window !== 'undefined') {
  document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  }
}

const addParent = async (email) => {
  // veli ekleme
  await axios.post("student/parent", {
    email: email,
  });
};

export {
  changePhoneNumber,
  verifyPhoneNumber,
  updateProfile,
  updateSchoolInfo,
  updateEmail,
  verifyUpdateEmail,
  getUserInvoiceProfile,
  updateUserInvoiceProfile,
  createUserInvoiceProfile,
  citiesJson,
  districtJson,
  TrToEn,
  isLocalStorageEnabled,
  isCookieEnabled,
  setCookie,
  getCookie,
  deleteCookie,
  addParent,
};
