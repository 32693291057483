<template>
  <div
    class="fixed h-16 w-full md:h-full md:relative md:w-admin-side-bar text-gray-700 bg-gray-100 transition-all z-20"
    :class="adminMiniSideBar ? 'adminMiniSideBar' : null"
  >
    <div
      class="md:flex md:flex-col h-full justify-between overflow-y-auto overflow-x-hidden"
    >
      <div class="px-3">
        <div
          class="flex flex-col justify-center items-center h-16 font-bold border-b border-gray-300"
        >
          <span v-if="!adminMiniSideBar">Admin Panele Hoşgeldiniz</span>
          <span v-else class="cursor-default transform rotate-45 hover:rotate-90 transition-all">:)</span>
          <button
            @click="logOutFunc()"
            v-if="!adminMiniSideBar"
            aria-label="Çıkış yap"
            class="flex items-center gap-1 text-xs text-gray-500 hover:text-gray-700"
          >
            <svgIcon name="logout" class="w-3 h-3" />
            <span class="text-xs">Çıkış yap</span>
          </button>
        </div>
        <div class="hidden md:block my-2">
          <menuItem
            v-for="menuItem in menuList"
            :key="menuItem.menuOrder"
            :title="$t('menuTitles.' + menuItem.label)"
            :icon="`menuIcons/${menuItem.label}`"
            :link="setLink(menuItem.link)"
            class="my-1"
          />
        </div>
      </div>

      <div class="py-4 text-xs text-gray-400">Sona geldiniz</div>

      <div class="hidden md:flex">
        <button
          @click="setMinimize(!adminMiniSideBar)"
          aria-label="Chevron left"
          class="transition-all absolute flex items-center justify-center top-4 -right-4 text-gray-100 bg-gray-700 h-8 w-8 rounded-full"
          :class="adminMiniSideBar ? 'transform rotate-180' : ''"
        >
          <svgIcon name="chevron-left" class="text-white w-3 h-3" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { userService } from "@/services/user/index";
import { appSettings } from "@/services/appSettings/index";
import { authenticationService } from "@/services/auth/index";
import menuItem from "./menuItem.vue";

export default {
  components: {
    menuItem,
  },
  data() {
    return {};
  },
  computed: {
    menuList() {
      return userService.menuList();
    },
    adminMiniSideBar() {
      return this.$store.state.appSettings.adminMiniSideBar;
    },
  },
  methods: {
    setMinimize(val) {
      appSettings.adminMiniSideBar(val);
    },
    setLink(link) {
      // Sidebar-dan Olcme ve Degerlendirme-ye tiklandiginda baska sayfaya gitsin diye
      if (link == "/statistics") {
        link = "/selectStatistics";
        return link
          .split(/(?=[A-Z])/)
          .toString()
          .toLowerCase()
          .replaceAll(",", "-");
      } else {
        return link // (not: oncesinde bir tek else icindeki kisim var idi)
          .split(/(?=[A-Z])/)
          .toString()
          .toLowerCase()
          .replaceAll(",", "-");
      }
    },
    logOutFunc() {
      authenticationService.logOut();
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style>
</style>