<template>
  <div>
    <QuestionGalleryView v-model="status" v-bind="{
      question,
      buttonPending,
      pending,
      showTrueOption,
      showDifficulty
    }" @favorite="setFavoriteQuestion" @solution="questionSolution" @drawMode="drawMode" @hintMode="openHintMode" />
  </div>
</template>

<script>
import { galleryView } from "@/common/eventBuses/galleryView"
import {
  setFavoriteWInfo,
  questionaddOneSolutionVideoViewCount
} from "@/common/helpers/questions"

const QuestionGalleryView = () => import("./questionGalleryView")
export default {
  components: { QuestionGalleryView },
  data() {
    return {
      status: false,
      question: {},
      buttonPending: false,
      pending: false,
      showTrueOption: true,
      showDifficulty: true,
      solution: {
        title: null,
        video: null,
        modal: false
      }
    }
  },
  beforeRouteLeave() {
    this.clearGallery()
  },
  created() {
    galleryView.$on("status", status => (this.status = status))
    galleryView.$on("question", question => (this.question = question))
    galleryView.$on("pending", pending => (this.pending = pending))
    galleryView.$on(
      "showTrueOption",
      showTrueOption => (this.showTrueOption = showTrueOption)
    )
    galleryView.$on(
      "showDifficulty",
      showDifficulty => (this.showDifficulty = showDifficulty)
    )
    if (typeof window !== 'undefined') {
      document.addEventListener("keyup", this.handleKeyup)
    }
  },
  beforeDestroy() {
    galleryView.$off("status", status => (this.status = status))
    galleryView.$off("question", question => (this.question = question))
    galleryView.$off("pending", pending => (this.pending = pending))
    galleryView.$off(
      "showTrueOption",
      showTrueOption => (this.showTrueOption = showTrueOption)
    )
    galleryView.$off(
      "showDifficulty",
      showDifficulty => (this.showDifficulty = showDifficulty)
    )
    if (typeof window !== 'undefined') {
      document.removeEventListener("keyup", this.handleKeyup)
    }
  },
  methods: {
    handleClose() {
      this.status = false
    },
    handleKeyup(e) {
      if (e.keyCode === 27) this.handleClose()
    },
    clearGallery() {
      this.status = false
      this.question = {}
      this.buttonPending = false
      this.showTrueOption = true
      this.showDifficulty = true
    },
    questionSolution() {
      this.$store.dispatch("modals/solutionModal", true)
      this.$store.dispatch(
        "modals/solutionModalVideo",
        this.question.answerSolutionVideoUrl
      )
      this.status = false
      //Add view count for only students
      if (this.$store.state.user.userRole != "STUDENT") return
      //Increase student video view Count

      const testUserId = this.question.testUserId || null
      //(devAsist) control
      const testId = this.question.testUserId ? null : this.question.testId
      const questionId = this.question.questionId

      this.questionaddOneSolutionVideoViewCount(
        testId,
        questionId,
        testUserId
      ).then(() => (this.question.solutionVideoViewCount += 1))
    },
    drawMode() {
      this.status = false
      galleryView.$emit("drawMode", this.question)
    },
    openHintMode() {
      const hintImage = JSON.parse(this.question.hintInfo)[0]?.hint_url
      this.status = false
      this.$store.dispatch("modals/hintModal", true)
      this.$store.dispatch("modals/hintModalImage", hintImage)
    },
    setFavoriteQuestion() {
      this.buttonPending = true
      this.setFavoriteWInfo(this.question).finally(
        () => (this.buttonPending = false)
      )
    },
    setFavoriteWInfo,
    questionaddOneSolutionVideoViewCount
  }
}
</script>
